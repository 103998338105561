import type { SagaMiddleware } from 'redux-saga';

import { ErrorLogger } from '@edapp/monitoring';
import { AppKeyboard } from '@maggie/cordova/keyboard';
import { Link } from '@maggie/cordova/link';
import { Notifier } from '@maggie/cordova/notifier/notifier';
import { PowerManagement } from '@maggie/cordova/power';
import { RateTheApp } from '@maggie/cordova/rate_the_app';
import { Sound } from '@maggie/cordova/sound';
import { StatusBarManager } from '@maggie/cordova/status-bar';
import { FileUtilities } from '@maggie/core/file';
import { addBackboneListeners } from '@maggie/store/backbone/backboneListener';
import { addOnlineConfigListener } from '@maggie/store/config/online-config-listener';
import { addOrientationListener } from '@maggie/store/config/orientation-listener';
import rootSaga from '@maggie/store/rootSagas';
import { addLocalStorageListener } from '@maggie/store/session/local-storage-listener';
import { store } from '@maggie/store/store';
import { addPageVisibilityListener } from '@maggie/store/thomas/page-visibility-listener';

/**
 * Bootstrap all required plugins and listeners
 * Note: cordova plugins do nothing if there is no plugin detected
 */
export async function appBootstrap() {
  addPageVisibilityListener();
  addBackboneListeners(store);
  addOnlineConfigListener(store);
  addLocalStorageListener(store);
  addOrientationListener(store);

  try {
    // needed for notification click cold start
    Notifier.initLocalClickListener();
    StatusBarManager.setDefault();

    await Promise.all([
      PowerManagement.initialize(),
      FileUtilities.prepareWritableFolders(),
      Sound.initialize()
    ]);

    AppKeyboard.initialize();
    RateTheApp.initialize();

    Link.initialize();
    Notifier.initialize();
  } catch (err) {
    ErrorLogger.captureEvent('Failed to start app', 'error', { err });
  }
}

export async function bootApp(runSaga: SagaMiddleware<unknown>['run']) {
  const runnedSaga = runSaga(rootSaga);
  await appBootstrap();

  return runnedSaga;
}
