import * as React from 'react';
import styled from 'styled-components';
import { SwipeableViewSlides, SwipeableViewSlide } from './Slide';
import type { Target, TargetAndTransition, Transition } from 'framer-motion';

const THRESHOLD = 35;

export type Position = {
  initial?: { x: number; y: number };
  current?: { x: number; y: number };
  isMouseDown: boolean;
};

type Props = {
  onChangeViewIndex?: (index: number) => void;
  viewIndex: number;
  /**
   * Allows you to keep swipping infinitely.
   * When it gets to last item, it goes back to the first.
   *
   * @default false
   */
  isInfiniteSwipe?: boolean;
  /**
   * Disables swiping
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * When slide is sliding (from left or right)
   */
  slideAnimation?: (position: Position, slideIndex: number) => TargetAndTransition;
  /**
   * Transition of the slide
   * (currentX movement - while pressed)
   */
  slideTransition?: Transition;
  /**
   * Transition of the container slide
   * (final movement - once relesed button/finger)
   */
  slideContainerTransition?: Transition;
  /**
   * Property for styling the container of SwipeableView
   * (works with styled-components)
   */
  className?: string;
  /**
   * When disabled, it doesn't show 'sliding' transition until `onMouseUp|onTouchUp`
   *
   * @default false
   */
  disableSlideDiff?: boolean;
  /**
   * Reference to the wrapper component
   * (for usages like calculation of the DOMRect, etc)
   */
  wrapperRef?: React.Ref<HTMLDivElement>;

  /**
   * if viewIndex is greater than zero when component mounts,
   * it doesn't trigger animation
   */
  disableInitialAnimation?: boolean;

  children?:
    | ((position: Position) => React.ReactElement | React.ReactElement[])
    | React.ReactElement
    | React.ReactElement[];
};

const defaultSlideContainerTransition = {
  duration: 0.3,
  stiffness: 100,
  damping: 400
};

export const SwipeableViews: React.FC<Props> = ({
  slideContainerTransition = defaultSlideContainerTransition,
  isInfiniteSwipe = false,
  disabled = false,
  children,
  onChangeViewIndex,
  viewIndex,
  slideTransition,
  slideAnimation,
  className,
  disableSlideDiff,
  wrapperRef,
  disableInitialAnimation
}) => {
  const [position, setPosition] = React.useState<Position>({ isMouseDown: false });

  const slides = typeof children === 'function' ? children(position) : children;
  const totalSlides = React.Children.count(children);

  React.useEffect(() => {
    setPosition({ ...position });
  }, [viewIndex]);

  const onGoLeft = () => {
    if (isInfiniteSwipe) {
      return onChangeViewIndex?.((((viewIndex - 1) % totalSlides) + totalSlides) % totalSlides);
    }

    if (viewIndex <= 0) {
      // can't go left
      return;
    }

    return onChangeViewIndex?.(viewIndex - 1);
  };

  const onGoRight = () => {
    if (isInfiniteSwipe) {
      return onChangeViewIndex?.((((viewIndex + 1) % totalSlides) + totalSlides) % totalSlides);
    }
    if (viewIndex >= React.Children.count(slides) - 1) {
      // can't go right
      return;
    }

    return onChangeViewIndex?.(viewIndex + 1);
  };

  const onMouseDown = (e: React.MouseEvent | React.TouchEvent) => {
    if (disabled) {
      return;
    }

    const x = (e as React.TouchEvent).changedTouches
      ? (e as React.TouchEvent).changedTouches[0].clientX
      : (e as React.MouseEvent).pageX;

    const y = (e as React.TouchEvent).changedTouches
      ? (e as React.TouchEvent).changedTouches[0].clientY
      : (e as React.MouseEvent).pageY;

    setPosition({ initial: { x, y }, current: { x, y }, isMouseDown: true });
  };

  const onMouseMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (!position.isMouseDown || disabled) {
      return;
    }

    const x = (e as React.TouchEvent).changedTouches
      ? (e as React.TouchEvent).changedTouches[0].clientX
      : (e as React.MouseEvent).pageX;

    const y = (e as React.TouchEvent).changedTouches
      ? (e as React.TouchEvent).changedTouches[0].clientY
      : (e as React.MouseEvent).pageY;

    setPosition({ ...position, current: { x, y } });
  };

  const isGoingRight = () => {
    const { initial, current } = position;

    if (!initial || !current) {
      return false;
    }

    return initial.x > current.x + THRESHOLD;
  };

  const isGoingLeft = () => {
    const { initial, current } = position;

    if (!initial || !current) {
      return false;
    }

    return initial.x < current.x - THRESHOLD;
  };

  const onMouseUp = () => {
    if (isGoingRight()) {
      onGoRight();
    }

    if (isGoingLeft()) {
      onGoLeft();
    }

    setPosition({
      isMouseDown: false
    });
  };

  const getSlideStyle = (slideIndex: number) => {
    // If it's current slide and there is no swipe happening - don't persist previous diff
    if (slideIndex === viewIndex && !position.isMouseDown) {
      return {
        animate: {
          x: 0,
          ...(slideAnimation?.(position, slideIndex) || {})
        }
      };
    }

    // OUT
    const { initial, current } = position;
    const diff = isGoingRight() || isGoingLeft() ? (current?.x || 0) - (initial?.x || 0) : 0;
    return {
      animate: {
        x: diff,
        ...(slideAnimation?.(position, slideIndex) || {})
      }
    };
  };

  const resetState = (e: React.MouseEvent) => {
    if (isGoingLeft() || isGoingRight()) {
      e.stopPropagation();
      e.preventDefault();
      setPosition({ isMouseDown: false });
    }
  };

  const animate: Target = { x: `${-100 * viewIndex}%` };

  return (
    <SwipeableViewWrapper ref={wrapperRef} className={className}>
      <SwipeableViewSlides
        initial={disableInitialAnimation ? animate : {}}
        animate={animate}
        transition={slideContainerTransition}
      >
        {React.Children.map(slides, (child, index) => {
          const style = !!disableSlideDiff ? { animate: {} } : getSlideStyle(index);
          return (
            <SwipeableViewSlide
              key={`slide-${index}`}
              animate={style.animate}
              transition={slideTransition}
              onClickCapture={resetState}
              onMouseDown={onMouseDown}
              onMouseUp={onMouseUp}
              onMouseMove={onMouseMove}
              onMouseLeave={resetState}
              onTouchStart={onMouseDown}
              onTouchEnd={onMouseUp}
              onTouchMove={onMouseMove}
            >
              {child}
            </SwipeableViewSlide>
          );
        })}
      </SwipeableViewSlides>
    </SwipeableViewWrapper>
  );
};

export const SwipeableViewWrapper = styled.div`
  display: flex;
  flex: 1 0 0%;
  position: relative;
  white-space: nowrap;
`;
