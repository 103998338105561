import * as React from 'react';
import type { Props } from './types';

export const DiscussionSvg: React.FC<Props> = ({ width = 180, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="80" cy="80" r="65" fill="#DCF6FF" />
    <path
      d="M107.541 110.205V101.784L94.541 105.784L104.493 111.908C105.825 112.728 107.541 111.769 107.541 110.205Z"
      fill="#00BAFF"
    />
    <rect
      width="58.5569"
      height="45.8271"
      rx="11"
      transform="matrix(-1 0 0 1 116.557 60)"
      fill="#00BAFF"
    />
    <path d="M82 76L58 90V76H82Z" fill="#0098E3" />
    <circle cx="76.5533" cy="82.6935" r="1.8463" fill="white" />
    <circle cx="87.6315" cy="82.6935" r="1.8463" fill="white" />
    <circle cx="98.7096" cy="82.6935" r="1.8463" fill="white" />
    <rect x="37" y="36" width="52.6163" height="41.5839" rx="11" fill="#83D9F9" />
    <path
      d="M46 81.0725V73L57 77L49.1763 82.6899C47.8544 83.6513 46 82.707 46 81.0725Z"
      fill="#83D9F9"
    />
    <circle cx="53.0006" cy="56.9249" r="1.8463" fill="white" />
    <circle cx="64.0787" cy="56.9249" r="1.8463" fill="white" />
    <circle cx="75.1568" cy="56.9249" r="1.8463" fill="white" />
    <path
      d="M118.5 123C123.194 123 127 119.194 127 114.5C127 109.806 123.194 106 118.5 106C113.806 106 110 109.806 110 114.5C110 119.194 113.806 123 118.5 123Z"
      fill="#83D9F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M125.42 119.436C123.879 121.594 121.354 123 118.5 123C115.645 123 113.12 121.593 111.578 119.435C112.964 116.946 115.545 115.273 118.499 115.273C121.454 115.273 124.034 116.947 125.42 119.436Z"
      fill="#6559FF"
    />
    <path
      d="M121.591 112.182C121.591 110.475 120.207 109.091 118.5 109.091C116.793 109.091 115.409 110.475 115.409 112.182C115.409 113.889 116.793 115.273 118.5 115.273C120.207 115.273 121.591 113.889 121.591 112.182Z"
      fill="#6559FF"
    />
    <path
      d="M33.5 97C38.7467 97 43 92.7467 43 87.5C43 82.2533 38.7467 78 33.5 78C28.2533 78 24 82.2533 24 87.5C24 92.7467 28.2533 97 33.5 97Z"
      fill="#83D9F9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.2345 93.0169C39.5116 95.4283 36.6891 97.0002 33.4996 97.0002C30.3094 97.0002 27.4865 95.4277 25.7637 93.0156C27.3131 90.2337 30.1969 88.3638 33.4987 88.3638C36.801 88.3638 39.6853 90.2343 41.2345 93.0169Z"
      fill="#6559FF"
    />
    <path
      d="M36.954 84.9092C36.954 83.0013 35.4074 81.4547 33.4995 81.4547C31.5916 81.4547 30.0449 83.0013 30.0449 84.9092C30.0449 86.8171 31.5916 88.3638 33.4995 88.3638C35.4074 88.3638 36.954 86.8171 36.954 84.9092Z"
      fill="#6559FF"
    />
  </svg>
);
