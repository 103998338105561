import * as React from 'react';

import styled from 'styled-components';

import { Box } from '@edapp/ed-components';
import type { AppLayoutContextType } from '@maggie/layout/AppLayoutContext';

import { StarBalance } from '../star-balance/StarBalance';
import { NavigationIconNames } from '../types';
import type { DesktopViewType } from './constants';
import { navigationIcons } from './constants';

type Props = Pick<
  AppLayoutContextType,
  'shouldShowStarBalance' | 'isGameAvailable' | 'balance' | 'viewHeadComponents'
> & {
  shouldShiftIcons: boolean;
  useIconsFor?: DesktopViewType;
  className?: string;
};

export const NavigationIcons: React.FC<Props> = ({
  shouldShiftIcons,
  shouldShowStarBalance,
  isGameAvailable,
  balance,
  viewHeadComponents,
  useIconsFor,
  className
}: Props) => {
  const iconsList = useIconsFor
    ? navigationIcons[useIconsFor]
    : { ...navigationIcons.viewHead, ...navigationIcons.navigation };
  return (
    <NavigationIconsWrapper
      flex
      flexDirection="row-reverse"
      justifyContent="flex-end"
      alignItems="center"
      className={className}
    >
      {viewHeadComponents?.map(iconName => {
        const Component = iconsList[iconName];
        if (iconName === NavigationIconNames.starBalance) {
          if (!shouldShiftIcons && shouldShowStarBalance && useIconsFor !== 'viewHead') {
            return (
              <StarBalance balance={balance} isGameAvailable={isGameAvailable} key={iconName} />
            );
          }
        } else if (Component) {
          return <Component key={iconName} />;
        }
      })}
    </NavigationIconsWrapper>
  );
};

const NavigationIconsWrapper = styled(Box)`
  height: 100%;

  /* add margin in between icons, margin-right is used due to row-reverse flex-direction */
  > * + * {
    margin-right: ${({ theme }) => `${theme.space(1.5)}px`};
  }
`;
