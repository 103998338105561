import { launchBranchDeepLink } from '@maggie/core/vendor-toolings';

import { BackboneActions } from '../actions';
import type { BackboneEventHandler } from '../backboneListener';

export const backboneResumeEventHandler: BackboneEventHandler = store => {
  return async () => {
    store.dispatch(BackboneActions.applicationResume());

    const isThomasOpen = store.getState().thomas.isOpen;
    if (!isThomasOpen) {
      await launchBranchDeepLink();
    }
  };
};
