import type { Store } from 'redux';

import { Platform } from '@maggie/cordova/platform';
import type { LxStoreState } from '@maggie/store/types';

import ConfigActions from './actions';

export const addOrientationListener = (store: Store<LxStoreState>) => {
  window.addEventListener('orientationchange', () => {
    if (!Platform.isCordova()) {
      return; // ignore?!
    }

    const { orientation } = store.getState().config;
    if (orientation !== screen.orientation.type) {
      /**
       * Updates for window.innerWidth in Android has a slight delay
       * which might cause a sporadic issue on screen orientation change triggered by this dispatch
       * to solve this sporadic issue, we delay the dispatch to the next execution cycle
       */
      if (Platform.get() === 'Android') {
        setTimeout(() => {
          store.dispatch(ConfigActions.setOrientation(screen.orientation.type));
        }, 0);
      } else {
        store.dispatch(ConfigActions.setOrientation(screen.orientation.type));
      }
    }
  });
};
