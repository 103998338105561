import * as React from 'react';

export function useDeviceKeyboardHeight() {
  const [keyboardHeight, setKeyboardHeight] = React.useState(0);

  React.useEffect(() => {
    const handleWillShowKeyboard = (e: CordovaKeyboardEvent) => setKeyboardHeight(e.keyboardHeight);
    const handleWillHideKeyboard = () => setKeyboardHeight(0);

    window.addEventListener('keyboardWillShow', handleWillShowKeyboard);
    window.addEventListener('keyboardWillHide', handleWillHideKeyboard);

    return () => {
      window.removeEventListener('keyboardWillShow', handleWillShowKeyboard);
      window.removeEventListener('keyboardWillHide', handleWillHideKeyboard);
    };
  });

  return keyboardHeight;
}
