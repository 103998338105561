import * as React from 'react';

import * as ReactDOM from 'react-dom';

import { ChinaStartupPolicyDialog } from '@maggie/components/china-privacy-policy/ChinaStartupPolicyDialog';
import { NoConsentDialog } from '@maggie/components/china-privacy-policy/NoConsentDialog';
import { setPrivacyPolicyLocalStorageValue } from '@maggie/utils/china-privacy-policy';

type Props = {
  open: boolean;
  onConsent?: () => void;
};
const ChinaPrivacyPolicy: React.FC<Props> = ({ open = false, onConsent }) => {
  const disagreeCountRef = React.useRef<number>(0);
  const [isPrimaryPolicyOpen, setIsPrimaryPolicyOpen] = React.useState(true);
  const [isSecondaryPolicyOpen, setIsSecondaryPolicyOpen] = React.useState(false);
  const [isNoConsentDialogOpen, setIsNoConsentDialogOpen] = React.useState(false);

  const handleAgree = React.useCallback(async () => {
    setPrivacyPolicyLocalStorageValue(true);
    setIsPrimaryPolicyOpen(false);
    setIsSecondaryPolicyOpen(false);

    onConsent?.();
  }, []);

  const handleDisagree = React.useCallback(() => {
    // disagree once
    if (disagreeCountRef.current === 0) {
      setIsPrimaryPolicyOpen(false);
      setIsSecondaryPolicyOpen(true);
      disagreeCountRef.current = 1;
      return;
    }

    // disagree twice
    if (disagreeCountRef.current === 1) {
      setIsPrimaryPolicyOpen(false);
      setIsSecondaryPolicyOpen(false);
      setIsNoConsentDialogOpen(true);
      disagreeCountRef.current = 2;

      return;
    }
  }, []);

  if (!open) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <ChinaStartupPolicyDialog
        isOpen={isPrimaryPolicyOpen}
        type="primary"
        onAgree={handleAgree}
        onDisagree={handleDisagree}
      />
      <ChinaStartupPolicyDialog
        isOpen={isSecondaryPolicyOpen}
        type="secondary"
        onAgree={handleAgree}
        onDisagree={handleDisagree}
      />
      <NoConsentDialog open={isNoConsentDialogOpen} />
    </>,
    document.body
  );
};

export { ChinaPrivacyPolicy };
