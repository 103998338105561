import type { PolicyContentType, PopupPolicyType } from './type';

export const POPUP_POLICY_TEXT: Record<PopupPolicyType, string> = {
  /**
   * Registering and using “App Name” requires you to agree to the User Agreement and Privacy Policy,
   * or else we cannot provide services to you.
   * Please carefully read the documents and click on “Agree” to continue.
   * If  you  click  “Disagree”,  you  will  not  be  able to  continue using “App Name”.
   */
  primary: `欢迎使用EdApp!

注册使用EdApp需要您同意用户协议和隐私政策，否则我们无法为您提供服务。请您仔细阅读下方政策中的条款后再点击“同意”前往下一步。
  
如果您选择“不同意”，您将无法使用EdApp。`,
  /**
   * If you select “Disagree”, unfortunately we will not be able to provide services for you.
   */
  secondary: `如果您不同意本用户协议和隐私政策，很遗憾我们将无法继续为您提供服务。`
};

export const POLICY_CONTENT: Record<PolicyContentType, string> = {
  'privacy-policy': `<center>
<strong>EdApp移动应用程序</strong>
隐私政策
</center>

<p>发布日期：2022年08月10日</p>

<p><strong>导言</strong>

本《隐私政策》（以下简称为“政策”）仅适用于安卓版本的EdApp移动应用程序（以下简称为“应用”）。应用的开发者是SafetyCulture公司。SafetyCulture公司已授权北京赛诺歌讴科技有限公司（以下简称为“我们”）在中国大陆为其代理此应用的一切分发和运营业务。

请您在使用/继续使用应用前仔细阅读、充分理解本政策的完整版全文，并在同意全部内容后使用/继续使用。除非另有说明，不同意政策或其更新（我们将及时通知您此类更新）可能会影响您正常使用或继续正常使用应用。

您同意本政策表示您已经了解并同意在相关场景下，为实现功能运行，我们将对您的相关个人信息进行处理。但这并不代表只要您开始使用我们的应用，我们将处理本政策中涉及的全部个人信息。仅当您使用特定功能时，我们才会根据“最小必要”原则，为实现向您提供服务的目的，处理您的相关个人信息。

除本指引外，在特定场景下，我们将以及时告知的方式（包括但不限于更新本指引、重新签署文件、页面提示、弹窗、站内信、邮件、网站公告或其他便于您获知的方式）另行向您详细说明对应信息的处理目的、方式、范围等规则，并在征得您的授权同意后处理。

本政策将帮助您了解以下内容：

1.    个人信息的收集和使用
2.    应用接入第三方SDK目录
3.    应用需要使用的手机权限
4.    信息的存储及转移
5.    信息共享
6.    您的权利
7.    政策变更
8.    年龄限制
9.    法律管辖及国际仲裁
10.  注销及联络渠道


<strong>1. 个人信息的收集和使用</strong>

我们会遵循合法、正当、最小必要、公开透明的原则，收集和使用您在使用我们的应用过程中主动提供或因使用我们的产品和/或服务而产生的个人信息。为了让您正常使用应用服务，我们可能会按照合法、正当、必要的原则收集您的个人信息，在收集您的个人信息后，我们会严格依照法律法规规定保护您的信息安全。为了方便您快速了解我们收集您的个人信息的情况，我们准备了本清单。我们努力为您的个人信息安全提供保障，以防止个人信息的泄露、篡改、丢失、不当使用、未经授权的访问和披露等。

我们会收集您在使用服务时主动提供的，以及通过自动化手段收集您在使用功能或接受服务过程中产生的下述信息为帮您快速了解我们关于您的个人信息收集、使用的主要情况，我们以清单的方式进行了说明：

•	信息种类：用户名
•	功能场景：应用功能，分析，账户管理
•	是否可拒绝提供：否
•	拒绝提供后的影响：用户无法注册并使用应用程序

•	信息种类：邮箱地址
•	功能场景：应用功能，分析，账户管理
•	是否可拒绝提供：否
•	拒绝提供后的影响：用户无法注册并使用应用程序

•	信息种类：地理位置
•	功能场景：应用功能，分析，账户管理
•	是否可拒绝提供：是
•	拒绝提供后的影响：用户无法关联正确的用户群


<strong>2. 应用接入第三方SDK目录</strong>

为保障应用的稳定运行或实现相关功能，我们可能会接入由第三方提供的软件开发包（SDK）实现前述目的。我们会对合作方获取个人信息的SDK进行严格的安全监测，以保护您的个人信息安全。

我们对接入的相关第三方SDK在以下目录中列明。请注意，第三方SDK可能由于版本升级、策略调整等原因导致其个人信息处理类型发生变化，请以其公示的官方说明为准。

•	SDK名称：Android Branch Deep Linking
•	使用目的：递延深度链接
•	收集个人信息类型：设备IP地址，操作系统，操作系统版本，IMEI，MAC地址，Android ID
•	隐私政策链接：https://github.com/BranchMetrics/android-branch-deep-linking

•	SDK名称：Datadog
•	使用目的：应用检测
•	收集个人信息类型：会话信息，IP地址地理位置，操作系统，设备信息
•	隐私政策链接：https://datadoghq.com

•	SDK名称：Segment
•	使用目的：用户分析
•	收集个人信息类型：IP地址地理位置，操作系统信息
•	隐私政策链接：https://segment.com/

•	SDK名称：IntelliJ IDEA Integration SDK
•	使用目的：插件管理
•	收集个人信息类型：无
•	隐私政策链接：https://plugins.jetbrains.com/docs/intellij/sdk.html

•	SDK名称：Shortcut Badger
•	使用目的：消息推送
•	收集个人信息类型：无
•	隐私政策链接：https://github.com/leolin310148/ShortcutBadger

•	SDK名称：Android Basic Framework Kotlin SDK
•	使用目的：Kotlin编程语音的基本功能
•	收集个人信息类型：无
•	隐私政策链接：https://kotlinlang.org/

•	SDK名称：Firebase Data Analysis SDK
•	使用目的：消息推送
•	收集个人信息类型：设备ID，IMEI，MAC地址，Android ID
•	隐私政策链接：https://firebase.google.com/docs/android/setup?hl=zh-cn

•	SDK名称：Google Mobile Services Framework
•	使用目的：为不同机型提供功能辅助
•	收集个人信息类型：无
•	隐私政策链接：https://www.android.com/gms

•	SDK名称：Apache SDK
•	使用目的：应用内核，Cordova Webview 框架
•	收集个人信息类型：手机上软件安装列表，Android ID
•	隐私政策链接：https://cordova.apache.org/

我们需要使用自启动服务来进行消息推送，如您想关掉自启动服务，可能会导致无法正常提醒。

<strong>3. 应用需要使用的手机权限</strong>

为保障应用产品功能实现与安全稳定运行目的，我们可能会申请或使用操作系统的相关权限；为了更好地保障您的知情权，我们通过下列列表将产品可能申请、使用的相关操作系统权限进行说明，您可以根据实际需要对相关权限进行管理和设置。伴随着产品的升级，申请、使用操作系统权限的情况可能会发生变动，我们将及时根据这些变动调整本说明，以确保您及时获悉权限申请与使用的情况。

•	权限种类：消息推送
•	应用内权限功能：允许应用程序与用户沟通及发送消息
•	是否可拒绝授权：是
•	拒绝授权后的影响：用户无法收到消息推送

•	权限种类：存储
•	应用内权限功能：允许用户从存储上传图片及视频
•	是否可拒绝授权：是
•	拒绝授权后的影响：用户无法从存储上传图片及视频

•	权限种类：写入日程提醒
•	应用内权限功能：允许用户添加日历提醒
•	是否可拒绝授权：是
•	拒绝授权后的影响：用户无法添加日历提醒

•	权限种类：读取日程提醒
•	应用内权限功能：避免用户添加重复日历提醒
•	是否可拒绝授权：否
•	拒绝授权后的影响：

•	权限种类：读取电话状态
•	应用内权限功能：为电池管理服务，应用检查电池消耗情况和网络状况
•	是否可拒绝授权：否
•	拒绝授权后的影响：


<strong>4. 信息的储存及转移</strong>

存储地点 

如我们因业务等需要，确需向中华人民共和国境外提供个人信息的，我们应具备法律规定的条件（通过国家网信部门组织的安全评估或按照国家网信部门制定的标准合同与境外接收方订立合同，约定双方的权利和义务等），并将向您告知境外接收方的名称或者姓名、联系方式、处理目的、处理方式、个人信息的种类以及您向境外接收方行使个人信息主体权利的方式和程序等事项，取得您的单独同意。我们将采取必要措施，保障境外接收方处理个人信息的活动达到中国法律规定的（或者《中国人民共和国个人信息保护法》，《网络安全法》，《数据安全法》及任何相关的中国人民共和国法律规定的）个人信息保护标准。

存储期限

我们将在实现您的个人信息处理目的所必要的最短时间内保存您的个人信息，但您行使删除权、注销账号或法律法规另有规定的除外（例如：《电子商务法》规定：商品和服务信息、交易信息保存时间自交易完成之日起不少于三年）。超出上述存储期限后，我们会对您的个人信息进行删除或匿名化处理。

当我们的产品或服务停止运营时，我们将以通知、公告等形式通知您，并在合理期限内删除您的个人信息或进行匿名化处理，法律法规另有规定的除外。


<strong>5. 信息共享</strong>

个人信息委托处理

平台的部分功能可能由外部服务商提供，例如，我们可能会聘请专业数据处理服务机构来为我们的平台提供数据分析方面的技术支持。

对我们委托处理个人信息的公司、组织和个人，我们会与其签署协议，约定委托处理的目的、期限、处理方式、个人信息的种类、保护措施以及双方的权利和义务等，并对受托人的个人信息处理活动进行监督。

与我们公司外第三方分享

为向您提供完善的产品和服务，在某些情形下，我们需要与我们公司外的第三方公司、组织或个人共享您的个人信息。我们仅会出于合法、正当、最小必要且本政策所明确的目的与第三方分享您的个人信息，并且只会分享必要的个人信息。如果第三方改变或超越您原同意范围处理您的个人信息，我们会要求第三方再次征得您的同意。

对我们与之共享个人信息的公司、组织和个人，我们会要求其严格遵守我们关于数据隐私保护的措施和要求，包括但不限于根据数据保护协议、承诺书及相关数据处理政策进行个人信息的处理，避免识别出个人身份，保障隐私安全。

如因业务需求，我们需要向海外进行个人数据传输，我们将向您告知境外接收方的名称或者姓名、联系方式、处理目的、处理方式、个人信息的种类以及您向境外接收方行使个人信息主体权利的方式和程序等事项，取得您的单独同意。我们将采取必要措施，保障境外接收方处理个人信息的活动达到中国法律规定的（或者《中国人民共和国个人信息保护法》，《网络安全法》，《数据安全法》及任何相关的中国人民共和国法律规定的）个人信息保护标准。


<strong>6. 您的权利</strong>

删除个人信息

在以下情形中，您可以通过本政策第10节提供的方式联系我们，请求我们删除您的个人信息：

•	如果我们处理个人信息的行为违反法律法规；
•	如果我们收集、使用您的个人信息，却未征得您的同意；
•	如果您同意我们收集、使用您的个人信息后又撤回同意；
•	如果我们处理个人信息的行为违反了与您的约定；
•	如果您不再使用我们的产品或服务，或您注销了账号；
•	如果我们不再为您提供产品或服务；
•	如果您个人信息的处理目的已实现、无法实现或者为实现处理目的不再必要；
•	如果您个人信息的保存期限已经届满。

注销账号

在您注销应用账号后，我们将停止为您提供应用的全部产品和/或服务，并依据法律法规相关要求通过匿名化等方式处理您的个人信息，或删除与您账号相关的个人信息，但法律法规或监管机构对用户信息存储时间另有规定的除外。您可以通过政策第10节提供的方式联系我们，并申请注销您的账号。

我们将尽快审核所涉问题，并在15日或法律法规规定的期限内予以反馈。

为保障安全，如您向我们提出请求，我们将验证您的个人信息主体身份后处理您的请求。

对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情况收取一定成本费用。对于那些技术上难以实现、根据法律、行政法规的规定无需响应的请求或响应您的请求将违反法律、行政法规时，我们可能会予以拒绝，并向您说明理由。


<strong>7. 政策变更</strong>

我们可能会适时对本政策进行修订。当本政策的条款发生变更时，我们会在版本更新时向您提示变更后的隐私保护指引，并向您说明生效日期。当政策发生重大变更时，我们还会提供更为显著的通知，将变更部分告知您。

上述“重大变更”包括：

•	个人信息处理者的名称或者姓名和联系方式；
•	个人信息的处理目的、处理方式，处理的个人信息种类、保存期限；
•	个人行使本法规定权利的方式和程序；
•	法律、行政法规规定应当告知的其他事项。

请您仔细阅读变更后的隐私保护指引内容，您继续使用应用表示您同意我们按照更新后的政策处理您的个人信息。如果个人信息的处理目的、处理方式和处理的个人信息种类发生变更的，我们将重新取得您的同意。


<strong>8. 年龄限制</strong>

您必须年满18周岁才能使用此应用。

<strong>9.法律管辖及国际仲裁</strong>

如管辖权有冲突，本政策将以中华人民共和国相关法律为准。

因本政策引起的或与本政策有关的任何争议，均优先提请北京仲裁委员会／北京国际仲裁中心按照其仲裁规则进行仲裁。国际仲裁优先提请香港国际仲裁中心按照其仲裁规则进行仲裁。若有必要，经双方协商并一致同意后，还可提请英格兰/威尔士地区国际仲裁机构按照其仲裁规则进行仲裁。

<strong>10. 注销及联络渠道</strong>

如果您对政策或个人信息保护相关事宜有任何疑问或投诉、建议，或当您需要注销账户时，您可以将您的问询或请求发送至privacy@edapp.com，我们将在15个工作日内处理。在应用内通过以下路径：“我的个人资料-账户设置-删除账户”即可删除账户。

</p>
`,
  'user-agreement': `<center>
<strong>EdApp</strong>
使用条款
</center>

<p>最后更新时间：2024年06月25日</p>
<p>本条款仅适用于Safety Culture公司（简称为“本司”）的“EdApp”应用程序。</p>
<p>Safety Culture公司已授权北京赛诺歌讴科技有限公司为其在中国大陆安卓应用商店发布维护“EdApp”应用程序。</p>
<p>以下条款和条件约束您访问和使用应用程序以及与应用程序相关的所有软件、服务、数据或内容（统称为“内容”）。就本使用条款而言，“内容”一词是指任何文本、图形、图像、音乐、音频、视频、任何类型的作者作品，以及发布、生成或提供的数据、信息或其他材料，包括用户提供生成的内容。这些使用条款构成您与本司之间具有约束力的法律协议。本使用条款中未定义的条款应具有EdApp条款条件(“协议”)中定义的含义。</p>
<p>您了解并同意：通过访问或使用应用程序，表明您已阅读、理解并同意接受这些使用条款的约束。如果您不同意这些使用条款，那么您无权访问或使用我们的应用程序。</p>
<p>
1. 年龄限制

除非得到您父母或法定监护人的同意或授权，否则您只有在年满18岁或以上时才能访问和使用我们的服务。

2. 使用条款修改

本司保留自行决定随时修改或终止应用程序服务或修改这些使用条款的权利，恕不另行通知。如果我们修改这些使用条款，我们将在网站上发布。在我们在网站上发布修改后继续访问或使用应用程序服务，即表示您同意接受修改后的使用条款内的约束。如果您不接受修改后的使用条款，您唯一的办法是停止访问和使用应用程序服务。

3. 所有权

本服务和内容受美国及其他国家的版权、商标和其他法律的保护。除非本使用条款明确规定，否则本司及其许可方仅拥有服务和内容的所有权利、所有权和利益，也包括所有相关的知识产权。

4. 内容许可

在您遵守这些使用条款条件的前提下，本司授予您有限的、非独占的、不可转让的许可，而非从属许可，仅您个人和非商业访问及查看任何内容为目的。除非明确允许，否则您不得使用，复制，改编，修改，分发，许可，出售，转让，公开展示，公开执行，传输，流式传输，广播或以其他方式利用服务或内容的衍生作品。在这些使用条款中。除了本使用条款中明确授予的许可和权利之外，本司或其许可方拥有或控制的任何知识产权均不暗示或以其他方式授予您许可或权利。

5. 通用禁令

您同意不执行以下任何操作：
• 通过服务发送任何非法内容;
• 使用、展示、镜像或框架服务，或服务中的任何单个元素，任何本司商标，徽标或其他专有信息，或页面上包含的任何内容或表单的布局和设计;
• 访问，篡改或使用服务的非公共区域，本司的计算机系统或本司提供商的技术交付系统;
• 避免，绕过，删除，停用，损害，或以其他方式规避为保护服务或内容而实施的任何技术措施;
• 将服务或内容用于任何商业目的或任何第三方的利益，或以本使用条款不允许的任何方式使用;
• 尝试破译，反编译，反汇编或反向工程，用于提供服务或内容的任何软件；干扰或试图干扰任何用户，主机或网络的访问，包括但不限于发送病毒，重载，泛滥，垃圾邮件或邮件轰炸的服务；违反任何适用的法律或法规;鼓励或允许任何其他人做上述任何一项。
• 干扰或试图干扰任何用户、主机或网络的访问，包括但不限于发送病毒、超载、淹没、垃圾邮件或邮件轰炸服务;
• 违反任何适用的法律或法规;
• 鼓励或允许任何其他人做上述任何一项。

6. 隐私

您对我们应用程序服务的使用受本司的隐私政策的约束，其条款通过此引用而成为本使用条款的一部分。

7. 免责声明

除法律禁止之外，本司明确拒绝承担任何口头或书面，明示，暗示，法定或其他形式的陈述和保证，包括但不限于对适销性，特定用途的适用性的暗示保证，非侵权，以及根据法律允许的最大范围内的交易或使用贸易过程中产生的任何担保。服务和所有内容均按“原样”和“现有”提供，并具有所有缺陷基础。

在适用法律允许的范围内，我们不对任何服务或任何内容的质量，准确性，及时性，真实性，完整性或可靠性做出任何保证。在适用法律允许的最大范围内，我们不保证（I）服务将满足您的要求，（II）服务的运营将不会中断，无病毒或无错误或免于其他有害元素（III）错误将得到纠正。我们的代理人或我们提供的任何口头或书面建议均不会产生任何保证。因此，您明确承认并同意使用服务的风险由您自行承担，并且由您承担质量，性能，准确性和工作质量的全部风险。您明确承认并同意，对于您或您的医生根据数据收集，传输或显示的数据所做的任何医疗保健或相关决定，本司不承担任何责任，无论此类数据是准确还是不准确。您明确承认并同意您自行承担使用服务或内容的风险。

8. 责任范围

在适用法律允许的最大范围内，本司或任何其他参与创建，生产或提供服务内容的其他方均不对任何偶然，特殊，惩戒或后续损害承担责任，包括利润损失，数据丢失或商誉，服务中断，计算机损坏或系统故障或由于这些条款或使用或无法使用服务而产生的替代服务的成本。无论是基于保修，合同，侵权（包括疏忽），产品责任或任何其他法律理论，无论是否已经知道此类损害的可能性，即使此处规定的有限补救措施未能发现其基本目的。

在任何情况下，本司对由于这些条款使用或无法使用服务而产生的全部责任均不超过您在前十二个月内为服务（如果有）支付的金额。事件或情况会引起上述索赔。在适用法律允许的范围内，您承认并同意我们根据使用条款中规定的保证免责声明，版本和责任限制提供服务并设置服务价格。在适用法律允许的范围内，您也承认并同意这些保证免责声明，版本和责任限制反映了您与我们之间合理公平的风险分配，并且这些保证免责声明，版本和责任限制构成了你和我们之间的重要基础。如果没有这些保证免责声明，版本和责任限制，我们将无法在经济合理的基础上向您提供服务。

9. 赔款

在法律授权的范围内，您同意本司，其员工，管理人员，董事，关联公司和代理人免受任何和所有索赔，争议，要求，责任，损失，损害赔偿以及成本和费用的损害，包括但不限于由（a）您违反使用条款或（b）您访问或使用服务或内容而直接或间接导致的合理的法律和会计费用。

10. 其他

这些使用条款及其相关行为将受中国法律管辖，而不考虑其法律冲突规定。这些使用条款构成本司与您之间关于服务和内容的完整且排他性的理解和协议，这些使用条款取代本司与您之间关于服务和之前的任何口头或书面谅解或协议内容。未经本司事先书面同意，您不得通过法律或其他方式转让或转让这些使用条款。未经此类同意，您转让这些使用条款的任何企图均为无效。在遵守前述规定的前提下，本使用条款将对双方，其继承人和许可受让人的利益具有约束力。本司未能执行这些使用条款的任何权利或规定，并不构成对该或任何其他权利或规定的未来执行的放弃。放弃任何此类权利或规定只有在书面形式并由本司的正式授权代表签字后才有效。除非本使用条款中明确规定，否则任何一方根据本使用条款行使任何补救措施将不影响其根据本使用条款或其他方式提供的其他补救措施。如果由于任何原因，有管辖权的法院认定这些使用条款的任何条款无效或无法执行，则该条款将在允许的最大范围内执行，并且这些使用条款的其他条款仍将完全有效。

11. 联系我们

如果您对这些使用条款有任何疑问，请发送电子邮件至legal@safetyculture.io
</p>

`
};
