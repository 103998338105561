type TransformOptions = {
  baseUrl: string;
  transformedHostnames: string[];
  transformation?: string;
};

export function transformSrc(
  src: string,
  { baseUrl, transformedHostnames, transformation }: TransformOptions
): string {
  const urlParts = parseUrlIntoParts(src);

  if (!urlParts) {
    return src;
  }

  if (!!urlParts.hostname && !transformedHostnames.includes(urlParts.hostname)) {
    return src;
  }

  if (urlParts.href.match('file:///')) {
    return src;
  }

  const pathname = cleanImagePath(urlParts);

  return buildUrl(baseUrl, pathname, transformation);
}

export function buildUrl(baseUrl: string, pathname: string, transformation?: string): string {
  return `${baseUrl}/${prependTransformation(pathname, transformation)}`;
}

export function cleanImagePath(urlParts: UrlParts): string {
  return cleanImagePathname(urlParts.pathname);
}

const REMOVED_VERSION_PATH = /^\/image\/upload/;

export function cleanImagePathname(pathname: string): string {
  pathname = pathname.replace(REMOVED_VERSION_PATH, '');

  return pathname.startsWith('/') ? pathname.substr(1) : pathname;
}

function prependTransformation(url: string, transformation?: string): string {
  if (!transformation) {
    return url;
  }

  return `t_${transformation}/${url}`;
}

const URL_MATCHER = /^(([^:\/?#]+:)?(?:\/\/((?:([^\/?#:]*):([^\/?#:]*)@)?([^\/?#:]*)(?::([^\/?#:]*))?)))?([^?#]*)(\?[^#]*)?(#.*)?$/;

type UrlParts = {
  href: string;
  origin: string;
  protocol: string;
  host: string;
  username: string;
  password: string;
  hostname: string;
  port: string;
  pathname: string;
  search: string;
  hash: string;
};

// Cross browser solution for parsing URL into parts, which doesn't rely
// on browser APIs or external library
export function parseUrlIntoParts(url: string): UrlParts | null {
  const match = url.match(URL_MATCHER);

  if (!match) {
    return null;
  }

  const result = {
    href: match[0] ?? '',
    origin: match[1] ?? '',
    protocol: match[2] ?? '',
    host: match[3] ?? '',
    username: match[4] ?? '',
    password: match[5] ?? '',
    hostname: match[6] ?? '',
    port: match[7] ?? '',
    pathname: match[8] ?? (match[1] ? '/' : ''),
    search: match[9] ?? '',
    hash: match[10] ?? ''
  };

  if (!result.protocol) {
    const possibleHost = result.pathname.split('/')[0];

    if (/:/.test(possibleHost)) {
      // localhost:3000/path/to/file
      result.host = possibleHost;

      const [hostname, port] = possibleHost.split(':');

      result.hostname = hostname;
      result.port = port;

      result.pathname = result.pathname.substr(possibleHost.length);
    } else if (/\./.test(possibleHost)) {
      // www.host.com/path/to/file
      result.hostname = possibleHost;

      result.pathname = result.pathname.substr(possibleHost.length);
    }
  } else if (result.protocol.length === 2) {
    result.protocol = 'file:///' + result.protocol.toUpperCase();
    result.origin = result.protocol + '//' + result.host;
  }

  result.href = result.origin + result.pathname + result.search + result.hash;

  return result;
}
