import type { LxStoreState } from '@maggie/store/types';

const getDialogOpen = (state: LxStoreState) => state.logout.dialogOpen;

const getLoading = (state: LxStoreState) => state.logout.loading;

export const LogoutSelectors = {
  getLoading,
  getDialogOpen
};
