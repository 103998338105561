import * as React from 'react';
import { useTransformSrc, useTransformSrcSet } from './hooks';
import type { SourceSetWithTransformation } from './types';

type Props = {
  src: string;
  srcSet?: SourceSetWithTransformation[];
  transformation?: string;
} & Omit<React.ComponentProps<'img'>, 'ref' | 'src' | 'srcSet'>;

export const Image = React.forwardRef(
  (
    { loading = 'lazy', src, srcSet, transformation, ...rest }: Props,
    ref: React.Ref<HTMLImageElement>
  ) => {
    const transformedSrc = useTransformSrc(src, transformation);

    const transformedSrcSet = useTransformSrcSet(src, srcSet, transformation);

    return (
      <img ref={ref} src={transformedSrc} srcSet={transformedSrcSet} loading={loading} {...rest} />
    );
  }
);
