import { css } from 'styled-components';

export const MaxWidthLayoutStyles = css`
  ${({ theme }) => css`
    margin-left: auto;
    margin-right: auto;
    padding-left: ${theme.space(3)}px;
    padding-right: ${theme.space(3)}px;
    box-sizing: border-box;
    max-width: ${theme.homeScreenMaxWidth}px;
    width: 100%;
  `}
`;
