import * as React from 'react';
import { Backdrop, DialogWrapper, StyledDialog } from './styled';
import { useBodyScrollLock } from '../../hooks';
import { mergeRefs } from '../../utils';
import { getAnimation } from './utils';
import type { Props as DialogProps } from './types';

export const DIALOG_TEST_ID = 'dialog';
export const DIALOG_BACKDROP_TEST_ID = 'dialog-backdrop';
export const DIALOG_WRAPPER_TEST_ID = 'dialog-wrapper';

type Props = Omit<DialogProps, 'withAnimatePresence'>;

export const BaseDialog = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      testId = DIALOG_TEST_ID,
      backdropTestId = DIALOG_BACKDROP_TEST_ID,
      wrapperTestId = DIALOG_WRAPPER_TEST_ID,
      isClosable = true,
      animationType = 'fadeInSlideDown',
      isOpen,
      usePortal,
      dialogWrapperStyle,
      isFullScreen,
      position = 'center',
      useBackdrop,
      blurBackdrop,
      onDialogClick,
      onClose,
      transition,
      variants,
      className,
      style,
      children,
      ...restOfProps
    },
    ref
  ) => {
    const dialogRef = React.useRef<HTMLDivElement | null>(null);

    const [lockScrolling, unlockScrolling] = useBodyScrollLock(dialogRef);

    // Always re-enable body scroll on unmount
    React.useEffect(() => unlockScrolling, [unlockScrolling]);

    // Enable or disable body scroll dependent on isOpen prop
    React.useEffect(() => {
      if (isOpen) {
        lockScrolling();
      } else {
        unlockScrolling();
      }
    }, [isOpen, lockScrolling, unlockScrolling]);

    const animation = getAnimation(animationType ?? 'fadeInSlideDown', position);

    // Event propagation follows the React structure and not DOM structure.
    // We don't expect modal click events from modals to travel up the tree
    // and affect other components on the React page, so we will stop here
    const stopDialogClickPropagation: React.MouseEventHandler = e => {
      e.stopPropagation();
    };

    return (
      <DialogWrapper
        data-testid={wrapperTestId}
        usePortal={usePortal}
        style={dialogWrapperStyle}
        onClick={stopDialogClickPropagation}
      >
        {!isFullScreen && useBackdrop && (
          <Backdrop
            key="backdrop"
            data-testid={backdropTestId}
            onClick={isClosable ? onClose : undefined}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            blurBackground={blurBackdrop}
          />
        )}

        <StyledDialog
          key="dialog"
          data-testid={testId}
          className={className}
          initial={animation?.initial}
          animate={animation?.animate}
          exit={animation?.exit}
          transition={transition}
          variants={variants}
          style={style}
          isFullScreen={isFullScreen}
          position={position}
          onClick={onDialogClick}
          ref={mergeRefs([ref, dialogRef])}
          {...restOfProps}
        >
          {children}
        </StyledDialog>
      </DialogWrapper>
    );
  }
);
