import * as React from 'react';

import { useSelector } from 'react-redux';

import { NotificationsBell } from '@edapp/notifications';
import { Link } from '@maggie/components/link/Link';
import { routePaths } from '@maggie/core/router/routes';
import { ConfigSelectors } from '@maggie/store/config/selectors';
import { UserSelectors } from '@maggie/store/user/selectors';

export const NotificationsLink: React.FC = props => {
  const isOnline = useSelector(ConfigSelectors.isOnline);
  const isEnabled = useSelector(UserSelectors.hasNotificationsEnabled);

  if (!isOnline || !isEnabled) {
    return null;
  }

  return (
    <Link {...props} to={routePaths.notifications.create({})}>
      <NotificationsBell />
    </Link>
  );
};
