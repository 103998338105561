import * as React from 'react';

interface CustomEventMap {
  'sc:nav-changed': Event;
}

// overloads signature
export function useEventListener<T extends keyof CustomEventMap>(
  eventName: T,
  callback: (e: CustomEventMap[T]) => void,
  elements?: Window,
  options?: AddEventListenerOptions | boolean
): void;

export function useEventListener<T extends keyof WindowEventMap>(
  eventName: T,
  callback: (e: WindowEventMap[T]) => void,
  elements?: Window,
  options?: AddEventListenerOptions | boolean
): void;

export function useEventListener<T extends keyof DocumentEventMap>(
  eventName: T,
  callback: (e: DocumentEventMap[T]) => void,
  element: Document,
  options?: AddEventListenerOptions | boolean
): void;

// implementation signature
export function useEventListener<
  T extends keyof (DocumentEventMap | WindowEventMap | CustomEventMap)
>(
  eventName: T,
  callback: (e: DocumentEventMap[T] | WindowEventMap[T] | CustomEventMap[T] | Event) => void,
  element: Window | Document = window,
  options?: AddEventListenerOptions | boolean
): void {
  const callbackRef = React.useRef<typeof callback>();

  const listenerOptions: AddEventListenerOptions | undefined =
    typeof options === 'boolean' ? { capture: options } : options;

  // destructure options to be able to get use as effect dependencies
  const { capture, once, passive, signal } = listenerOptions ?? {};

  // latest ref pattern to ensure
  // that we're always calling the latest version of the callback
  // rather than one from an old render.
  React.useLayoutEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // unmount function pointer to ensure the function can be collected by garbage collector
  React.useLayoutEffect(() => {
    return () => {
      callbackRef.current = undefined;
    };
  }, []);

  // attach event listener
  React.useEffect(() => {
    // if element does not have event listener then do not attach anything
    if (element.addEventListener) {
      const eventCallback: typeof callback = e => {
        callbackRef.current?.(e);
      };

      element.addEventListener(eventName, eventCallback, options);
      return () => {
        element.removeEventListener(eventName, eventCallback);
      };
    }
  }, [eventName, element, capture, once, passive, signal, options]);
}
