import type { SlideProgressState } from './types';

export function getAlreadyEarnedStarsForSlide(
  slideProgressState: SlideProgressState,
  lessonId: string,
  slideId: string
): number {
  const slidesProgressForLesson = slideProgressState[lessonId];
  if (slidesProgressForLesson == null) {
    return 0;
  }
  const slideProgress = slidesProgressForLesson.items[slideId];
  return slideProgress ? slideProgress.earnedStars : 0;
}
