import * as React from 'react';

import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';

import { LoadingBanner } from '@maggie/components/common/LoadingBanner';
import type { RouteData, RouteName, RouteParams } from '@maggie/core/router/types';
import { AppLayoutSelectors } from '@maggie/layout/selectors';
import { UserSelectors } from '@maggie/store/user/selectors';

import { ViewBody } from './ViewBody';
import { ViewHead } from './ViewHead';

type Props = {
  routeName: RouteName;
  route: RouteData;
  routeParams?: RouteParams<RouteName>;
};

export const RouteViewLayout: React.FC<Props> = React.memo(({ routeName, route, routeParams }) => {
  const isUxp = useSelector(s => UserSelectors.getProvisioningType(s) === 'external');

  const title = useSelector(AppLayoutSelectors.getTitle(routeName, routeParams));
  const viewHeadType = useSelector(AppLayoutSelectors.getViewHeadType(routeName));

  return (
    <>
      {!isUxp && <Helmet title={`EdApp ${!!title ? `· ${title}` : title}`} />}

      <ViewHead title={title} viewHeadType={viewHeadType} />

      <ViewBody viewHeadType={viewHeadType}>
        <React.Suspense fallback={<LoadingBanner />}>
          <route.component />
        </React.Suspense>
      </ViewBody>
    </>
  );
});

RouteViewLayout.displayName = 'RouteViewLayout';
