import { AchievementColorScheme } from './types';

export function getBackgroundColorFromScheme(scheme: AchievementColorScheme) {
  switch (scheme) {
    case AchievementColorScheme.LIGHT:
      return `radial-gradient(#F3F6FB, #EBF2FF)`;
    case AchievementColorScheme.DARK:
      return 'radial-gradient(#00164E, #00122D)';
    default:
      return `radial-gradient(#F3F6FB, #EBF2FF)`;
  }
}
