import * as React from 'react';

import { schemes } from '../constants';
import type { SchemeType } from '../types';

export const SCHEME_COOKIE = 'ed-lms:theme';
const SCHEME_LOCAL_STORAGE = 'ed:theme';

export type State = {
  scheme: SchemeType;
  isDarkMode: boolean;
  useSystemScheme: boolean;
  setScheme: (selectedScheme: SchemeType) => void;
  setUseSystemScheme: React.Dispatch<React.SetStateAction<boolean>>;
};

const AppearanceContext = React.createContext<State>({
  scheme: 'ed-light',
  isDarkMode: false,
  useSystemScheme: true,
  setScheme: () => null,
  setUseSystemScheme: () => null
});

type Props = {
  initialScheme?: SchemeType;
  onSchemeChange?: (selectedScheme: SchemeType) => void;
  /**
   * Lock the theme and prevent external changes
   */
  isLocked?: boolean;
};

/**
 * @returns {string} - The theme from the url query param
 */
export const getUrlThemeParam = (location: Location) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, searchFromHash] = location.hash?.split('?') || [];
  const queryString = location.search || (searchFromHash ?? `?${searchFromHash}`);
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get('theme') as SchemeType;
};

/**
 * Order of updating scheme state
 * url > local storage > cookie > initialScheme
 * Cookie is created in Emily code
 */
const getInitialScheme = (defaultScheme: SchemeType): SchemeType => {
  if (typeof window === 'undefined') return defaultScheme;
  const urlTheme = getUrlThemeParam(window.location);
  const localTheme = window.localStorage.getItem(SCHEME_LOCAL_STORAGE) as SchemeType;
  const edCookie = getCookie(SCHEME_COOKIE);
  window.localStorage.removeItem('theme'); // removing the old theme local storage item if exists

  if (urlTheme !== null && schemes.includes(urlTheme) && urlTheme.length > 0) {
    return urlTheme;
  } else if (localTheme !== null && schemes.includes(localTheme)) {
    return localTheme;
  } else if (edCookie !== '' && schemes.includes(edCookie as SchemeType)) {
    return edCookie as SchemeType;
  } else {
    return defaultScheme;
  }
};

export const AppearanceContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  initialScheme = 'ed-light',
  onSchemeChange,
  isLocked = false
}) => {
  const [scheme, setScheme] = React.useState<SchemeType>(() =>
    isLocked ? initialScheme : getInitialScheme(initialScheme)
  );
  const [useSystemScheme, setUseSystemScheme] = React.useState<boolean>(false);

  const handleChangeScheme = (selectedScheme: SchemeType) => {
    setScheme(selectedScheme);
    window.localStorage.setItem(SCHEME_LOCAL_STORAGE, selectedScheme);
    // Scheme change function provided by each app
    onSchemeChange?.(selectedScheme);
  };

  /**
   * Order of updating scheme state
   * locked > url > local storage > cookie > initialScheme
   * Cookie is created in Emily code
   */
  React.useEffect(() => {
    if (isLocked) {
      return;
    }
    handleChangeScheme(scheme);
  }, []);

  // TODO: https://safetyculture.atlassian.net/browse/TRAINING-558
  // listen to system scheme change
  // React.useEffect(() => {
  //   if (!useSystemScheme) return;

  //   function handleChangeSystemScheme(e: MediaQueryListEvent) {
  //     const isSCtheme = scheme === 'sc-dark' || scheme === 'sc-light';
  //     const prefix = isSCtheme ? 'sc' : 'ed';
  //     const newScheme: SchemeType = e.matches ? `${prefix}-dark` : `${prefix}-light`;
  //     handleChangeScheme(newScheme);
  //   }

  //   window
  //     .matchMedia('(prefers-color-scheme: dark)')
  //     .addEventListener('change', handleChangeSystemScheme);
  //   return () => {
  //     window
  //       .matchMedia('(prefers-color-scheme: dark)')
  //       .removeEventListener('change', handleChangeSystemScheme);
  //   };
  // }, [useSystemScheme]);

  return (
    <AppearanceContext.Provider
      value={{
        scheme,
        useSystemScheme,
        isDarkMode: scheme === 'ed-dark' || scheme === 'sc-dark',
        setScheme: handleChangeScheme,
        setUseSystemScheme
      }}
    >
      {children}
    </AppearanceContext.Provider>
  );
};

/**
 * Please do not use this hook to check if it is dark mode
 * inside shared components because not all apps use the
 * AppearanceContextProvider.
 */
export const useAppearance = () => React.useContext(AppearanceContext);

/**
 *
 * @param name - The name of the cookie to be set
 * @param value - The value of the cookie
 * @param options - supports any cookie option like path, expires, maxAge and domain. [MDN Cookie Reference](https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie)
 */
export function setCookie(name: string, value: string, options: Record<string, unknown> = {}) {
  document.cookie = `${name}=${encodeURIComponent(value)}${Object.keys(options).reduce(
    (acc, key) => {
      return acc + `;${key.replace(/([A-Z])/g, $1 => '-' + $1.toLowerCase())}=${options[key]}`;
    },
    ''
  )}`;
}

/**
 * Returns a cookie value if a name is specified. Otherwise returns the entire cookies as an object
 * @param [name] - The name of the cookie to fetch the value for. Returns the entire map of cookies if not specified
 * @returns {string|Object} - The value of the cookie specified by `name` if specified. Otherwise returns a name value map of the available cookies
 */
export function getCookie(name: string) {
  const cookies = document.cookie.split(';').reduce<Record<string, string>>((acc, cookieString) => {
    const [key, value] = cookieString.split('=').map(s => s.trim());
    if (key && value) {
      acc[key] = decodeURIComponent(value);
    }
    return acc;
  }, {});
  return name ? cookies[name] || '' : '';
}
