import * as React from 'react';

import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { Box } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';
import { NotificationsLink } from '@maggie/components/notifications/link';
import type { ViewHeadType } from '@maggie/core/router/types';
import { AppLayoutContext } from '@maggie/layout/AppLayoutContext';
import { useIsMobile } from '@maggie/layout/app-mobile-context/useIsMobile';
import { NavigationIcons } from '@maggie/layout/navigation/navigation-icons/NavigationIcons';
import { MaxWidthLayoutStyles } from '@maggie/layout/styled';
import { UserSelectors } from '@maggie/store/user/selectors';

import { MenuToggle } from './menu-toggle/MenuToggle';
import { getViewTypeStyles } from './view-utils';

type Props = {
  title: string;
  viewHeadType: ViewHeadType | { mobile: ViewHeadType; desktop: ViewHeadType };
};

export const ViewHead: React.FC<Props> = React.memo(({ title, viewHeadType }) => {
  const isNewNavbar = useSelector(UserSelectors.hasNewNavbarEnabled);
  const {
    locked,
    isAppMenuOpen,
    shouldShowStarBalance,
    isGameAvailable,
    balance,
    viewHeadComponents,
    hasBackButton
  } = React.useContext(AppLayoutContext);

  const isMobile = useIsMobile();
  const shouldDisplayMenuToggle = isNewNavbar ? hasBackButton : hasBackButton || isMobile;

  if (locked) {
    return null;
  }

  return (
    <ViewHeadComponent data-testid="view-head" isMobile={isMobile} viewType={viewHeadType}>
      <ContentWrapper>
        {shouldDisplayMenuToggle ? <MenuToggle /> : <span></span>}

        <Box
          pr={!isMobile && hasBackButton ? 10.5 : 0}
          flex="1 0 auto"
          alignItems="center"
          justifyContent="center"
          justifySelf="center"
        >
          <StyledText variant="bodySmallStrong">{title}</StyledText>
        </Box>
        <Box flex justifySelf="end">
          {isMobile && <StyledNotificationsLink />}

          {!isNewNavbar && (
            <NavigationIcons
              shouldShiftIcons={isAppMenuOpen}
              shouldShowStarBalance={shouldShowStarBalance}
              isGameAvailable={isGameAvailable}
              balance={balance}
              viewHeadComponents={viewHeadComponents}
              useIconsFor={!isMobile ? 'viewHead' : undefined}
            />
          )}
        </Box>
      </ContentWrapper>
    </ViewHeadComponent>
  );
});

const StyledText = styled(Text)`
  ${({ theme }) => theme.lineClamp(1)}
`;

const ContentWrapper = styled.div`
  position: relative;
  ${MaxWidthLayoutStyles}
  padding-left: 0;
  padding-right: 0;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto 1fr;
`;

type ViewHeadComponentProps = {
  isMobile: boolean;
  viewType: ViewHeadType | { mobile: ViewHeadType; desktop: ViewHeadType };
};
const ViewHeadComponent = styled.div<ViewHeadComponentProps>(
  ({ theme, isMobile, viewType }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    z-index: 1;
    flex-shrink: 0;
    transition: all 300ms;
    height: ${theme.topContainerHeight}px;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);

    ${!isMobile &&
    css`
      padding-top: ${theme.space(1)}px;
      padding-bottom: ${theme.space(1)}px;
    `}

    ${getViewTypeStyles(viewType, isMobile, theme).viewHead}
    
    background-color: ${theme.colors.white};
    color: ${theme.colors.text};
    border-bottom: 1px solid ${theme.colors.grey};
  `
);

const StyledNotificationsLink = styled(NotificationsLink)(
  ({ theme }) => css`
    margin-right: ${theme.space(1)}px;
  `
);
