import * as React from 'react';

import { motion } from 'framer-motion';
import { useDispatch } from 'react-redux';
import styled, { css } from 'styled-components';

import { Text, icons } from '@edapp/sc-web-ui';
import { ToolbarBehaviour } from '@maggie/core/router/types';
import { AppLayoutContext } from '@maggie/layout/AppLayoutContext';
import { useIsMobile } from '@maggie/layout/app-mobile-context/useIsMobile';
import { MaxWidthLayoutStyles } from '@maggie/layout/styled';
import { NavigationActions } from '@maggie/store/navigation/actions';

import { useSplitViewContext } from '../../split-view-context/useSplitViewContext';

type Props = {
  title: string;
  textColor: string;
  toolbarBehaviour: ToolbarBehaviour;
};

export const MainToolbarContent: React.FC<Props> = React.memo(
  ({ title, textColor, toolbarBehaviour }) => {
    const dispatch = useDispatch();

    const isMobile = useIsMobile();
    const { hasBackButton } = React.useContext(AppLayoutContext);
    const { mainRoute, mainParams } = useSplitViewContext();

    const handleClick = () => {
      dispatch(NavigationActions.goBack(mainRoute, mainParams));
    };

    return (
      <Container
        data-testid="main-toolbar"
        color={textColor}
        toolbarBehaviour={toolbarBehaviour}
        isMobile={isMobile}
      >
        {/* Go back */}
        {hasBackButton ? (
          <GoBackButton
            data-testid="go-back-button"
            onClick={handleClick}
            color="accent.text.default"
            variant="labelMedium"
          >
            <icons.ChevronLeft size={24} color="accent.text.default" />
          </GoBackButton>
        ) : (
          <div />
        )}

        {/* Title */}
        <Title
          data-testid="toolbar-title"
          variant="bodySmallStrong"
          textAlign="center"
          clamp={1}
          color="surface.text.default"
        >
          {title}
        </Title>

        {/* Actions */}
        <div />
      </Container>
    );
  }
);

const Title = motion(Text);

const GoBackButton = styled(Text)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

type ContainerProps = { toolbarBehaviour: ToolbarBehaviour; isMobile: boolean };
const Container = styled(motion.div)<ContainerProps>(
  ({ color, toolbarBehaviour, isMobile }) => css`
    display: grid;
    align-items: center;
    grid-template-columns: 48px 1fr 48px;

    .is-ios & {
      padding-top: constant(safe-area-inset-top);
      padding-top: env(safe-area-inset-top);
    }

    position: ${toolbarBehaviour === ToolbarBehaviour.fixed ? 'relative' : 'absolute'};
    top: 0;
    left: 0;
    right: 0;

    width: 100%;
    height: ${({ theme }) => theme.toolbarHeight}px;
    overflow: hidden;
    ${MaxWidthLayoutStyles}
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid ${({ theme }) => theme._ui.colors.surface.border.default};
    flex-shrink: 0;
    color: ${color};
    background-color: ${({ theme }) => theme.colors.white};

    box-sizing: ${isMobile ? 'content-box' : 'border-box'};
  `
);
