import { groupBy, uniqBy } from 'lodash';

import type { LxStoreState } from '@maggie/store/types';

const getRegistrationFields = (state: LxStoreState) => {
  const registrationSettings = state.register.registrationSettings;
  const fields = uniqBy(registrationSettings.fields, 'name');

  const groups = groupBy(fields, 'group');

  // to ensure compliance goes at last
  if (groups.compliance) {
    const { compliance, ...newGroups } = groups;
    return { ...newGroups, compliance };
  }

  return groups;
};

const DEFAULT_LOGO_IMAGE =
  'https://media.edapp.com/image/upload/v1634620715/registration/default-logo.png';
const getCustomLogo = (state: LxStoreState) => {
  const logo = state.register.registrationSettings.useCustomLogo
    ? state.register.registrationSettings.customLogoUrl || DEFAULT_LOGO_IMAGE
    : DEFAULT_LOGO_IMAGE;
  return logo;
};

const DEFAULT_BACKGROUND_IMAGE =
  'https://media.edapp.com/image/upload/v1642636274/registration/Background-new.png';
const getBackgroundImg = (state: LxStoreState) => {
  return state.register.registrationSettings.customBackgroundUrl || DEFAULT_BACKGROUND_IMAGE;
};

const getIsLoading = (state: LxStoreState) => state.register.loading;

const getError = (state: LxStoreState) => state.register.error;

const getApplicationId = (state: LxStoreState) => state.register.registrationSettings.applicationId;

export const RegisterSelectors = {
  getRegistrationFields,
  getCustomLogo,
  getIsLoading,
  getError,
  getBackgroundImg,
  getApplicationId
};
