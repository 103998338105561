/**
 *  ___________  _______________
 * |           |                |
 * |           |                |
 * |   Main    |      Detail    |
 * |           |                |
 * |           |                |
 * |----------  ----------------|
 */
import * as React from 'react';

import styled from 'styled-components';

import type { RouteName, RouteParams } from '@maggie/core/router/types';

import { SplitViewContextProvider } from './split-view-context/SplitViewContextProvider';
import { SplitViewDetail } from './split-view-detail/SplitViewDetail';
import { SplitViewMain } from './split-view-main/SplitViewMain';

type Props = {
  mainRoute: RouteName;
  mainParams: RouteParams<RouteName>;
};

export const RouteSplitViewLayout: React.FC<Props> = React.memo(({ mainRoute, mainParams }) => {
  return (
    <SplitViewContextProvider mainRoute={mainRoute} mainParams={mainParams}>
      <Container data-testid="split-view-layout">
        <SplitViewMain />

        <SplitViewDetail />
      </Container>
    </SplitViewContextProvider>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: row;

  width: 100%;
  height: 100%;

  background-color: ${({ theme }) => theme.colors.white};
`;
