import type { AuthoringTypes, SlideType, StyleConfiguration } from '@edapp/authoring-logic';
import type { MeetingType } from '@edapp/hippo-client';
import type { CourseUserPermissions } from '@edapp/permissions';
import type { ErrorResponseType, RequestTypes, ValidationErrorResponseType } from '@edapp/request';

export type ErrorType = ErrorResponseType | ValidationErrorResponseType;

export type ContentLibraryCourseType = {
  media?: React.ReactNode;
  title: React.ReactNode;
  description: React.ReactNode;
  lessonsCount: number;
  lessons: Array<{ id: string; title: string; lessonType: AuthoringTypes.LessonDiscriminatorEnum }>;

  isPublished: boolean;

  rating?: RatingType;
  urlSlug: string;
  targetedAudience: TargetedAudience;
  groupTrainingInfo?: {
    isEnabled: boolean;
    inProgressSessionId?: string;
    hasCompletedSession: boolean;
    bookmark?: {
      lessonId: string;
      slideIndex: number;
    };
  };
  userPermissions: CourseUserPermissions[];
} & Omit<
  AuthoringTypes.CourseType,
  | 'config'
  | 'completionCriteria'
  | 'userGroups'
  | 'metaTags'
  | 'lessons'
  | 'modules'
  | 'internationalisation'
  | 'prerequisites'
  | 'sequentialPlaylists'
  | 'planning'
  | 'mandatory'
  | 'dueBy'
  | 'publishedDate'
  | 'publishOn'
  | 'unpublishOn'
  | 'display'
  | 'thomasUrl'
  | 'externalIdentifier'
  | 'hasNextLessonButton'
  | 'collectionId'
  | 'collectionTitle'
  | 'collectionRank'
  | 'collectionIsManual'
  | 'isContentReady'
  | 'isFromContentLibrary'
  | 'isInappropriate'
  | 'tags'
  | 'leaderboard'
  | 'isRapidRefresh'
  | 'creationType'
  | 'isGroupTrainingEnabled'
  | 'versionNumber'
  | 'hasUnpublishedChanges'
  | 'libraryImage'
>;

export enum TargetedAudience {
  NOT_SPECIFIED = 'NotSpecified',
  TEAM_TRAINER = 'TeamTrainer',
  INDIVIDUAL_LEARNER = 'IndividualLearner'
}

export type RatingType = {
  averageRating?: number;
  numberOfRatings?: number;
  numberOfReviews?: number;
};

export type ReviewType = {
  id: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  courseId: string;
  appUserId: string;
  rating: number;
  review: string;
};

export type ContentLibrarySectionType = {
  id: string;
  name: string;
  courses: RequestTypes.PaginatedResponse<ContentLibraryCourseType>;
};

export type Industry = {
  name: string;
  tagId: string;
  courseCount: number;
};

export type CoursesWithUpdates = {
  ids: string[];
  totalCount: number;
};

export type ContentLibraryState = {
  loading: boolean;
  error: string;

  sections: RequestTypes.PaginatedResponse<ContentLibrarySectionType>;

  searchTerm: string;
  courseSearchResults: RequestTypes.PaginatedResponse<ContentLibraryCourseType>;

  /**
   * -- Learners App Only -- (as LMS doesn't have course preview, yet?!)
   * This is only used in case deeplink into a course and course is not part of any section
   * or also not part of search results
   */
  courseItem: ContentLibraryCourseType | undefined;

  /**
   * This tells if the network request of adding a course to the library is in progress
   */
  addingCoursesLoading: boolean;
  /**
   * This tells if the network request of adding a course to the library had an error
   */
  addingCoursesError: string;

  coursesWithUpdates: CoursesWithUpdates;
};

export type LessonPreview = {
  id: string;
  slides: SlideType[];
  config: StyleConfiguration;
  lessonType: AuthoringTypes.LessonDiscriminatorEnum;
  description?: string;
  title?: string;
} & Partial<Pick<MeetingType, 'startDate' | 'endDate' | 'link' | 'recordingLink'>>;
