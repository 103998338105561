import * as React from 'react';
import { motion } from 'framer-motion';
import { appMenuTransitions } from './transitions';
import styled from 'styled-components';

const MenuList: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return <StyledMenuList variants={appMenuTransitions.menuList}>{children}</StyledMenuList>;
};

const StyledMenuList = styled(motion.ul)`
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.middle};

  width: 264px;
  height: 100%;

  /* reset styles */
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;

  padding-top: ${({ theme }) => theme.space(6)}px;
  padding-top: calc(constant(safe-area-inset-top) + ${({ theme }) => theme.space(6)}px);
  padding-top: calc(env(safe-area-inset-top) + ${({ theme }) => theme.space(6)}px);
  box-sizing: border-box;
`;

export { MenuList };
