import * as React from 'react';

import * as DOMPurify from 'dompurify';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, Button } from '@edapp/ed-components';
import { FullWindowModal } from '@maggie/components/common/FullWindowModal/FullWindowModal';
import { Screens } from '@maggie/theme/screens';

import { POLICY_CONTENT } from './constant';
import type { PolicyContentType } from './type';

type Props = {
  isOpen: boolean;
  type: PolicyContentType;
  onClose: () => void;
};

export const ChinaPolicyContent: React.FC<Props> = ({ isOpen, type, onClose }) => {
  const { t } = useTranslation('learners-experience');
  return (
    <StyledFullWindow open={isOpen} withoutBox={true} backgroundColor="navyHover" textColor="white">
      <StyledBox flex={true} flexDirection="column" testId={`${type}-policy-content`}>
        <Content
          flexGrow={1}
          mb={3}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(POLICY_CONTENT[type]) }}
        />
        <Button variant="light" onClick={onClose}>
          {t('lesson-summary.actions.close')}
        </Button>
      </StyledBox>
    </StyledFullWindow>
  );
};

const StyledFullWindow = styled(FullWindowModal)`
  z-index: ${({ theme }) => theme.zIndex.PRIVACY_DIALOG};
  ${({ theme }) => `
    padding-top: calc(constant(safe-area-inset-top) + ${theme.space(3)}px);
    padding-top: calc(env(safe-area-inset-top) + ${theme.space(3)}px);
    
    padding-bottom: calc(constant(safe-area-inset-bottom) + ${theme.space(3)}px);
    padding-bottom: calc(env(safe-area-inset-bottom) + ${theme.space(3)}px);
  `}
`;

const StyledBox = styled(Box)`
  height: 100%;
  margin-right: auto;
  margin-left: auto;
  width: 85%;
  @media (min-width: ${Screens.screenLgMin}px) {
    width: 720px;
  }
`;

const Content = styled(Box)`
  overflow-y: auto;
  white-space: pre-wrap;
  touch-action: pan-y;
`;
