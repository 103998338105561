import * as React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { itly } from '@edapp/analytics-tracking';
import { IconButton, LightningIcon } from '@edapp/ed-components';
import { checkOnline } from '@maggie/cordova/network_utils';
import { BrainBoostSelectors } from '@maggie/store/brain-boost/selectors';
import { ConfigSelectors } from '@maggie/store/config/selectors';
import { UserSelectors } from '@maggie/store/user/selectors';

import { BrainboostOfflineDialog } from './BrainboostOfflineDialog';

export const BrainboostIcon: React.FC = () => {
  const hasQuizEnabled = useSelector(s => UserSelectors.hasQuizEnabled(s.user));
  const { hasCompletedBoostedSlides } = useSelector(BrainBoostSelectors.getIsBrainBoostAvailable);
  const isOnline = useSelector(ConfigSelectors.isOnline);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const onClickBrainboostIcon = () => {
    if (!checkOnline()) {
      setDialogOpen(true);
      return;
    }
    itly.brainBoostLearnerOpen({ trigger: 'header-icon', from: 'home' });
    window.__router.navigate('brainBoost', {});
  };

  const onCloseDialog = () => setDialogOpen(false);

  if (!isOnline) {
    return null;
  }

  return (
    <>
      {hasQuizEnabled && hasCompletedBoostedSlides && (
        <StyledIconButton
          icon={LightningIcon}
          onClick={onClickBrainboostIcon}
          iconSize="md"
          iconColor="blue"
          variant="transparent"
        />
      )}
      <BrainboostOfflineDialog open={dialogOpen} onClose={onCloseDialog} />
    </>
  );
};

const StyledIconButton = styled(IconButton)`
  ${({ theme }) => `
  padding: 0 ${theme.space(1)}px
`}
  :hover:enabled {
    background-color: transparent;
  }
  :enabled:focus {
    background-color: transparent;
  }
`;
