import * as React from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import type { FeatureFlagsResponse } from '@edapp/feature-flags';
import { FeatureFlagsProvider as BaseFeatureFlagsProvider } from '@edapp/feature-flags';
import { FeatureFlagsActions } from '@maggie/store/feature-flags/actions';
import type { Flags } from '@maggie/store/feature-flags/constants';
import { FeatureFlagsSelectors } from '@maggie/store/feature-flags/selectors';

export const FeatureFlagsProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const dispatch = useDispatch();

  const flags = useSelector(FeatureFlagsSelectors.getFlags, shallowEqual);

  const handleFetchFlags = React.useCallback(() => {
    dispatch(FeatureFlagsActions.fetchFeatureFlags());
    return new Promise<FeatureFlagsResponse<Flags>>(res => res([]));
  }, []);

  return (
    <BaseFeatureFlagsProvider<Flags>
      useLocalStorage={false}
      flags={flags}
      getFeatureFlags={handleFetchFlags}
    >
      {children}
    </BaseFeatureFlagsProvider>
  );
};
