import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { getUserAuthToken } from '@edapp/notifications';
import { ENV } from '@maggie/config/env';
import { ConfigSelectors } from '@maggie/store/config/selectors';
import { UserSelectors } from '@maggie/store/user/selectors';

import type { UseAuthToken } from './types';

export const useAuthToken: UseAuthToken = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authToken, setAuthToken] = useState<string>('');

  const isOnline = useSelector(ConfigSelectors.isOnline);
  const userToken = useSelector(UserSelectors.getToken);

  useEffect(() => {
    const fetchAuthToken = async () => {
      try {
        setIsLoading(true);
        const { token } = await getUserAuthToken(ENV.HIPPO_API, userToken);
        setIsLoading(false);
        setAuthToken(token);
      } catch (error) {
        console.error('Failed to retrieve notification auth token.', error);
      }
    };

    if (isOnline && userToken && !authToken && !isLoading) {
      fetchAuthToken();
    }
  }, [userToken]);

  return authToken;
};
