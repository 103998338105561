import * as React from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { ErrorDialog } from '@maggie/components/dialogs/error-dialog/ErrorDialog';
import { ActionDialogActions } from '@maggie/store/action-dialog/actions';
import type { LxStoreState } from '@maggie/store/types';

export const ActionDialogContainer: React.FC = () => {
  const dispatch = useDispatch();
  const closeDialog = React.useCallback(() => dispatch(ActionDialogActions.closeActionDialog()), [
    dispatch
  ]);
  const { isOpen, title, message, confirmText, onConfirm } = useSelector(
    (state: LxStoreState) => state.actionDialog,
    shallowEqual
  );

  const handleConfirm = React.useCallback(() => {
    onConfirm?.();
    closeDialog();
  }, [onConfirm]);

  return (
    <ErrorDialog
      open={isOpen}
      title={title}
      message={message}
      confirm={confirmText}
      onConfirm={handleConfirm}
      onCancel={closeDialog}
    />
  );
};
