const colors = [
  '#58b99e',
  '#8548a8',
  '#de8cbd',
  '#4a9e86',
  '#b14434',
  '#ca75aa',
  '#eac645',
  '#925cb1',
  '#e99b89',
  '#e7a03c',
  '#d75745',
  '#9da382',
  '#65c97a',
  '#818c8d',
  '#af935e',
  '#55ab68',
  '#2d3a4a',
  '#9d483b',
  '#c45c24',
  '#5296d5',
  '#437eb4'
];

export function generateColor(initials: string) {
  const charCode = initials.charCodeAt(0) + initials.charCodeAt(1);
  if (!isNaN(charCode)) {
    return colors[charCode % colors.length];
  } else {
    return colors[0];
  }
}

export const generateInitials = (name: string) => {
  const splitNames = name
    .trim()
    .split(' ')
    .filter(str => str); // filter out empty/white space only strings

  const initials = `${splitNames[0]?.[0]?.toLocaleUpperCase() || ''}${
    splitNames[1]?.[0]?.toLocaleUpperCase() || ''
  }`;

  return initials;
};
