import { createSelector } from 'reselect';

import type { LxStoreState } from '@maggie/store/types';

const getCustomAchievementsList = (state: LxStoreState) =>
  state.customAchievements.customAchievementsList;

const getNewCustomAchievements = createSelector(
  (state: LxStoreState) => state.customAchievements.customAchievementsList,
  achievements =>
    achievements.filter(achievement => achievement.earnedDate && !achievement.isUserNotified)
);

const getCustomAchievementsListError = (state: LxStoreState) => state.customAchievements.error;

const getCustomAchievementsListIsLoading = (state: LxStoreState) =>
  state.customAchievements.isLoading;

export const CustomAchievementsSelectors = {
  getCustomAchievementsList,
  getNewCustomAchievements,
  getCustomAchievementsListError,
  getCustomAchievementsListIsLoading
};
