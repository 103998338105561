import type { IconComponent } from '@edapp/ed-components';

export type TopNavigationMenuItem = {
  // the router path to be updated
  path: string;
  // This is the title of element
  title: string;
  // Highlights item in the list
  active?: boolean;
  // Item hides
  hidden?: boolean;
  icon?: IconComponent;
};

export enum NavigationIconNames {
  starBalance = 'star-balance',
  brainBoost = 'brain-boost',
  brainBoostInfo = 'brain-boost-info'
}
