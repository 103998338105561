import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { HomeIcon, SearchIcon, UserIcon } from '@edapp/ed-components';
import { checkOnline } from '@maggie/cordova/network_utils';
import { Platform } from '@maggie/cordova/platform';
import type { TopNavigationMenuItem } from '@maggie/layout/navigation/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import { routePaths } from '../../core/router/routes';

export const useMenuItems = (): TopNavigationMenuItem[] => {
  const { t } = useTranslation('learners-experience');
  const { pathname } = useLocation();
  const isNewNavbar = useSelector(UserSelectors.hasNewNavbarEnabled);

  const isLoggedIn = !!useSelector(UserSelectors.getToken);

  const isOffline = Platform.isCordova() && !checkOnline();

  const hasLeaderboardEnabled = useSelector(s => UserSelectors.hasLeaderboard(s.user));
  const hasStarsEnabled = useSelector(s => UserSelectors.hasStarsEnabled(s.user));
  const canPlayLottery = useSelector(s => UserSelectors.canPlayLottery(s.user, s.game));
  const isPrizingUser = useSelector(s => UserSelectors.isPrizingUser(s.user));
  const hasMyProfileEnabled = useSelector(UserSelectors.hasMyProfileEnabled);
  const hasMyPerformanceEnabled = useSelector(UserSelectors.hasMyPerformanceEnabled);

  const menuItems = React.useMemo(() => {
    const items: TopNavigationMenuItem[] = [
      {
        path: routePaths.home.create({}),
        title: t('menu.item.courses.title'),
        icon: HomeIcon
      },
      {
        path: routePaths.search.create({}),
        title: t('home.search'),
        icon: SearchIcon,
        hidden: !isNewNavbar
      },
      {
        path: routePaths.profile.create({}),
        title: t('menu.item.my-profile.title'),
        hidden: !hasMyProfileEnabled,
        icon: UserIcon
      },
      {
        path: routePaths.leaderboards.create({}),
        title: t('menu.item.leaderboards.title'),
        hidden: isNewNavbar || !hasLeaderboardEnabled || isOffline
      },
      {
        path: routePaths.game.create({}),
        title: t('menu.item.star-bar.title'),
        hidden:
          isNewNavbar ||
          !(isLoggedIn && hasStarsEnabled && canPlayLottery && isPrizingUser) ||
          isOffline
      },
      {
        path: routePaths.performance.create({}),
        title: t('menu.item.my-performance.title'),
        hidden: isNewNavbar || !isLoggedIn || !hasMyPerformanceEnabled
      }
    ];

    const activeItem = items.find(i => i.path === pathname);

    if (!!activeItem) {
      activeItem.active = true;
    }

    return items.filter(i => !i.hidden);
  }, [
    pathname,
    isNewNavbar,
    hasLeaderboardEnabled,
    hasMyPerformanceEnabled,
    hasMyProfileEnabled,
    isOffline,
    isLoggedIn,
    hasStarsEnabled,
    canPlayLottery,
    isPrizingUser
  ]);

  return menuItems;
};
