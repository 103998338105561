import type { RumInitConfiguration } from '@datadog/browser-rum';

export const defaultConfig: Required<Pick<
  RumInitConfiguration,
  | 'site'
  | 'useCrossSiteSessionCookie'
  | 'trackInteractions'
  | 'trackFrustrations'
  | 'defaultPrivacyLevel'
  | 'sessionSampleRate'
  | 'sessionReplaySampleRate'
  | 'allowedTracingUrls'
>> = {
  site: 'datadoghq.com',
  useCrossSiteSessionCookie: true,
  trackInteractions: true,
  trackFrustrations: true,
  defaultPrivacyLevel: 'allow',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 0,
  allowedTracingUrls: [
    { match: 'https://*.edapp.com', propagatorTypes: ['tracecontext', 'datadog'] }
  ]
};
