import { FileManager } from '@maggie/cordova/file';
import { Platform } from '@maggie/cordova/platform';

const ApplicationDirectories = [
  'data',
  'data/images',
  'data/slidedecks',
  'downloads',
  'downloads/assets',
  'downloads/engines',
  'downloads/lessons',
  'downloads/courses',
  'downloads/collections',
  'downloads/playlists'
];

export class FileUtilities {
  /**
   * Prepares the writable folders needed for other parts of the application.
   *
   * @static
   * @memberof FileUtilities
   */
  static async prepareWritableFolders() {
    if (Platform.isCordova()) {
      // Create each directory sequentially
      for (const dir of ApplicationDirectories) {
        const entry = await FileManager.resolveLocalFileSystemURL(FileManager.dataDirectory);
        if (entry && 'getDirectory' in entry) {
          await new Promise((resolve, reject) => {
            entry.getDirectory(dir, { create: true, exclusive: false }, resolve, reject);
          });
        }
      }
    }
  }
}
