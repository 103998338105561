import styled, { css } from 'styled-components';
import { Box, Badge } from '@edapp/ed-components';

export const StyledBellContainer = styled(Box)`
  position: relative;
`;

export const StyledBadge = styled(Badge)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.space(1)}px;
    right: ${theme.space(1)}px;

    & > * {
      cursor: pointer;
      user-select: none;
    }
  `
);
