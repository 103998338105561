import { rgba } from '@edapp/themes';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { ChevronLeftIcon, ChevronRightIcon } from '../../icons';

export type ArrowProps = {
  disabled?: boolean;
  onClick?: () => void;
  direction: 'next' | 'previous';
  className?: string;
};

const SwipeableCarouselArrow: React.FC<ArrowProps> = ({
  disabled = false,
  onClick,
  direction,
  className
}) => {
  return (
    <Arrow
      data-testid={`carousel-arrow-${direction}`}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      direction={direction}
      className={className}
    >
      {direction === 'previous' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </Arrow>
  );
};

export const Arrow = styled.span<ArrowProps>`
  ${({ theme, disabled, direction }) => css`
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    z-index: 20;
    border-radius: 50%;
    background: ${disabled ? rgba(theme.colors.greyHover, 0.8) : rgba(theme.colors.brand, 0.8)};
    color: ${theme.colors.white};
    transition: all 300ms;
    left: ${direction === 'previous' ? 0 : 'auto'};
    right: ${direction === 'next' ? 0 : 'auto'};
    transform: translate3d(${direction === 'previous' ? '-50%' : '50%'}, -50%, 0);
    path {
      fill: ${theme.colors.white};
    }
    &:hover {
      background: ${disabled ? rgba(theme.colors.greyHover, 0.8) : theme.colors.black};
      cursor: ${disabled ? 'default' : 'pointer'};
    }

    @media (max-width: 376px) {
      display: none;
    }
  `}
`;

export default SwipeableCarouselArrow;
