import * as React from 'react';

import { ErrorLogger } from '../error-logger';
import { initialState } from './constants';
import type { Props, State } from './types';

export class ErrorBoundary extends React.Component<React.PropsWithChildren<Props>, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState;
    this.reset = this.reset.bind(this);
  }

  static getDerivedStateFromError: React.GetDerivedStateFromError<Props, State> = error => ({
    error
  });

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    ErrorLogger.captureException(error, { errorInfo, componentStack: errorInfo.componentStack });
    this.props.onError?.(error, errorInfo);
  }

  reset() {
    if (!this.state.error) {
      return;
    }

    this.setState(initialState);
  }

  render(): React.ReactNode {
    if (!this.state.error) {
      return this.props.children;
    }

    if (typeof this.props.fallback === 'function') {
      return this.props.fallback({ ...this.state, reset: this.reset });
    }

    return this.props.fallback;
  }
}
