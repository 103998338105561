import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { sizes } from './constants';
import type { Props, Size } from './types';

export const Backdrop = styled(motion.div)<{ blurBackground?: boolean }>`
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  touch-action: none;
  ${({ blurBackground, theme }) => blurBackground && `backdrop-filter: blur(${theme.space()}px);`}
`;

export const DialogWrapper = styled.div<Pick<Props, 'usePortal'>>(
  ({ usePortal }) => css`
    position: ${usePortal ? 'fixed' : 'absolute'};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1061; // Needs a higher z-index than Dropdown items which are 1060
  `
);

export const StyledDialog = styled(motion.div)<
  Pick<Props, 'isFullScreen' | 'position' | 'positionOverride'>
>(
  ({ isFullScreen, position, theme, positionOverride }) => css`
    background-color: ${theme.colors.white};
    ${isFullScreen
      ? css`
          top: 0;
          left: 0;
          width: 100vw;
          height: 100%;
        `
      : css`
          border-radius: ${theme.sizes.borderRadius * 6}px;
          box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
          left: 50%;
          transform: translate3d(-50%, 0, 0);
          ${!!positionOverride
            ? positionOverride
            : position === 'bottom'
            ? `
          bottom: 0;
          width: 100vw;
          max-height: 100vh;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          `
            : position === 'top'
            ? `
          top: 0;
          width: 100vw;
          max-height: 100vh;
          border-top-left-radius: 0;
          border-top-right-radius: 0;
      `
            : `
          top: 50%;
          transform: translate3d(-50%, -50%, 0);
          max-width: 90%;
          max-height: 90%;
      `}
        `}
    margin: 0;
    outline: 0;
    background-clip: padding-box;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    display: flex;
  `
);

export const sizing = css<{ size?: Size }>(({ size }) =>
  !!size
    ? css`
        width: ${sizes[size]};
      `
    : undefined
);
