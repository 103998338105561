/**
 * Temporary fix to accomodate Proxy polyfill for styled components using motion components
 */

import { createDomMotionComponent } from 'framer-motion';

/** IE 11 supported version of the motion component */
export const nonProxyMotion = {
  div: createDomMotionComponent('div'),
  span: createDomMotionComponent('span'),
  svg: createDomMotionComponent('svg'),
  label: createDomMotionComponent('label'),
  img: createDomMotionComponent('img')
};
