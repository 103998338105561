import * as React from 'react';

import type { DefaultTheme, ThemeProps } from 'styled-components';
import styled, { css } from 'styled-components';

import { Button, LOADING_COLOR_MAP } from './Button';
import type { IconButtonProps, PaddingSize } from './types';

export const ICON_BUTTON_TEST_ID = 'icon-button';

const ICON_BUTTON_MAP: Record<PaddingSize, { size: number; iconSize: PaddingSize }> = {
  xs: {
    size: 24,
    iconSize: 'xs'
  },
  sm: {
    size: 32,
    iconSize: 'md'
  },
  md: {
    size: 40,
    iconSize: 'md'
  },
  lg: {
    size: 48,
    iconSize: 'lg'
  },
  xl: {
    // there is no xl, so make it the same as 'lg' to avoid breaking
    size: 48,
    iconSize: 'lg'
  }
};

export const iconButtonStyle = <T extends keyof JSX.IntrinsicElements = 'button'>({
  theme,
  children,
  size = 'md',
  right
}: ThemeProps<DefaultTheme> & IconButtonProps<T>) => css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  // Icon is on the right/left side
  ${
    !!right
      ? css`
          padding-left: ${theme.buttonPaddings[size].x}px;
        `
      : css`
          padding-right: ${theme.buttonPaddings[size].x}px;
        `
  }
    // Icon only button
  ${
    !children &&
    css`
      width: ${ICON_BUTTON_MAP[size].size}px;
      height: ${ICON_BUTTON_MAP[size].size}px;
    `
  }
  svg {
    order: ${!!right ? 1 : 0};
    margin: ${children ? (right ? `0 0 0 ${theme.space()}px` : `0 ${theme.space()}px 0 0`) : 0};
  }
`;

const Component = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      testId = ICON_BUTTON_TEST_ID,
      icon: IconComponent,
      right,
      variant,
      iconSize,
      iconWidth,
      iconHeight,
      iconColor,
      disabled,
      isDisabled,
      ariaHideIcon = true,
      children,
      ...props
    },
    ref
  ) => {
    const internalDisabled = disabled || isDisabled;

    return (
      <Button ref={ref} variant={variant} isDisabled={internalDisabled} testId={testId} {...props}>
        {!!IconComponent && (
          <IconComponent
            color={
              internalDisabled
                ? 'textMuted'
                : iconColor || (variant ? LOADING_COLOR_MAP[variant] : 'blue')
            }
            size={iconSize ?? ICON_BUTTON_MAP[props.size ?? 'md'].iconSize}
            width={iconWidth}
            aria-hidden={ariaHideIcon}
            height={iconHeight}
          />
        )}
        {children}
      </Button>
    );
  }
);

export const IconButton = styled(Component)<IconButtonProps>`
  ${iconButtonStyle}

  ${({ isLoading }) =>
    isLoading &&
    css`
      width: fit-content;
    `}
`;
