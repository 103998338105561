import * as React from 'react';

import type { RouteName, RouteParams } from '@maggie/core/router/types';
import { ToolbarBehaviour } from '@maggie/core/router/types';

export type SplitViewContextType = {
  mainRoute: RouteName;
  mainParams: RouteParams<RouteName>;

  /**
   * Defines the behaviour of toolbar in the main section.
   *
   * Its initial value comes from the `app-routes` configuration.
   * But it can be overriden at component level.
   */
  toolbarBehaviour: ToolbarBehaviour;

  /**
   * The color of the text and go back button in the main toolbar
   *
   * @default 'white'
   */
  toolbarTextColor: string;

  /**
   * The background image of the toolbar in the main
   */
  toolbarBackground: string;

  /**
   * Defines the title that will display in the toolbar of the main section.
   *
   * Its initial value comes from the `app-routes` configuration.
   * But it can be overriden at component level.
   */
  title: string;
  setTitle: (title: string) => void;
};

export const SplitViewContext = React.createContext<SplitViewContextType>({
  mainRoute: 'home',
  mainParams: {},
  toolbarBehaviour: ToolbarBehaviour.fixed,
  toolbarTextColor: '',
  toolbarBackground: '',
  title: '',
  setTitle: () => ({})
});
