import { useLocation } from 'react-router-dom';

import { appRoutes } from '@maggie/app/constants/app-routes';

export const useRoute = () => {
  const location = useLocation();
  const matchedRoute = window.__router['hashManager'].findMatch(location.pathname);
  const routeName = matchedRoute?.routeName;
  const routeParams = matchedRoute?.params;
  const routeData = routeName ? appRoutes[routeName] : undefined;

  return {
    routeData,
    routeName,
    routeParams
  };
};
