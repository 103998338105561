import * as React from 'react';

export function useWindowResize(target?: HTMLElement) {
  const isClient = typeof window === 'object';

  const getSize = () => ({
    width: target ? target.clientWidth : isClient ? window.innerWidth : undefined,
    height: target ? target.clientHeight : isClient ? window.innerHeight : undefined
  });

  const [windowSize, setWindowSize] = React.useState(getSize);

  React.useEffect(() => {
    if (!isClient) {
      return;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  return windowSize;
}
