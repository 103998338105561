import * as React from 'react';

import { AnimatePresence } from 'framer-motion';

import { AppLaunchLoading } from '@maggie/app/AppLaunchLoading';

type Props = { show: boolean };

export const AppLaunchDialog: React.FC<Props> = ({ show }) => {
  return <AnimatePresence>{show && <AppLaunchLoading />}</AnimatePresence>;
};
