import * as React from 'react';
import styled, { css } from 'styled-components';
import { CircleOutlineIcon, CircleIcon } from '../../icons';
import { MatchMedia } from '../../match-media';
import type { ColorsKeys } from '@edapp/themes';
import type { IconSize } from '../../icons/types';

type Props = {
  active: boolean;
  onClick: () => void;
  outline?: boolean;
  color?: ColorsKeys;
  size?: IconSize;
};

const SwipeableCarouselDot: React.FC<Props> = ({
  active,
  onClick,
  color,
  size,
  outline = false
}) => {
  return (
    <MatchMedia query="only screen and (max-width: 560px)">
      {isMobile => {
        const responsiveIconSize = isMobile ? 'xs' : 'sm';
        const iconSize = size || responsiveIconSize;

        return (
          <IconWrapper onClick={onClick} active={active}>
            {outline && !active ? (
              <CircleOutlineIcon size={iconSize} color={color} />
            ) : (
              <CircleIcon size={iconSize} color={color} />
            )}
          </IconWrapper>
        );
      }}
    </MatchMedia>
  );
};

const IconWrapper = styled.div<{ active: boolean }>`
  ${({ theme, active }) => css`
    padding: 0 ${theme.space(0.5)}px;
    color: ${theme.colors.textMuted};
    cursor: ${active ? 'default' : 'pointer'};
    opacity: ${active ? 1 : 0.4};

    ${CircleOutlineIcon},
    ${CircleIcon} {
      transition: ${`all 300ms cubic-bezier( ${theme.transitionAnimation.bounce.lg})`};
      transform: ${`translate3d(0, 0, 0) ${active ? `scale(1.1)` : `scale(0.92)`}`};
      opacity: 1;
      &::after {
        content: '';
        opacity: 0;
        transform: translate3d(0, 0, 0) scale(0.8);
        transition: inherit;
      }
    }
  `}
`;

export default SwipeableCarouselDot;
