import { getBrowserVisibilityChangeEventName, getIsVisible } from '@edapp/ed-components/hooks';
import { Platform } from '@maggie/cordova/platform';
import * as Backbone from 'backbone';

export const handleVisibilityChange = (isVisible: boolean) => {
  if (isVisible) {
    Backbone.Events.trigger('resume-tracking');
  } else {
    Backbone.Events.trigger('pause-tracking');
  }
};

/**
 * Listens to when page is visible or not
 *
 * This changes when user changes tabs puts the page in background.
 *
 */
const addPageVisibilityListener = () => {
  if (Platform.isWeb()) {
    const eventName = getBrowserVisibilityChangeEventName();
    document.addEventListener(eventName, () => handleVisibilityChange(getIsVisible()));
  } else {
    // 'pause' and 'resume' are cordova events - not supported by browsers
    document.addEventListener('pause', () => handleVisibilityChange(false));
    document.addEventListener('resume', () => handleVisibilityChange(true));
  }
};

export { addPageVisibilityListener };
