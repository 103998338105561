export enum Sizes {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg'
}

export const sizes: Record<Sizes, string> = {
  [Sizes.xs]: '480px',
  [Sizes.sm]: '600px',
  [Sizes.md]: '720px',
  [Sizes.lg]: '1250px'
};
