import * as React from 'react';

import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Lottie } from '@edapp/ed-components';
import LIGHTNING_PATH from '@maggie/assets/lottie/brainboost-lightning.json';

type Props = {
  streak: number;
  previousStreak: number;
};

export const BrainBoostToastContent: React.FC<Props> = ({ streak, previousStreak }: Props) => {
  // streak is set to 0 whenever a user answers a question wrong, so a check for previousStreak>0 is required to make sure toast doesn't show if users answer questions wrong consecutively.
  if (streak === 0 && previousStreak > 0) {
    const lightningNumber = previousStreak > 3 ? 3 : previousStreak;
    return (
      <>
        <IconWrapper>
          {[...Array(lightningNumber)].map((el, index) => (
            <motion.div
              key={`lost-streak-${index}`}
              initial={{ opacity: 1, y: '0' }}
              animate={{ opacity: 0, y: `${(index + 1) * 30}%` }}
              exit={{ opacity: 1, y: '0' }}
              transition={{ duration: 1.9 }}
            >
              <LightningAnimatedIcon
                data={LIGHTNING_PATH}
                loop={true}
                autoplay={true}
                semiTransparent={true}
              />
            </motion.div>
          ))}
        </IconWrapper>
      </>
    );
  } else if (streak > 0) {
    const lightningNumber = streak > 3 ? 3 : streak;
    return (
      <>
        <IconWrapper>
          {[...Array(lightningNumber)].map((el, index) => {
            const isNew = index === lightningNumber - 1;
            if (!!isNew) {
              return (
                <motion.div
                  key={`new-${index}`}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, scale: [0, 0.25, 0.5, 0.75, 1] }}
                  exit={{ opacity: 0 }}
                  transition={{
                    duration: 0.3,
                    delay: 0.5
                  }}
                >
                  <LightningAnimatedIcon
                    data={LIGHTNING_PATH}
                    loop={true}
                    autoplay={true}
                    isLarge={true}
                  />
                </motion.div>
              );
            }

            return (
              <LightningAnimatedIcon
                key={`new-${index}`}
                data={LIGHTNING_PATH}
                loop={true}
                autoplay={true}
              />
            );
          })}
        </IconWrapper>
      </>
    );
  }
  return <></>;
};

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const LightningAnimatedIcon = styled(Lottie)<{ isLarge?: boolean; semiTransparent?: boolean }>(
  ({ isLarge, semiTransparent }) => `
  width: 70px;
  height: ${isLarge ? '110' : '80'}px;
  opacity: ${isLarge || semiTransparent ? 1 : 0.5};
`
);
