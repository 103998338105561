import styled from 'styled-components';

const TouchScroll = styled.div`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  overflow: auto;
  will-change: scroll-position;
  touch-action: pan-y;
  z-index: 1;
`;

export { TouchScroll };
