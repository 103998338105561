import * as React from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';

import { ErrorLogger } from '@edapp/monitoring';
import { appRoutes } from '@maggie/app/constants/app-routes';
import { LoadingBanner } from '@maggie/components/common/LoadingBanner';
import { useIsMobile } from '@maggie/layout/app-mobile-context/useIsMobile';
import { SplitViewLayoutSelectors } from '@maggie/store/split-view-layout/selectors';

import { useTrackView } from '../hooks';
import { SplitViewContext } from '../split-view-context/SplitViewContext';

export const SplitViewDetail: React.FC = React.memo(() => {
  const isMobile = useIsMobile();
  const { mainRoute } = React.useContext(SplitViewContext);
  const detail = useSelector(SplitViewLayoutSelectors.getCurrentSplitViewDetail, shallowEqual);
  const DetailPlaceholder = useSelector(s =>
    appRoutes[mainRoute]?.splitViewLayout?.getDetailPlaceholder?.(s)
  );

  useTrackView(detail?.routeName, detail?.params || {});

  if (isMobile) {
    return null;
  }

  if (!detail) {
    if (!!DetailPlaceholder) {
      return (
        <React.Suspense fallback={<LoadingBanner isCentered />}>
          <DetailPlaceholder />
        </React.Suspense>
      );
    }
    return null;
  }

  const DetailView = appRoutes[detail.routeName].component;

  if (!detail?.params) {
    ErrorLogger.captureEvent('Split View Detail with no params', 'error', {
      mainRoute,
      isMobile,
      detailRoute: detail?.routeName,
      detailParams: detail?.params
    });
  }

  return (
    <AnimatePresence exitBeforeEnter={true}>
      <Detail data-testid={`detail ${detail.routeName}`} key={detail.routeName}>
        <React.Suspense fallback={<LoadingBanner isCentered />}>
          <DetailView route={detail.routeName} params={detail.params} />
        </React.Suspense>
      </Detail>
    </AnimatePresence>
  );
});

const Detail = styled(motion.div)`
  flex-grow: 1;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
`;
