import * as React from 'react';

import { useHistory, useLocation } from 'react-router';
import { useLastLocation } from 'react-router-last-location';

import { Platform } from '@maggie/cordova/platform';
import type { RouteName } from '@maggie/core/router/types';
import { getLocationPath } from '@maggie/store/navigation/utils';

import { appRoutes } from './constants/app-routes';

const getRouteName = (path: string) => {
  return window.__router['hashManager'].findMatch(path)?.routeName;
};

// Diffs the stackIndex between the previous path and the current path,
// to know how to animate the transition between the two components
function getStackIndexDiff(
  prevRoute: RouteName | undefined,
  currentRoute: RouteName | undefined
): number {
  // always fade for desktop!
  // TODO: https://safetyculture.atlassian.net/browse/TRAINING-513
  if (!Platform.isMobile()) {
    return 0;
  }

  const prevStackIndex = prevRoute ? appRoutes[prevRoute].stackIndex : 0;
  const currentStackIndex = currentRoute ? appRoutes[currentRoute].stackIndex : 0;

  return currentStackIndex - prevStackIndex;
}

const AppRouteContext = React.createContext({
  /**
   * The difference in the viewstack from a route to the next one
   */
  diff: 0
});

export const useAppRouterProvider = () => {
  const context = React.useContext(AppRouteContext);
  return context;
};

export const AppRouterProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const previousLocation = useLastLocation();
  const previousDiff = React.useRef<number>(0);
  /**
   * Diff needs to be calculated at re-rendering of when the location changes
   * NOTE: we need to be careful with extra re-rendering
   * https://ed-app.atlassian.net/wiki/spaces/DEV/pages/2144174081/2022-07-27+Lesson+view+displays+blank+through+deeplink+with+SSO
   */
  const from = getRouteName(previousLocation?.pathname || '');
  const to = getRouteName(location.pathname);
  let diff = previousDiff.current;

  // do not re-calculate diff if the route is the same
  if (from !== to) {
    diff = getStackIndexDiff(from, to);
    previousDiff.current = diff;
  }

  React.useEffect(() => {
    // we listen to history changes in case user changes the URL manually
    // this will be trigger on every navigation
    // but router.navigate inside the router will prevent unnecessary
    const unlisten = history.listen(e => {
      const url = getLocationPath(e);
      if (url !== window.__router.getLastRouteUrl()) {
        window.__router.trigger(url);
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  const value = React.useMemo(() => ({ diff }), [diff]);

  return <AppRouteContext.Provider value={value}>{children}</AppRouteContext.Provider>;
};

export const MemoizedAppRouterProvider: React.FC<React.PropsWithChildren<{}>> = React.memo(
  AppRouterProvider
);
MemoizedAppRouterProvider.displayName = 'MemoizedAppRouterProvider';
