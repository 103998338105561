import { StarBalance } from '../star-balance/StarBalance';
import { BrainboostIcon } from '../brain-boost/BrainboostIcon';
import { BrainboostInfoIcon } from '../brain-boost/BrainboostInfoIcon';
import { NavigationIconNames } from '../types';
import type React from 'react';

export type DesktopViewType = 'navigation' | 'viewHead';

export const navigationIcons: Record<
  DesktopViewType,
  Partial<Record<NavigationIconNames, React.ComponentType>>
> = {
  navigation: {
    [NavigationIconNames.starBalance]: StarBalance,
    [NavigationIconNames.brainBoost]: BrainboostIcon
  },
  viewHead: {
    [NavigationIconNames.brainBoostInfo]: BrainboostInfoIcon
  }
};
