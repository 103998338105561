/**
 * @file Contains code relating to the cordova-plugin-apprate plugin.
 * This has been translated from coffeescript to typescript.
 * @see @link https://github.com/pushandplay/cordova-plugin-apprate
 */
import { t } from 'i18next';

import { ENV } from '@maggie/config/env';
import { UserActions } from '@maggie/store/user/actions';
import { UserSelectors } from '@maggie/store/user/selectors';

const ButtonMapping = ['N/A', 'decline', 'remind', 'rate'];

const settings = {
  usesUntilPrompt: 5,
  declinedRatingCooldownMonths: 1,
  remindRatingCooldownDays: 2,
  promptOnLessonsComplete: 5,
  promptOnLessonsCompleteAdmin: 3
};

const getButtonFromIndex = (index: number) => ButtonMapping[index];
const hasLoggedIn = () => !!UserSelectors.getToken(window.__store.getState());
const hasRatedApp = () => window.__store.getState().user.hasRatedApp;
const isAfter = (d1: Date, d2: Date) => d1.getTime() > d2.getTime();
const getDate = (d: number | null | undefined) => (d ? new Date(d) : undefined);

const addDays = (d: Date, n: number) => {
  const date = new Date(d);
  date.setDate(date.getDate() + n);
  return date;
};

const addMonths = (d: Date, n: number) => {
  const date = new Date(d);
  date.setMonth(date.getMonth() + n);
  return date;
};

const getLatest = (d1?: Date, d2?: Date) => {
  if (d1 && d2) {
    if (isAfter(d1, d2)) {
      return d1;
    } else {
      return d2;
    }
  } else {
    return d1 || d2;
  }
};

/**
 * Prompt the user to rate the app
 * @param { boolean } immediately Shows the rating prompt regardless of the waiting period
 */
const prompt = (immediately = false) => {
  const userState = window.__store.getState().user;

  const declineDate = getDate(userState.declinedRating);
  const remindDate = getDate(userState.remindRating);
  const latestDate = getLatest(declineDate, remindDate);
  const now = new Date();
  if (latestDate && remindDate) {
    const repromptDate =
      latestDate === declineDate
        ? addMonths(declineDate, settings.declinedRatingCooldownMonths)
        : addDays(remindDate, settings.remindRatingCooldownDays);
    if (isAfter(now, repromptDate)) {
      AppRate.promptForRating(immediately);
    }
  } else {
    AppRate.promptForRating(immediately);
  }
};

/**
 * Initializes the cordova-plugin-apprate plugin and prompts the user to rate the app
 * if certain requirements are met.
 */
const initialize = () => {
  if (window.AppRate == null || !hasLoggedIn() || hasRatedApp()) {
    return;
  }
  AppRate.preferences.inAppReview = true;
  AppRate.preferences.storeAppURL.ios = ENV.STORE_APP_URL_IOS;
  AppRate.preferences.storeAppURL.android = `market://details?id=${ENV.ANDROID_PACKAGE_NAME}`;
  AppRate.preferences.customLocale = {
    title: t('apprate.title', { ns: 'learners-experience' }),
    message: t('apprate.message', { ns: 'learners-experience' }),
    cancelButtonLabel: t('apprate.cancelButtonLabel', { ns: 'learners-experience' }),
    laterButtonLabel: t('apprate.laterButtonLabel', { ns: 'learners-experience' }),
    rateButtonLabel: t('apprate.rateButtonLabel', { ns: 'learners-experience' }),
    yesButtonLabel: t('apprate.yesButtonLabel', { ns: 'learners-experience' }),
    noButtonLabel: t('apprate.noButtonLabel', { ns: 'learners-experience' }),
    appRatePromptTitle: t('apprate.appRatePromptTitle', { ns: 'learners-experience' }),
    feedbackPromptTitle: t('apprate.feedbackPromptTitle', { ns: 'learners-experience' }),
    // Added these missing properties for typescript
    appRatePromptMessage: '',
    feedbackPromptMessage: ''
  };
  AppRate.preferences.usesUntilPrompt = settings.usesUntilPrompt;
  prompt();
};

/**
 * Prompts the user to rate the app immediately if they have not rated previously.
 */
const forcePrompt = () => {
  if (window.AppRate == null || hasRatedApp()) {
    return;
  }
  AppRate.preferences.inAppReview = true;
  AppRate.preferences.storeAppURL.ios = ENV.STORE_APP_URL_IOS;
  AppRate.preferences.storeAppURL.android = `market://details?id=${ENV.ANDROID_PACKAGE_NAME}`;
  AppRate.preferences.customLocale = {
    title: t('apprate.title', { ns: 'learners-experience' }),
    message: t('apprate.message', { ns: 'learners-experience' }),
    cancelButtonLabel: t('apprate.cancelButtonLabel', { ns: 'learners-experience' }),
    laterButtonLabel: t('apprate.laterButtonLabel', { ns: 'learners-experience' }),
    rateButtonLabel: t('apprate.rateButtonLabel', { ns: 'learners-experience' }),
    yesButtonLabel: t('apprate.yesButtonLabel', { ns: 'learners-experience' }),
    noButtonLabel: t('apprate.noButtonLabel', { ns: 'learners-experience' }),
    appRatePromptTitle: t('apprate.appRatePromptTitle', { ns: 'learners-experience' }),
    feedbackPromptTitle: t('apprate.feedbackPromptTitle', { ns: 'learners-experience' }),
    // Added these missing properties for typescript
    appRatePromptMessage: '',
    feedbackPromptMessage: ''
  };

  AppRate.preferences.callbacks.onButtonClicked = buttonIndex => {
    const result = getButtonFromIndex(buttonIndex);

    if (result === 'remind') {
      window.__store.dispatch(UserActions.remindRatingAction(new Date().getTime()));
    } else if (result === 'decline') {
      window.__store.dispatch(UserActions.declinedRatingAction(new Date().getTime()));
    }
  };

  prompt(true);
};

/**
 * Contains code relating to the cordova-plugin-apprate plugin
 * @see @link https://github.com/pushandplay/cordova-plugin-apprate
 */
export const RateTheApp = {
  prompt: forcePrompt,
  initialize
};
