import * as React from 'react';

import { useSelector } from 'react-redux';

import { NotificationsProvider as BaseNotificationsProvider, Feeds } from '@edapp/notifications';
import { ENV } from '@maggie/config/env';
import { UserSelectors } from '@maggie/store/user/selectors';

import { useAuthToken } from './hooks';
import type { Props } from './types';

export const NotificationsProvider = React.memo<React.PropsWithChildren<Props>>(({ children }) => {
  const userId = useSelector(UserSelectors.getId);
  const authToken = useAuthToken();

  const handleNotificationClick = React.useCallback((uri: string) => {
    if (!uri) {
      // if no path to navigate, don't try
    }

    window.__router.trigger(uri, false);
  }, []);

  return (
    <BaseNotificationsProvider
      apiKey={ENV.KNOCK_PUBLIC_KEY}
      feed={Feeds.InAppLx}
      userId={userId}
      knockToken={authToken}
      onNotificationClick={handleNotificationClick}
    >
      {children}
    </BaseNotificationsProvider>
  );
});
