import * as React from 'react';
import { EdIcon } from '@edapp/ed-components';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { splashScreenAnimations } from './constants';

type Props = {
  /**
   * @default white
   */
  color?: 'white' | 'black';

  /**
   * @default false
   */
  horizontal?: boolean;

  /**
   * @default null
   */
  customLogo?: JSX.Element;
};

export const MotionPoweredByEd: React.FC<Props> = ({ color, horizontal, customLogo = null }) => {
  return (
    <>
      <motion.div
        custom={horizontal}
        variants={splashScreenAnimations.rotateScale}
        initial="initial"
        animate="animate"
        transition={{
          ease: 'easeIn',
          duration: 1
        }}
      >
        {!!customLogo ? customLogo : <EdLogo size="md" horizontal={!!horizontal} color={color} />}
      </motion.div>

      <PoweredBy
        custom={horizontal}
        variants={splashScreenAnimations.fadeUp}
        initial="initial"
        animate="animate"
        color={color}
        transition={{
          ease: 'easeIn',
          duration: 0.5,
          delay: 1
        }}
      >
        Powered by EdApp
      </PoweredBy>
    </>
  );
};

const EdLogo = styled(EdIcon)<{ horizontal: boolean }>`
  ${({ horizontal, theme }) => !horizontal && `margin: 0 auto ${theme.space(0.5)}px;`}
`;

const PoweredBy = styled(motion.span)`
  font-size: 12px;
`;
