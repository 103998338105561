import * as React from 'react';
import type { AnimationItem } from 'lottie-web';
import lottie from 'lottie-web';

type Props = {
  /** the json object of animation */
  data: object;
  /** to override styles of container with styled-components */
  className?: string;
  loop?: boolean;
  autoplay?: boolean;
  play?: boolean;
};

export const Lottie: React.FC<Props> = ({ data, autoplay, loop, className, play }) => {
  const [hasLoaded, setHasLoaded] = React.useState(false);
  const [hasPlayed, setHasPlayed] = React.useState(false);

  const element = React.useRef<HTMLDivElement>(null);
  const animation = React.useRef<AnimationItem>();

  React.useEffect(() => {
    if (element.current && !hasLoaded && !hasPlayed) {
      animation.current = lottie.loadAnimation({
        container: element.current,
        renderer: 'svg',
        loop,
        autoplay: !!(play || autoplay),
        animationData: data
      });

      setHasLoaded(true);
      setHasPlayed(!!(play || autoplay));
    }
  }, []);

  React.useEffect(() => {
    if (animation.current && play && hasLoaded && !hasPlayed) {
      animation.current.play();
      setHasPlayed(true);
    }
  }, [play, hasLoaded]);

  return <div id="lottie" className={className} ref={element} />;
};
