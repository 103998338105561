import { IconButton, NotificationsIcon } from '@edapp/ed-components';
import * as React from 'react';
import { useNotifications } from '../../providers/notifications';
import { StyledBadge, StyledBellContainer } from './styled';

export const NotificationsBell = React.forwardRef<HTMLDivElement>((props, ref) => {
  const { state } = useNotifications();
  const unseenCount = state.metadata.unseen_count;

  return (
    <StyledBellContainer ref={ref} {...props}>
      <IconButton icon={NotificationsIcon} variant="transparent" iconColor={'text'} size="md" />

      <StyledBadge value={unseenCount} max={9} showOnEmpty={false} />
    </StyledBellContainer>
  );
});
