import type { FeedStoreState } from '@knocklabs/client';
import { NetworkStatus } from '@knocklabs/client';

export enum Feeds {
  InAppLms = 'df75c74d-99cf-4da7-abe2-2989357c36e4',
  InAppLx = 'ff42f818-28c6-4cf7-bd57-6a4c6dcf7377'
}

export const PAGE_SIZE = 10;

export const initialFeedStore: FeedStoreState = {
  items: [],
  loading: false,
  metadata: {
    total_count: 0,
    unread_count: 0,
    unseen_count: 0
  },
  networkStatus: NetworkStatus.ready,
  pageInfo: {
    after: null,
    before: null,
    page_size: 0
  },
  resetStore: () => ({}),
  setItemAttrs: () => ({}),
  setLoading: () => ({}),
  setMetadata: () => ({}),
  setNetworkStatus: () => ({}),
  setResult: () => ({})
};
