import Branch from 'branch-sdk';

import { itly } from '@edapp/analytics-tracking';
import { ErrorLogger } from '@edapp/monitoring';
import { ENV } from '@maggie/config/env';

import type { BranchDeepLinkProperties } from './branch';
import { Platform } from './platform';

const logInfo = (...msg: any[]) => {
  if (ENV.NODE_ENV === 'test') {
    return;
  }
  // eslint-disable-next-line no-console
  console.info(...msg);
};

/**
 * Supress adblocker errors
 */
const isValidError = (err: any) => {
  const adblockErrorMesssage = 'Request blocked by client, probably adblock';
  const timeoutErrorMessage = 'Request timed out';

  if (typeof err === 'object' && err?.message.indexOf(adblockErrorMesssage) >= 0) {
    return false; // adblocker
  }

  if (typeof err === 'object' && err?.message.indexOf(timeoutErrorMessage) >= 0) {
    return false; // timeout caused by adblocker
  }

  return true;
};

const cleanNonBranchLink = (url: string) => {
  const ED_SCHEME = 'ed://';
  if (url.indexOf(ED_SCHEME) >= 0) {
    return url.replace(ED_SCHEME, '');
  }

  const path = url.split('://').slice(1).join('://');
  const ED_LINK = /^(staging\-)?web.edapp.com\//;
  if (ED_LINK.test(path)) {
    return path.replace(ED_LINK, '');
  }

  const FEATURE_BRANCH_LINK = /web-deeplink-test.staging.edapp.com\//;
  if (FEATURE_BRANCH_LINK.test(path)) {
    return path.replace(FEATURE_BRANCH_LINK, '');
  }
};

export const UrlLaunch = {
  handleDeeplinkData: (data?: BranchDeepLinkProperties): boolean => {
    if (!data) {
      logInfo('Branch initialized with no data!');
      return false;
    }

    logInfo('Branch: initialized succesfully!', data);
    const itlyProps = {
      '+clicked_branch_link': data['+clicked_branch_link'],
      '+referrer': data['+referrer'],
      '+non_branch_link': data['+non_branch_link'],
      $canonical_url: data['$canonical_url'],
      $deeplink_path: data['$deeplink_path']
    };
    if (data['+non_branch_link']) {
      itly.deeplinkOpened(itlyProps);
      // web.edapp.com
      // ed://
      const path = cleanNonBranchLink(data['+non_branch_link']);
      window.__router.trigger(path);
      return true;
    }

    if (data['+clicked_branch_link']) {
      itly.deeplinkOpened(itlyProps);
      // this assumes deeplinks generated on branch are valid
      const path = data.$deeplink_path;
      window.__router.trigger(path);
      return true;
    }

    return false;
  },

  /**
   * @returns boolean: if redirected or not
   */
  initialize: (): Promise<boolean> => {
    let redirected: Promise<boolean> | null = null;
    try {
      redirected = new Promise<boolean>(async (resolve, reject) => {
        if (Platform.isWeb()) {
          logInfo('Branch web: initializing...');
          return Branch.init(ENV.PUBLIC_BRANCH_KEY, {}, (err, data) => {
            if (err && isValidError(err)) {
              ErrorLogger.captureEvent('Branch web: link error', 'error', { err });
              return resolve(false);
            }
            try {
              const deeplinkData = data?.data_parsed as BranchDeepLinkProperties;
              const result = UrlLaunch.handleDeeplinkData(deeplinkData);
              return resolve(result);
            } catch (err) {
              return reject(err);
            }
          });
        }

        if (!window.Branch) {
          ErrorLogger.captureEvent('Branch: not available', 'warning', {});
          return false; // not available
        }

        logInfo('Branch device: initializing...');
        if (ENV.NODE_ENV !== 'production') {
          window.Branch!.setDebug(true);
        }

        // !Note!
        // If you're testing it locally: follow the steps below
        // 1. update 'BRANCH_CONFIG_KEY' in config.xml
        // 2. prepare app again
        // 3. MAKE SURE YOU DON'T COMMIT that change
        await window
          .Branch!.initSession()
          .then(data => resolve(UrlLaunch.handleDeeplinkData(data)))
          .catch(err => {
            if (typeof err === 'string' && err.indexOf('Warning') >= 0) {
              // ignore error
              // likely to be Android saying that Branch has already initialized.
              // But we need re-init again to make deeplinks work in warm start
              // Error like: "Session initialization already happened. To force a new session, set intent extra, "branch_force_new_session", to true.""
              console.warn('Branch device:', err);
              return resolve(false);
            }

            ErrorLogger.captureEvent('Branch device: link error', 'error', { err });
            return resolve(false);
          });
      });
    } catch (err) {
      throw err;
    } finally {
      (window as any).__branchInitialized = true;
    }

    return redirected ?? Promise.resolve(false);
  }
};
