import * as React from 'react';

import { useSelector } from 'react-redux';

import { AppLayoutContext } from '@maggie/layout/AppLayoutContext';
import { useIsMobile } from '@maggie/layout/app-mobile-context/useIsMobile';
import { NotificationsProvider } from '@maggie/providers/notifications/NotificationsProvider';
import { UserSelectors } from '@maggie/store/user/selectors';

import { AppNavigation } from './app-navigation/AppNavigation';
import { BottomNavigation } from './bottom-navigation/BottomNavigation';
import { DesktopNavigation } from './desktop-navigation/DesktopNavigation';
import { NewDesktopNavigation } from './desktop-navigation/NewDesktopNavigation';

export const Navigation: React.FC<React.PropsWithChildren<{}>> = React.memo(({ children }) => {
  const isNewNavbar = useSelector(UserSelectors.hasNewNavbarEnabled);
  const isMobile = useIsMobile();
  const { hideNavigation, locked } = React.useContext(AppLayoutContext);

  /**
   *
   * Do not mess around with DOM structure when this component re-renders.
   *
   * There was a bug where the parent element of thomas iframe got destroyed from the DOM.
   * Therefore, the iframe.contentWindow also got destroyed...
   *
   */
  const shouldHaveNavigation = !hideNavigation && !locked;
  if (!shouldHaveNavigation) {
    return <>{children}</>;
  }

  if (!!isMobile) {
    return (
      <NotificationsProvider>
        <>
          {!isNewNavbar && <AppNavigation />}
          {children}
          {isNewNavbar && <BottomNavigation />}
        </>
      </NotificationsProvider>
    );
  }

  return (
    <NotificationsProvider>
      <>
        {!isNewNavbar && <DesktopNavigation />}
        {isNewNavbar && <NewDesktopNavigation />}
        {children}
      </>
    </NotificationsProvider>
  );
});
