import * as React from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';

import { AppLayoutContext } from '@maggie/layout/AppLayoutContext';

import { MenuItem } from './MenuItem';
import { MenuList } from './MenuList';
import { appMenuTransitions } from './transitions';

export const AppNavigation: React.FC = () => {
  const { menuItems, setIsAppMenuOpen, isAppMenuOpen, closeHomeSearch } = React.useContext(
    AppLayoutContext
  );

  const handleMenuItemClick = () => {
    setIsAppMenuOpen(false);
    closeHomeSearch();
  };

  const handleCloseMenu = () => {
    setIsAppMenuOpen(false);
  };

  return (
    <AnimatePresence initial={false}>
      <>
        {isAppMenuOpen && (
          <StyledBackdrop
            data-testid="app-nav-backdrop"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.4 }}
            exit={{ opacity: 0, transition: { delay: 0.7, stiffness: 1000, velocity: -100 } }}
            onClick={handleCloseMenu}
          />
        )}

        <StyledNav data-testid="app-nav" variants={appMenuTransitions.menu}>
          <MenuList>
            {menuItems.map(item => (
              <MenuItem key={item.path} item={item} onClick={handleMenuItemClick} />
            ))}
          </MenuList>
        </StyledNav>
      </>
    </AnimatePresence>
  );
};

const StyledBackdrop = styled(motion.div)`
  background-color: ${({ theme }) => theme.colors.black};
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  touch-action: none;
  z-index: 1;
`;

const StyledNav = styled(motion.nav)`
  z-index: 1;
  height: 100%;
  overflow: hidden;
`;
