import * as React from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box } from '@edapp/ed-components';
import { StyledDialog, StyledDialogActionButton } from '@maggie/components/dialogs/styled';

type Props = {
  open: boolean;
  onClose: () => void;
};

const StarBalanceOfflineDialog: React.FC<Props> = props => {
  const { t } = useTranslation('learners-experience');
  return (
    <StyledDialog isOpen={props.open} onClose={props.onClose}>
      <StyledBox textAlign="center" p="md">
        {t('game.starbar-offline')}
      </StyledBox>

      <StyledDialogActionButton onClick={props.onClose}>
        {t('common.postCompletionDialog.ok')}
      </StyledDialogActionButton>
    </StyledDialog>
  );
};

const StyledBox = styled(Box)`
  color: ${({ theme }) => theme.colors.navy};
`;

export { StarBalanceOfflineDialog };
