import * as React from 'react';
import styled from 'styled-components';
import { getAnimation } from '../utils';
import { SwipeableCarouselAnimations } from '../types';
import type { SwipeableCarouselAnimationType } from '../types';

type Props = {
  active: boolean;
  animations: string[] | SwipeableCarouselAnimationType[];
  showFullContent?: boolean;
};

const SwipeableCarouselSlide: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  active,
  animations,
  showFullContent = false
}) => {
  return (
    <Slide animations={animations} active={active} showFullContent={showFullContent}>
      {children}
    </Slide>
  );
};

const Slide = styled.li<Props>`
  list-style: none;
  width: 100%;
  white-space: normal;
  transition: all ease-in 0.3s;
  opacity: ${({ active = false, animations }) =>
    active
      ? getAnimation(SwipeableCarouselAnimations.OPACITY, animations).activeValue
      : getAnimation(SwipeableCarouselAnimations.OPACITY, animations).inactiveValue};
  transform: ${({ active = false, animations }) =>
    active
      ? `scale(${getAnimation(SwipeableCarouselAnimations.SCALE, animations).activeValue})`
      : `scale(${getAnimation(SwipeableCarouselAnimations.SCALE, animations).inactiveValue})`};
  margin: ${({ showFullContent }) => (showFullContent ? '0' : '0 8px')};

  @media (max-width: 376px) {
    display: block;
    width: 100%;
    box-shadow: none;
    opacity: 1;
    transform: scale(1);
    margin: ${({ showFullContent }) => (showFullContent ? '0' : ' 0 auto')};
  }
`;

export default SwipeableCarouselSlide;
