import type { LxStoreState } from '@maggie/store/types';

const getIsOpen = (state: LxStoreState) => state.search.isOpen;
const getIsPreviewMode = (state: LxStoreState) => state.search.isPreviewMode;
const getSearchTerm = (state: LxStoreState) => state.search.searchTerm;
const getRecentSearchTerms = (state: LxStoreState) => state.search.recentSearchesTerms;
const getFilteredItems = (state: LxStoreState) => state.search.filteredItems;
const getIsLoadingCatalog = (state: LxStoreState) => state.search.isLoadingCatalog;
const getIsFilteringCatalog = (state: LxStoreState) => state.search.isFilteringCatalog;

export const SearchSelectors = {
  getIsOpen,
  getIsPreviewMode,
  getSearchTerm,
  getRecentSearchTerms,
  getFilteredItems,
  getIsLoadingCatalog,
  getIsFilteringCatalog
};
