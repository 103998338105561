import { Box } from '@edapp/ed-components';
import styled, { css } from 'styled-components';

export const StyledBlueDot = styled.span(
  ({ theme }) => css`
    position: absolute;
    left: 10px;
    top: 25px;
    background-color: ${theme.colors.blue};
    width: 8px;
    height: 8px;
    border-radius: 50%;
  `
);

export const StyledNotificationCard = styled(Box)(
  ({ theme }) => css`
    position: relative;
    padding: ${theme.space(2)}px ${theme.space(3)}px;
    display: flex;
    text-decoration: none;

    &:hover,
    &:focus {
      background-color: ${theme.colors.lightBlue};
      text-decoration: none;
    }
  `
);

export const StyledMarkdown = styled.span(
  ({ theme }) => css`
    white-space: normal;
    color: ${theme.colors.text};

    & strong {
      font-weight: 500;
    }

    & p {
      margin-top: 0;
      margin-bottom: 0;
      word-break: break-word;
      ${theme.lineClamp(2)}
    }

    & blockquote {
      z-index: 0; // somehow there is z-index inherited from Emily
      border-left: 1px solid ${theme.colors.grey};
      padding-left: ${theme.space(2)}px;
      color: ${theme.colors.textMuted};

      // remove default pseudo-element inherited from Emily
      &::before {
        content: '';
      }
    }
  `
);
