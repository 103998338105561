import * as React from 'react';

import { isEqual } from 'lodash';

import { usePrevious } from '@edapp/ed-components';
import type { RouteName, RouteParams } from '@maggie/core/router/types';
import { Tracker } from '@maggie/core/tracker/tracker';

type TrackedViewType = ReturnType<typeof Tracker['trackView']>;

/**
 * A hook that gets triggered every time the route changes.
 *
 * It tracks the duration for how long the user spends looking at
 * a page/view
 */
export const useTrackView = <R extends RouteName>(
  route: R | undefined | null,
  routeParams: RouteParams<R>
) => {
  const previousRoute = usePrevious(route);
  const previousParams = usePrevious(routeParams);
  const [trackedView, setTrackedView] = React.useState<TrackedViewType>(undefined);

  React.useEffect(() => {
    if (previousRoute === route && isEqual(routeParams, previousParams)) {
      return; // same route - nothing to do here
    }

    trackedView?.end();

    if (!!route && window.__router.isSplitViewLayout(route)) {
      const trackedView = Tracker.trackView({ type: 'view', name: route });
      setTrackedView(trackedView);
    }
  }, [route, routeParams]);

  return trackedView;
};
