import * as React from 'react';

import { Router } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import { LoadingBanner } from '@maggie/components/common/LoadingBanner';
import { HostedWebviewUtils } from '@maggie/core/hosted_webview_utils';
import { InteractionApi } from '@maggie/core/tracker/interaction-api';
import { AppMobileContextProvider } from '@maggie/layout/app-mobile-context/AppMobileContextProvider';

import { AppLayout } from '../layout/AppLayout';
import { AppGlobalDialogs } from './AppGlobalDialogs';
import { MemoizedAppRouterProvider } from './AppRouterContext';
import { AppRoutes } from './AppRoutes';

export const App: React.FC = React.memo(() => {
  React.useEffect(() => {
    HostedWebviewUtils.triggerEvent('ready');
  }, []);

  /**
   * Gate for unsynced interactions that have not completed sync with backend
   *
   * We destroy these listeners to avoid conflicts when hosting app as MFE.
   */
  React.useEffect(() => {
    window.onbeforeunload = (e: Event) => InteractionApi.requestInProgress(e);
    window.onunhandledrejection = e => InteractionApi.unhandledPromiseRejection(e);

    return () => {
      window.onbeforeunload = null;
      window.onunhandledrejection = null;
    };
  }, []);

  return (
    <React.Suspense fallback={<LoadingBanner isCentered />}>
      <Router history={window.__router.getHistory()}>
        <LastLocationProvider>
          <MemoizedAppRouterProvider>
            <AppMobileContextProvider>
              <AppLayout>
                <AppRoutes />
              </AppLayout>
            </AppMobileContextProvider>
          </MemoizedAppRouterProvider>
          <AppGlobalDialogs />
        </LastLocationProvider>
      </Router>
    </React.Suspense>
  );
});
