import type { DialogAnimationType } from './types';

export function getPortalElement(
  usePortal: boolean | string | HTMLElement | null
): HTMLElement | null {
  if (typeof window === 'undefined') {
    return null;
  }

  if (!usePortal) {
    return null;
  }

  if (typeof usePortal === 'string') {
    return document.querySelector(usePortal) ?? document.body;
  }

  if (usePortal instanceof Element) {
    return usePortal;
  }

  return document.body;
}

export function getAnimation(
  animation: DialogAnimationType,
  position: 'top' | 'center' | 'bottom'
) {
  switch (animation) {
    case 'none':
      return undefined;
    case 'fadeIn':
      return {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 }
      };
    case 'fadeOut':
      return {
        initial: { opacity: 1 },
        animate: { opacity: 1 },
        exit: { opacity: 0 }
      };
    case 'fadeInSlideDown':
    default:
      switch (position) {
        case 'top':
          return {
            initial: { opacity: 0, y: '-100%', translateX: '-50%' },
            animate: { opacity: 1, y: '0', translateX: '-50%' },
            exit: { opacity: 0, y: '-100%', translateX: '-50%' }
          };
        case 'bottom':
          return {
            initial: { opacity: 0, y: '100%', translateX: '-50%' },
            animate: { opacity: 1, y: '0', translateX: '-50%' },
            exit: { opacity: 0, y: '100%', translateX: '-50%' }
          };
        case 'center':
        default:
          return {
            initial: { opacity: 0, translateY: '-75%', translateX: '-50%' },
            animate: { opacity: 1, translateY: '-50%', translateX: '-50%' },
            exit: { opacity: 0, translateY: '-75%', translateX: '-50%' }
          };
      }
  }
}
