import * as React from 'react';

import { Box } from '@edapp/ed-components';
import { globalZIndex } from '@maggie/theme/globalZIndex';

import { PrivacyDialog, StyledPreWrapTypography } from './styled';

type Props = {
  open: boolean;
};
export const NoConsentDialog: React.FC<Props> = ({ open }) => {
  return (
    <PrivacyDialog
      isOpen={open}
      dialogWrapperStyle={{ zIndex: globalZIndex.PRIVACY_DIALOG }}
      testId="dialog-no-consent"
    >
      <Box px={4} py={5}>
        <StyledPreWrapTypography as="p">
          抱歉，如果您不同意我们的隐私政策和用户协议，您无法继续使用应用。
        </StyledPreWrapTypography>
      </Box>
    </PrivacyDialog>
  );
};
