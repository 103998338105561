import { routePaths } from '@maggie/core/router/routes';
import type { SearchItem } from '@maggie/store/search/types';

export const getPathFromSearchItem = (item: SearchItem): string => {
  switch (item.type) {
    case 'Assignment':
      return routePaths.assignment.create({ id: item.id });
    case 'BriefcaseDocument':
      const courseId = item.metadata?.['courseId'];
      return routePaths.briefcaseDocument.create({ documentId: item.id, id: courseId });
    case 'Conference':
      return routePaths.conference.create({ id: item.id });
    case 'Course':
      return routePaths.course.create({ id: item.id });
    case 'CourseCollection':
      return routePaths.courseCollection.create({ id: item.id });
    case 'Discussion':
      return routePaths.discussion.create({ id: item.id });
    case 'Lesson':
      return routePaths.lesson.create({ id: item.id });
    case 'Observation':
      return routePaths.observation.create({ id: item.id });
    case 'Playlist':
      return routePaths.playlist.create({ id: item.id });
    default:
      return '';
  }
};
