import * as React from 'react';

import { shallowEqual, useSelector } from 'react-redux';

import { AchievementsCarousel } from '@maggie/components/achievements/AchievementsCarousel';
import { DynamicDialog } from '@maggie/components/common/DynamicDialog/DynamicDialog';
import { CustomAchievementsSelectors } from '@maggie/store/custom-achievements/selectors';
import { globalZIndex } from '@maggie/theme/globalZIndex';

export const AchievementsDialog: React.FC = () => {
  const newAchievements = useSelector(
    CustomAchievementsSelectors.getNewCustomAchievements,
    shallowEqual
  );
  const [localAchievements, setLocalAchievements] = React.useState(newAchievements);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const handleClose = () => {
    setIsDialogOpen(false);
    setLocalAchievements([]);
  };

  // We don't want to clear the achievement dialog even if the achievements list changes,
  // unless the user actually closes the dialog
  React.useEffect(() => {
    if (newAchievements.length > 0) {
      // Combine updated achievements, making sure to not have duplicates
      const mergedAchievements = [
        ...localAchievements,
        ...newAchievements.filter(x => !localAchievements.some(y => y.id === x.id))
      ];
      if (mergedAchievements.length !== localAchievements.length) {
        setLocalAchievements(mergedAchievements);
      }
    }
  }, [newAchievements.length]);

  React.useEffect(() => {
    if (localAchievements.length > 0) {
      setIsDialogOpen(true);
    }
  }, [localAchievements.length]);

  return (
    <DynamicDialog
      data-testid="achievements-dialog"
      isOpen={isDialogOpen}
      useBackdrop
      usePortal={true}
      handleClose={handleClose}
      dialogWrapperStyle={{ zIndex: globalZIndex.ACHIEVEMENTS }}
    >
      <AchievementsCarousel newAchievements={localAchievements} handleClose={handleClose} />
    </DynamicDialog>
  );
};
