import * as React from 'react';
import type { ContextType } from './types';

export const NotificationsContext = React.createContext<ContextType | null>(null);

export const useNotifications = () => {
  const context = React.useContext(NotificationsContext);

  if (!context) {
    throw new Error("Usage of 'useKnockFeed' must be within NotificationsProvider");
  }

  return context;
};
