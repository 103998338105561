import { Link as ReactRouterLink } from 'react-router-dom';
import { NavLink as ReactRouterNavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

const LinkStyles = css`
  text-decoration: none;
  color: inherit;
`;

export const Link = styled(ReactRouterLink)`
  ${LinkStyles}
`;

export const NavLink = styled(ReactRouterNavLink)`
  ${LinkStyles}
`;