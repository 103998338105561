import * as React from 'react';

import { isSameDay } from 'date-fns';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Dialog } from '@edapp/ed-components';
import { useResizeObserver } from '@edapp/ed-components';
import { MotionPoweredByEd } from '@maggie/components/custom-splash-screen/MotionPoweredByEd';
import { splashScreenAnimations } from '@maggie/components/custom-splash-screen/constants';
import { checkOnline } from '@maggie/cordova/network_utils';
import { Platform } from '@maggie/cordova/platform';
import { optimizeCloudinaryImageURL } from '@maggie/core/optimize';
import { UserSelectors } from '@maggie/store/user/selectors';

const CustomSplashScreen: React.FC<{}> = () => {
  const { t } = useTranslation('learners-experience');
  const [open, setOpen] = React.useState(true);
  const { bounds, ref: wrapperRef } = useResizeObserver<HTMLDivElement>();

  const close = () => setOpen(false);

  const { logo, background, textColor } = useSelector(
    state => state.user.splashScreen,
    shallowEqual
  );

  React.useEffect(() => {
    window.localStorage.setItem('lastCustomSplashDate', new Date().toUTCString());
  }, []);

  return (
    <Wrapper
      isOpen={open}
      useBackdrop={false}
      usePortal={false}
      onDialogClick={close}
      url={optimizeCloudinaryImageURL(background)}
      animationType="fadeOut"
      color={textColor}
      ref={wrapperRef}
    >
      <LogoWrapper>
        <MotionLogo
          src={optimizeCloudinaryImageURL(logo)}
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{
            ease: 'easeIn',
            duration: 0.5,
            delay: 0.3
          }}
          wrapperHeight={bounds?.height || 0}
        />
      </LogoWrapper>

      <MotionPoweredByEd color={textColor} />

      <MotionTap
        variants={splashScreenAnimations.fadeUp}
        initial="initial"
        animate="animate"
        transition={{
          ease: 'easeIn',
          duration: 0.5,
          delay: 1.1
        }}
      >
        {t('loader.tap-to-continue')}
      </MotionTap>
    </Wrapper>
  );
};

const Wrapper = styled(Dialog)<{ url: string; color?: 'white' | 'black' }>`
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  box-shadow: none;
  border-radius: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.blue};
  background-image: url("${({ url }) => url}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  color: ${({ theme, color = 'white' }) => theme.colors[color]};
  cursor: pointer;
`;

const LogoWrapper = styled.div`
  flex: 1 1 100%;
`;

const Logo = styled.img<{ wrapperHeight: number }>`
  width: 200px;
  height: auto;
  margin-top: ${({ wrapperHeight }) => `${wrapperHeight * 0.25}px`};
`;

const TapToContinue = styled.h2`
  margin: ${({ theme }) => `${theme.space(2)}px 0 ${theme.space(4)}px !important`};
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
`;

const MotionLogo = motion(Logo);

const MotionTap = motion(TapToContinue);

export const CustomSplashScreenContainer: React.FC = React.memo(() => {
  // the ones below should not use `useSelector` because we don't want them to trigger re-render
  const token = UserSelectors.getToken(window.__store.getState());
  const hasSplashScreenEnabled = window.__store.getState().user.splashScreen?.enabled;

  // need to be logged
  if (!token) {
    return null;
  }

  // need to be have splashscreen enabled
  if (!hasSplashScreenEnabled) {
    return null;
  }

  // if offline need to have downloaded splashscreen
  if (!checkOnline()) {
    return null;
  }

  const lastShown = window.localStorage.getItem('lastCustomSplashDate');
  const showToday = !lastShown || !isSameDay(new Date(lastShown), new Date());
  if (!Platform.isCordova() && !showToday) {
    return null;
  }

  return <CustomSplashScreen />;
});
