import * as React from 'react';

import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import { useAppRouterProvider } from '@maggie/app/AppRouterContext';
import {
  routeViewTransitionDurationMs,
  routeViewTransitions
} from '@maggie/app/constants/transitions';
import type { RouteData, RouteName } from '@maggie/core/router/types';
import { AppLayoutSelectors } from '@maggie/layout/selectors';
import { getLocationPath } from '@maggie/store/navigation/utils';

import { ViewLayout } from './ViewLayout';

type Props = {
  /**
   * The name of the route - helps debugging!
   */
  routeName: RouteName;
  /**
   * Current route properties
   */
  route: RouteData;
};

export const RouteView: React.FC<Props> = React.memo(({ routeName, route }) => {
  const { diff } = useAppRouterProvider();

  const location = useLocation();
  const url = getLocationPath(location);
  const routeMatch = window.__router.findMatch(url);

  const shouldRenderRoute = useSelector(AppLayoutSelectors.shouldRenderRoute(routeName));

  if (!shouldRenderRoute || !routeMatch) {
    return null; // render placeholder screen?
  }

  // by default we should have
  const shouldUseTransitionEffect = route.transitionEffect ?? true;
  if (!shouldUseTransitionEffect) {
    return (
      <View id={`route-view-${routeName}`} stackIndex={route.stackIndex}>
        <ViewLayout routeName={routeName} route={route} routeParams={routeMatch.params} />
      </View>
    );
  }

  return (
    <MotionView
      id={`route-view-${routeName}`}
      custom={diff}
      variants={routeViewTransitions}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{
        stiffness: 1000,
        velocity: -100,
        duration: routeViewTransitionDurationMs / 1000
      }}
      stackIndex={route.stackIndex}
    >
      <ViewLayout routeName={routeName} route={route} routeParams={routeMatch.params} />
    </MotionView>
  );
});

RouteView.displayName = 'RouteView';

type StyledProps = {
  stackIndex: number;
};

const View = styled.div<StyledProps>`
  z-index: ${({ stackIndex }) => stackIndex};

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};

  display: flex;
  flex-direction: column;
`;

const MotionView = motion(View);
