import type { Store } from 'redux';

import { ErrorLogger } from '@edapp/monitoring';
import type { RequestTypes } from '@edapp/request';
import { HostedWebviewUtils } from '@maggie/core/hosted_webview_utils';
import { Urls } from '@maggie/store/constants';
import type { LxStoreState } from '@maggie/store/types';
import { UserActions } from '@maggie/store/user/actions';

/**
 * These are URIs that are allowed to return 401s.
 * They should not logout the user.
 *
 * All other URIs not listed here, if returned 401 they will logout the user.
 */
const WHITELISTED_URIS = [Urls.LINK_ACCOUNT, Urls.LOGIN, Urls.ME];

export const addLogoutListener = (store: Store<LxStoreState>) => {
  window.addEventListener('api-unauthorized', (evt: RequestTypes.UnauthorizedEventType) => {
    if (WHITELISTED_URIS.find(url => evt.detail.url.indexOf(url) >= 0)) {
      return; // do not logout user
    }

    ErrorLogger.captureEvent('Unauthorized', 'info', { evt });
    HostedWebviewUtils.triggerEvent('unauthorized');

    // Used for v2 of SC Training integration
    HostedWebviewUtils.triggerBootstrapError('unauthorized');

    store.dispatch(UserActions.userLogout(true, false));
  });
};
