import * as React from 'react';

import { ToolbarBehaviour } from '@maggie/core/router/types';

import { useSplitViewContext } from '../../split-view-context/useSplitViewContext';
import { MainToolbarContent } from './MainToolbarContent';

export const MainToolbar: React.FC = React.memo(() => {
  const context = useSplitViewContext();

  if (context.toolbarBehaviour === ToolbarBehaviour.none) {
    return <></>;
  }

  if (context.toolbarBehaviour === ToolbarBehaviour.fixed) {
    return (
      <MainToolbarContent
        title={context.title}
        textColor={context.toolbarTextColor}
        toolbarBehaviour={context.toolbarBehaviour}
      />
    );
  }

  throw Error(`unknown type of toolbar behaviour ${context.toolbarBehaviour}`);
});
