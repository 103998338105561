import * as React from 'react';
import { StarIcon } from '../icons';
import { Box } from '../box';
import styled from 'styled-components';
import type { ColorsKeys, ThemeSpace } from '@edapp/themes';
import type { IconSize } from '../icons';

type Props = {
  value?: number | null;
  disabled?: boolean;
  starColor?: ColorsKeys;
  secondaryStarColor?: ColorsKeys;
  secondaryStarOpacity?: number;
  iconSize?: IconSize;
  onClick?: (userRating: number) => void;
  padding?: ThemeSpace | null;
} & Omit<React.ComponentProps<typeof Box>, 'onClick'>;

const StarRating: React.FC<Props> = ({
  disabled = false,
  starColor = 'blue',
  secondaryStarColor = starColor,
  secondaryStarOpacity = 0.5,
  iconSize = 'lg',
  padding = 'xs',
  value,
  onClick,
  ...rest
}) => {
  const [hoverValue, setHover] = React.useState<number>(0);
  const totalStars = 5;
  const currentRating = value || 0;

  const onClickStar = (rating: number) => {
    if (disabled || !onClick) {
      return;
    }

    onClick(rating);
  };

  const handleHover = (rating: number) => {
    if (disabled || !onClick) {
      return;
    }
    setHover(rating);
  };

  const stars = new Array(totalStars).fill(null);

  const getStyle = (value: number) => {
    const isSelected = currentRating >= value;
    const hoverAbove = hoverValue >= value;
    const hoverBelow = hoverValue && hoverValue < value;
    return {
      opacity: isSelected && !hoverBelow ? 1 : secondaryStarOpacity,
      color: isSelected || hoverAbove ? starColor : secondaryStarColor
    };
  };

  return (
    <Box flex={true} flexDirection="row" justifyContent="center" {...rest}>
      {stars.map((_, i) => {
        const starValue = i + 1;
        const { opacity, color } = getStyle(starValue);

        return (
          <Box
            key={i}
            pr={padding === null ? undefined : padding}
            onClick={() => onClickStar(starValue)}
          >
            <StyledStar
              opacity={opacity}
              onMouseOver={() => handleHover(starValue)}
              onMouseOut={() => handleHover(0)}
              color={color}
              size={iconSize}
            />
          </Box>
        );
      })}
    </Box>
  );
};

const StyledStar = styled(StarIcon)<{ opacity: number }>`
  vertical-align: top;
  opacity: ${({ opacity }) => opacity};
  path {
    transition: fill 250ms ease-in-out;
  }
`;

export { StarRating };
