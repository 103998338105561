import * as React from 'react';

import { motion } from 'framer-motion';
import styled from 'styled-components';

import { Box, useEventListener } from '@edapp/ed-components';
import { Link } from '@maggie/components/link/Link';
import { routePaths } from '@maggie/core/router/routes';
import { useMenuItems } from '@maggie/layout/hooks/useMenuItems';

export const BottomNavigation = () => {
  const [displayNavigation, setDisplayNavigation] = React.useState<boolean>(true);
  /**
   * NOTE: tactical solution
   * determine the active navigation on the bottom nav since we don't have a way to determine if a route
   * is a subroute to another route, which potentially can be solved with split-view routes
   *
   * example:
   * Profile icon should still be active when, click on profile from the navbar and navigate to leaderboards from profile view
   */
  const [activeNavigationItem, setActiveNavigationItem] = React.useState<string | null>(null);
  const menu = useMenuItems();

  useEventListener('keyboardWillShow', () => setDisplayNavigation(false));
  useEventListener('keyboardWillHide', () => setDisplayNavigation(true));

  React.useEffect(() => {
    if (displayNavigation) {
      const activeItem = menu.find(item => item.active)?.path ?? routePaths.home.create({});
      setActiveNavigationItem(activeItem);
    }
  }, [menu, displayNavigation]);

  if (!displayNavigation) {
    return null;
  }

  return (
    <motion.nav
      data-testid="app-bottom-nav"
      initial={{ y: '100%', opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: '100%', opacity: 0 }}
      transition={{ ease: 'anticipate', clamp: true }}
    >
      <Nav flex={true} justifyContent="space-around" alignItems="center">
        {menu.map(({ path, icon: IconComponent }) => (
          <Link key={path} to={path}>
            {IconComponent && (
              <IconComponent
                size="md"
                color={path === activeNavigationItem ? 'blue' : 'textMuted'}
                onClick={() => setActiveNavigationItem(path)}
              />
            )}
          </Link>
        ))}
      </Nav>
    </motion.nav>
  );
};

const Nav = styled(Box)`
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  height: 55px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
`;
