import * as React from 'react';
import PolyfillResizeObserver from 'resize-observer-polyfill';

type Bounds =
  | {
      height: number;
      width: number;
      outerHeight: number;
      outerWidth: number;
    }
  | undefined;

const useIsomorphicEffect = typeof window === 'undefined' ? React.useEffect : React.useLayoutEffect;

export function useResizeObserver<Element extends HTMLElement>(
  dependencies: Array<unknown> = []
): {
  bounds: Bounds;
  ref: React.RefObject<Element>;
} {
  const ref = React.useRef<Element>(null);
  const [bounds, setBounds] = React.useState<Bounds>(undefined);

  useIsomorphicEffect(() => {
    const ResizeObserver = window.ResizeObserver || PolyfillResizeObserver;

    const observer = new ResizeObserver(([entry]: ResizeObserverEntry[]) => {
      // wrap into a requestAnimationFrame to avoid
      // 'ResizeObserver loop limit exceeded'
      window.requestAnimationFrame(() => {
        if (!entry) {
          return;
        }

        setBounds({
          height: entry.contentRect.height,
          width: entry.contentRect.width,
          outerHeight: entry.borderBoxSize?.[0].blockSize || 0, // height including padding and borders
          outerWidth: entry.borderBoxSize?.[0].inlineSize || 0 // width including padding and borders
        });
      });
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, ...dependencies]);

  return {
    bounds,
    ref
  };
}
