import * as React from 'react';
import styled, { css } from 'styled-components';
import type { ColorsKeys } from '@edapp/themes';

export type BadgeAnchorType = {
  horizontal: 'left' | 'right';
  vertical: 'top' | 'bottom' | 'center';
};

type Props = {
  /**
   * Default: 0
   *
   * @type {number}
   */
  value?: number;

  /**
   * Default: 99
   *
   * @type {number}
   */
  max?: number;

  /**
   * Default: standard
   *
   * @type {('standard' | 'dot')}
   */
  variant?: 'standard' | 'dot';

  /**
   * Default: red
   *
   * @type {ColorsKeys}
   */
  color?: ColorsKeys;

  /**
   * Default: { vertical: 'top', horizontal: 'right' }
   *
   * @type {BadgeAnchorType}
   */
  anchor?: BadgeAnchorType;

  className?: string;

  showOnEmpty?: boolean;

  testId?: string;
};

export const Badge: React.FC<React.PropsWithChildren<Props>> = ({
  variant = 'standard',
  max = 99,
  color = 'red',
  value = 0,
  anchor = { vertical: 'top', horizontal: 'right' },
  className,
  children,
  showOnEmpty = true,
  testId = 'Badge'
}) => {
  const getBadgeValue = () => {
    if (variant === 'dot') {
      return null;
    }

    if (typeof max === 'number' && value > max) {
      return max + '+';
    }

    return value;
  };

  return (
    <BadgeRoot className={className}>
      {children}

      <BadgeWrapper
        value={value}
        showOnEmpty={showOnEmpty}
        variant={variant}
        color={color}
        anchor={anchor}
        testId={testId}
      >
        <BadgeValue testId={testId}>{getBadgeValue()}</BadgeValue>
      </BadgeWrapper>
    </BadgeRoot>
  );
};

const BadgeRoot = styled.div`
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  vertical-align: middle;
`;

const anchorStyles = {
  topLeft: {
    top: 0,
    left: 0,
    transform: 'scale(1) translate(-50%, -50%)',
    transformOrigin: '0% 0%'
  },
  topRight: {
    top: 0,
    right: 0,
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%'
  },
  centerLeft: {
    top: '50%',
    left: 0,
    transform: 'scale(1) translate(-50%, -50%)',
    transformOrigin: '0% 0%'
  },
  centerRight: {
    top: '50%',
    right: 0,
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%'
  },
  botLeft: {
    bottom: 0,
    left: 0,
    transform: 'scale(1) translate(-50%, 50%)',
    transformOrigin: '0% 100%'
  },
  botRight: {
    bottom: 0,
    right: 0,
    transform: 'scale(1) translate(50%, 50%)',
    transformOrigin: '100% 100%'
  }
};

const getAnchorValues = (anchor: BadgeAnchorType) => {
  switch (anchor.vertical) {
    case 'top':
      if (anchor.horizontal === 'left') {
        return anchorStyles.topLeft;
      }

      if (anchor.horizontal === 'right') {
        return anchorStyles.topRight;
      }

    case 'center':
      if (anchor.horizontal === 'left') {
        return anchorStyles.centerLeft;
      }

      if (anchor.horizontal === 'right') {
        return anchorStyles.centerRight;
      }

    case 'bottom':
      if (anchor.horizontal === 'left') {
        return anchorStyles.botLeft;
      }

      if (anchor.horizontal === 'right') {
        return anchorStyles.botRight;
      }

    default:
      return anchorStyles.topRight;
  }
};

type BadgeWrapperProps = {
  variant: Props['variant'];
  color: ColorsKeys;
  anchor: BadgeAnchorType;
  showOnEmpty: boolean;
  value: number;
  testId: string;
};

const BadgeWrapper = styled.div.attrs<BadgeWrapperProps>(({ testId }) => ({
  'data-testid': `${testId}-Wrapper`
}))<BadgeWrapperProps>`
  display: ${({ showOnEmpty, value }) => (!showOnEmpty && value === 0 ? 'none' : 'flex')};
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: absolute;
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadows.middle};

  background-color: ${({ theme, color }) => theme.colors[color]};
  padding-top: 1px;
  ${({ variant, theme }) => {
    switch (variant) {
      case 'dot': {
        return css`
          border-radius: 50%;
          height: 8px;
          width: 8px;
        `;
      }
      case 'standard':
      default: {
        return css`
          border-radius: 10px;
          height: 17px;
          min-width: 17px;
          padding-left: ${theme.space(0.5)}px;
          padding-right: ${theme.space(0.5)}px;
        `;
      }
    }
  }}

  ${({ anchor }) => getAnchorValues(anchor)}
`;

const BadgeValue = styled.span.attrs<{ testId: string }>(({ testId }) => ({
  'data-testid': `${testId}-Value`
}))<{ testId: string }>`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 12px;
  letter-spacing: 0.25px;
  line-height: ${({ theme }) => theme.lineHeights[1]}px;
  text-align: center;
`;
