import * as React from 'react';

/**
 * Hook to create a ref of the previous value can be used to compare props
 * and memoise
 * @param value
 */
function usePrevious<T = any>(value: T) {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export { usePrevious };
