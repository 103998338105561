/// <reference types="@types/zepto" />

let keyboardTimeout: number | null = null;
// const viewsWithPadding: Element[] = [];

// Temporarily insert a spacer node which should force a browser repaint
// and the correct calculation of scrolling viewports
// const onTransitionEnd = (event: TransitionEvent) => {
//   const el = event.target;
//   if (!el) {
//     return;
//   }

//   const t = document.createElement('span');
//   t.innerHTML = `<span>&nbsp;</span>`;

//   let element = document.querySelector('.view.active .touch-scroll-cta');

//   if (!element) {
//     element = document.querySelector('.view.active .touch-scroll');
//   }

//   if (!element) {
//     return;
//   }

//   element.appendChild(t).getBoundingClientRect();
//   if (t.parentNode) {
//     t.parentNode.removeChild(t);
//   }
// };

const initialize = () => {
  if (typeof Keyboard !== 'undefined' && Keyboard !== null) {
    if (typeof Keyboard.hideFormAccessoryBar === 'function') {
      Keyboard.hideFormAccessoryBar(false);
    }

    window.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.keyCode === 13 && e.target && (e.target as any).nodeName !== 'TEXTAREA') {
        close();
      }
    });

    // !!Commenting this out!!!
    // This code is legacy and I believe there is no containers that will fit the selector below:
    //
    // '.view.active .view-body.content'
    //
    // Also note. If you coming from the future, the code below uses $.os.ios
    // That is coming from zepto.detect - which has been removed from the app.
    //
    // This event is coming from `cordova-plugin-keyboard`
    // window.addEventListener('keyboardDidShow', (e: CordovaKeyboardEvent) => {
    //   document.body.classList.add('keyboard-visible');
    //   cancel();

    //   if ($.os.ios && e.keyboardHeight) {
    //     // Auto-scroll to focused element for iOS
    //     document.activeElement?.scrollIntoView({ behavior: 'smooth' });
    //     const activeView = document.querySelector('.view.active .view-body.content');
    //     if (!activeView) {
    //       return;
    //     }

    //     // We don't need that workaround anymore... we are using the cordova plugin
    //     // Check `config.xml`
    //     // <preference name="KeyboardResize" value="true" />
    //     // <preference name="KeyboardResizeMode" value="native" />
    //     // These two props do the magic
    //     // (activeView as HTMLElement).style.borderBottomWidth = `${e.keyboardHeight}px`;
    //     activeView.addEventListener('transitionend', onTransitionEnd);

    //     viewsWithPadding.push(activeView);
    //   }
    // });

    // This event is coming from `cordova-plugin-keyboard`
    // window.addEventListener('keyboardWillHide', () => {
    //   document.body.classList.remove('keyboard-visible');

    //   if ($.os.ios) {
    //     return viewsWithPadding.forEach(view => {
    //       (view as HTMLElement).style.borderBottomWidth = '0px';
    //       const transition = (view as HTMLElement).style.transition;
    //       (view as HTMLElement).style.transition = 'border 1ms';

    //       view.addEventListener('transitionend', (ev: TransitionEvent) => {
    //         onTransitionEnd(ev);
    //         (view as HTMLElement).style.transition = transition;
    //       });
    //     });
    //   }
    // });
  }
};

const close = () => {
  if ((typeof Keyboard !== 'undefined' && Keyboard !== null ? Keyboard.hide : undefined) != null) {
    keyboardTimeout = window.setTimeout(() => Keyboard.hide(), 300);
  }
};

const cancel = () => {
  if (keyboardTimeout !== null) {
    window.clearTimeout(keyboardTimeout);
  }
};

const show = () => {
  if (typeof Keyboard !== 'undefined' && Keyboard !== null) {
    return Keyboard.show();
  }
};

const toggleAccessoryBar = (shouldShow: boolean) => {
  if (shouldShow == null) {
    shouldShow = true;
  }

  if (
    (typeof Keyboard !== 'undefined' && Keyboard !== null
      ? Keyboard.hideFormAccessoryBar
      : undefined) != null
  ) {
    return Keyboard.hideFormAccessoryBar(!shouldShow);
  }
};

export const AppKeyboard = {
  initialize,
  toggleAccessoryBar,
  show,
  cancel,
  close
};
