import { useSelector } from 'react-redux';

import { useScPlatformContext } from '@edapp/sc-web-ui';
import { ConfigSelectors } from '@maggie/store/config/selectors';

export const useHideNavigation = () => {
  const { isMfe } = useScPlatformContext();
  const restrictToCourseScreen = useSelector(ConfigSelectors.getRestrictToCourseScreen);
  const restrictToLessonScreen = useSelector(ConfigSelectors.getRestrictToLessonScreen);
  return !!restrictToCourseScreen || !!restrictToLessonScreen || isMfe;
};
