import { ENV } from '@maggie/config/env';

export const CHINA_PRIVACY_POLICY_STORAGE_KEY = 'ed:china_agreement';

export const setPrivacyPolicyLocalStorageValue = (agreed: boolean) => {
  window.localStorage.setItem(CHINA_PRIVACY_POLICY_STORAGE_KEY, agreed + '');
};

export const hasUserConsent = () => {
  const agreed = window.localStorage.getItem(CHINA_PRIVACY_POLICY_STORAGE_KEY);
  if (ENV.FOR_CHINA === 'true') {
    return agreed === 'true';
  }
  // outside china, user will automatically consented
  return true;
};
