import * as React from 'react';

import { Box, Dropdown } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';

import { NotificationsBell } from '../bell';
import { NotificationsFeed } from '../feed';
import { StyledContainer } from './styled';

export const NotificationsDropdown: React.FC = () => (
  <Dropdown closeOnScroll={false} trigger={<NotificationsBell />}>
    <StyledContainer>
      <Box p={3}>
        <Text variant="titleLarge" component="h2">
          Notifications
        </Text>
      </Box>

      <NotificationsFeed />
    </StyledContainer>
  </Dropdown>
);
