type BaseAchievement = {
  colorScheme: AchievementColorScheme;
  badgeMediaUrl: string;
  badgeBorderColor: string;
  isPublished: boolean;
  achievers: Achiever[];
  recipes: (Recipe | Partial<Recipe>)[];
  title: string;
  completion: string;
  description: string;
};

// Body to the BE
export type AchievementRequest = BaseAchievement & {
  id?: string;
  localeCode: string | null;
  translations: AchievementTranslationRequest[] | null;
  recipes: Recipe[];
};

// Response from the BE
export type Achievement = BaseAchievement & {
  id?: string;
  defaultLocale: Language | null;
  translations: AchievementTranslationResponse[] | null;
  status: AchievementStatus;
  isInvalid: boolean;
  isLocked: boolean;
};

export type ComponentsResponse = {
  info: ComponentInfo;
  components: (ComponentList | ComponentNumber | ComponentSelector)[];
  filter: {
    feature: true;
  };
  lookup: {
    [name: string]: string[];
  };
  ignoreInTextOutput: string[];
};

export type ComponentInfo = {
  [name: string]: {
    title: string;
    text: string;
    next?: ComponentInfoNext;
  };
};
export type ComponentInfoNext = {
  [name: string]: {
    suffix?: string;
    prefix?: string;
    text?: string;
    max?: number;
    min?: number;
    value?: number;
  };
};

export type ComponentList = {
  type: 'list';
  key: string;
  value: string[];
};

export type ComponentNumber = {
  type: 'number';
  key: string;
  min: number;
  max: number;
  value: number;
};

export type ComponentSelector = {
  type: 'selector';
  key: string;
  value?: string | null;
};

export type KeyUnion = ListKeyUnion | NumberKeyUnion | SelectKeyUnion;

export enum ListKeyUnion {
  ACTION = 'action',
  MEASURE = 'measure',
  FEATURE = 'feature',
  REPEAT = 'repeat'
}

export enum NumberKeyUnion {
  COUNTER = 'counter',
  PERIOD = 'period',
  VALUE = 'value'
}

export enum SelectKeyUnion {
  FEATURE_ID = 'featureId'
}

export type ComponentRecipe = {
  [x in ListKeyUnion]?: ComponentListDefinition;
} &
  {
    [y in NumberKeyUnion]?: ComponentNumberDefinition;
  } &
  {
    [z in SelectKeyUnion]?: ComponentSelectorDefinition;
  };

export type ComponentRecipeUnion =
  | ComponentListDefinition
  | ComponentNumberDefinition
  | ComponentSelectorDefinition;

type BaseDefinition<T = ComponentDefinitionType> = {
  type: T;
  text?: string;
  prefix?: string;
  suffix?: string;
};

export enum ComponentDefinitionType {
  LIST = 'list',
  NUMBER = 'number',
  SELECTOR = 'selector'
}

export type ComponentListDefinition = BaseDefinition<ComponentDefinitionType.LIST> & {
  list: string[];
  value: string;
};

export type ComponentNumberDefinition = BaseDefinition<ComponentDefinitionType.NUMBER> & {
  value: number;
  min: number;
  max: number;
};

export type ComponentSelectorDefinition = BaseDefinition<ComponentDefinitionType.SELECTOR> & {
  value: string | null;
};

export type AchievementListItem = {
  id: string;
  title: string;
  colorScheme: AchievementColorScheme;
  completion: string;
  description: string;
  badgeMediaUrl: string;
  badgeBorderColor: string;
  status: AchievementStatus;
  isInvalid: boolean;
  isLocked: boolean;
  features: string[];
  usersEarned: number;
};

export enum AchievementColorScheme {
  LIGHT = 'light',
  DARK = 'dark'
}

export enum AchievementStatus {
  DRAFT = 'drafted',
  PUBLISHED = 'published',
  ARCHIVED = 'archived'
}

export type AchievementTranslationRequest = {
  localeCode: string;
  values: { title: string; description: string; completion: string };
};

export type AchievementTranslationResponse = {
  locale: {
    code: string;
    name: string;
  };
  values: {
    completion: string;
    description: string;
    title: string;
  };
};

export type Language = {
  name: string;
  code: string;
};

export type Recipe = {
  action: string;
  value: number;
  measure: string;
  counter: number;
  feature: string;
  featureId: string | null;
  featureIdTitle?: string;
  period: number;
  repeat: string;
  isInvalid?: boolean;
};

export type Achiever = {
  name: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  userGroups: string[];
  earnedDate: string; // Datetime and Timezone
};
