import { useSelector } from 'react-redux';

import { StatusBarManager } from '@maggie/cordova/status-bar';
import type { RouteName } from '@maggie/core/router/types';
import { AppLayoutSelectors } from '@maggie/layout/selectors';
import { UNAUTHENTICATED_ROUTES } from '@maggie/store/navigation/handlers/access-sagas';

type HookOptionsType = {
  routeName?: RouteName;
};

export const useSetStatusBarStyle = ({ routeName }: HookOptionsType) => {
  const isLocked = useSelector(AppLayoutSelectors.getIsLocked(routeName));
  if (!routeName) {
    return;
  }

  /**
   * display light text, if route is non unauthenticated route
   */
  const isLightContent = !UNAUTHENTICATED_ROUTES.includes(routeName) && isLocked;

  if (isLightContent) {
    StatusBarManager.setLightContent();
  } else {
    StatusBarManager.setDefault();
  }
};
