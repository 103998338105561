import { useMemo, useContext } from 'react';
import { AssetContext } from '../AssetContext';
import { transformSrc } from './utils';
import type { SourceSetWithTransformation, MediaQueryWithTransformation } from './types';

export function useTransformSrc(src?: string, transformation?: string): string | undefined {
  const { baseUrl, transformedHostnames } = useContext(AssetContext);

  return useMemo(() => {
    if (!src) {
      return undefined;
    }

    return transformSrc(src, {
      baseUrl,
      transformedHostnames,
      transformation
    });
  }, [src, transformation, baseUrl, transformedHostnames]);
}

export function useTransformSrcSet(
  src: string,
  srcSet?: SourceSetWithTransformation[],
  transformation?: string
): string | undefined {
  const { baseUrl, transformedHostnames } = useContext(AssetContext);

  return useMemo(() => {
    if (!srcSet) {
      return undefined;
    }

    return srcSet
      .map(item => {
        const url = transformSrc(item.src ?? src, {
          baseUrl,
          transformedHostnames,
          transformation: item.transformation ?? transformation
        });

        return `${url} ${item.size}`;
      })
      .join(', ');
  }, [baseUrl, src, srcSet, transformation]);
}

export function useTransformMediaQueries(
  src: string,
  mediaQueries?: MediaQueryWithTransformation[],
  transformation?: string
): string | undefined {
  const { baseUrl, transformedHostnames } = useContext(AssetContext);

  return useMemo(() => {
    if (!mediaQueries) {
      return undefined;
    }

    return mediaQueries
      .map(item => {
        const url = transformSrc(item.src ?? src, {
          baseUrl,
          transformedHostnames,
          transformation: item.transformation ?? transformation
        });

        return `
          @media ${item.query} {
            background-image: url(${url});
          }
        `;
      })
      .join('\n');
  }, [baseUrl, src, mediaQueries, transformation]);
}
