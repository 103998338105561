import * as React from 'react';

import { useSelector } from 'react-redux';

import { itly } from '@edapp/analytics-tracking';
import { UpdateAppDialog } from '@maggie/components/dialogs/update-app-dialog/UpdateAppDialog';
import { useCheckUpdateApp } from '@maggie/components/dialogs/update-app-dialog/useCheckUpdateApp';
import { appVersion } from '@maggie/config/appVersion';
import { ENV } from '@maggie/config/env';
import { openExternalUrl } from '@maggie/cordova/link';
import { Platform } from '@maggie/cordova/platform';
import { UserSelectors } from '@maggie/store/user/selectors';

export const UpdateAppDialogContainer: React.FC = () => {
  const isChina = ENV.FOR_CHINA === 'true';
  const updateAppEnabled = useSelector(UserSelectors.hasAppPromptUpdateEnabled);
  const recommendedAppVersion = useSelector(UserSelectors.getRecommendedAppVersion);

  const { shouldPromptUpdate, acknowledgeUpdate } = useCheckUpdateApp(
    appVersion,
    recommendedAppVersion
  );

  const onRedirectToAppStore = () => {
    itly.appUpdateClicked();
    switch (Platform.get()) {
      case 'iOS':
        // we moved the app from CLD -> EDAPP -> Safety Culture
        // the id has never changed. we believe it's fine to keep it in the code
        const appId = '991242619';
        openExternalUrl(`itms-apps://itunes.apple.com/app/${appId}`);
        acknowledgeUpdate();
        break;

      case 'Android':
        const appPackageId = 'com.cld.ed';
        openExternalUrl(`market://details?id=${appPackageId}`);
        acknowledgeUpdate();
        break;

      default:
        break; // do nothing
    }
  };

  const onCancel = () => {
    itly.appUpdateCancelled();
    acknowledgeUpdate();
  };

  return (
    <UpdateAppDialog
      isOpen={!isChina && updateAppEnabled && shouldPromptUpdate}
      version={recommendedAppVersion}
      onUpdate={onRedirectToAppStore}
      onCancel={onCancel}
    />
  );
};
