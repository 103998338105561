import * as React from 'react';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

/**
 * Intended to be used for locking document scrolling, for ex. when a dialog has been opened.
 * @param preserveScrollRef HTMLElement on which scrolling should be preserved
 */
export function useBodyScrollLock<T extends HTMLElement>(preserveScrollRef?: React.RefObject<T>) {
  // preserveScrollRef is lost after the dialog is closed
  const previousPreservedScrollRef = React.useRef(preserveScrollRef?.current);
  React.useEffect(() => {
    if (preserveScrollRef?.current) {
      previousPreservedScrollRef.current = preserveScrollRef.current;
    }
  }, [preserveScrollRef?.current]);

  const handleTouchMove = React.useCallback(
    (e: TouchEvent) => {
      if (e.target instanceof Node && !preserveScrollRef?.current?.contains(e.target)) {
        e.preventDefault();
      }
      e.stopPropagation();
    },
    [preserveScrollRef]
  );

  const lockScrolling = React.useCallback(() => {
    /**
     * Locking/ unlocking scroll for iOS devices are done differently because body-scroll-lock enables/disables scrolling on the parent dialog component only,
     * but this won't work for designs that require scrolling on some parts of the content.
     */
    if (isIOS()) {
      document.documentElement.style.overflow = 'hidden'; // this was from the previous commit, need to check if this is necessary (?)
      document.ontouchmove = (e: TouchEvent) => {
        handleTouchMove(e);
      };
      window.addEventListener('touchmove', handleTouchMove, { passive: false });
    } else {
      if (preserveScrollRef?.current) {
        disableBodyScroll(preserveScrollRef.current);
      }
    }
  }, [preserveScrollRef, handleTouchMove]);

  const unlockScrolling = React.useCallback(() => {
    if (isIOS()) {
      document.documentElement.style.overflow = '';
      document.ontouchmove = () => true;
      window.removeEventListener('touchmove', handleTouchMove);
    } else {
      if (previousPreservedScrollRef?.current) {
        enableBodyScroll(previousPreservedScrollRef.current);
      }
    }
  }, [preserveScrollRef, handleTouchMove]);

  return React.useMemo(() => [lockScrolling, unlockScrolling], [lockScrolling, unlockScrolling]);
}

export function isIOS() {
  /**
   * window.navigator.platform is returning the broswer for ipad devices with ios > 13
   * second condition is required to ensure iosDevices are accurately detected
   */

  return !!(
    typeof window !== 'undefined' &&
    window.navigator &&
    window.navigator.platform &&
    (/iP(ad|hone|od)/.test(window.navigator.platform) ||
      (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1))
  );
}
