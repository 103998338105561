import * as React from 'react';

import { useLocation } from 'react-router-dom';

import { useWindowResize } from '@edapp/ed-components';
import { Platform } from '@maggie/cordova/platform';
import { ScreenLock } from '@maggie/cordova/screen-lock';
import { AppLayoutSelectors } from '@maggie/layout/selectors';

import { AppMobileContext } from './AppMobileContext';

const DESKTOP_WIDTH = 744;

export const AppMobileContextProvider = React.memo<React.PropsWithChildren<{}>>(({ children }) => {
  const appContainer =
    document.getElementById('react-app') || document.getElementById('training-root') || undefined; //targeting standalone or sc training

  const { width, height } = useWindowResize(appContainer);

  const location = useLocation();

  const route = window.__router['hashManager'].findMatch(location.pathname);
  const routeName = route?.routeName;

  const isMobile = React.useCallback(
    (w = width) => {
      return AppLayoutSelectors.getIsMobile(w, routeName);
    },
    [width, routeName]
  );

  React.useEffect(() => {
    // - portrait-mode: width is less then the constant in isMobile
    // - landscape-mode: height is less then the constant in isMobile
    window.__isMobile = Math.min(width ?? 0, height ?? 0) < DESKTOP_WIDTH;
  }, [width, height]);

  React.useEffect(() => {
    /**
     * Lock platform if:
     * - it is a device
     * - and platform isMobile in:
     *   - portrait-mode: width is less then the constant in isMobile
     *   - landscape-mode: height is less then the constant in isMobile
     */
    if (Platform.isCordova() && appContainer && window.__isMobile) {
      ScreenLock.lockToPortrait();
    }
  }, [appContainer]);

  const value = React.useMemo(() => ({ isMobile: isMobile() }), [isMobile]);

  return <AppMobileContext.Provider value={value}>{children}</AppMobileContext.Provider>;
});

AppMobileContextProvider.displayName = 'AppMobileContextProvider';
