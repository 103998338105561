import * as React from 'react';

import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import type { RouteName, RouteParams } from '@maggie/core/router/types';
import { AppLayoutSelectors } from '@maggie/layout/selectors';

import { SplitViewContext } from './SplitViewContext';

/**
 * It encapsulates the logic around exposing the correct state values of layout properties
 */
type Props = {
  mainRoute: RouteName;
  mainParams: RouteParams<RouteName>;
};

export const SplitViewContextProvider: React.FC<React.PropsWithChildren<Props>> = React.memo(
  ({ children, mainRoute, mainParams }) => {
    const theme = useTheme();

    const [title, setTitle] = React.useState('');
    const routeTitle = useSelector(AppLayoutSelectors.getSplitViewTitle(mainRoute));
    // when the route changes, reset the value to initial value from configuration
    React.useEffect(() => {
      setTitle(routeTitle);
    }, [routeTitle]);

    const toolbarBehaviour = useSelector(
      AppLayoutSelectors.getSplitViewToolbarBehaviour(mainRoute)
    );
    const toolbarTextColor = useSelector(
      AppLayoutSelectors.getSplitViewToolbarTextColor(theme, mainRoute)
    );
    const toolbarBackground = useSelector(
      AppLayoutSelectors.getSplitViewToolbarBackground(mainRoute)
    );

    return (
      <SplitViewContext.Provider
        value={{
          mainRoute,
          mainParams,
          title,
          setTitle,
          toolbarTextColor,
          toolbarBackground,
          toolbarBehaviour
        }}
      >
        {children}
      </SplitViewContext.Provider>
    );
  }
);
