import styled from 'styled-components';
import { motion } from 'framer-motion';

export const SwipeableViewSlides = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const SwipeableViewSlide = styled(motion.div)`
  display: inline-flex;
  flex-grow: 1;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  white-space: normal;
  opacity: 1;
  transition: inherit;
`;
