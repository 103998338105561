import * as React from 'react';

import { AnimatePresence } from 'framer-motion';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';

import { BaseDialog } from './BaseDialog';
import { sizing } from './styled';
import type { Props } from './types';
import { getPortalElement } from './utils';

const DialogComponent = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { usePortal = true, useBackdrop = true, ...rest } = props;

  const [portalElement, setPortalElement] = React.useState<HTMLElement | null>(
    getPortalElement(usePortal)
  );

  React.useEffect(() => {
    setPortalElement(getPortalElement(usePortal));
  }, [usePortal]);

  const createDialog = ({ withAnimatePresence = true, ...dialogProps }: Props) => {
    const renderedDialog = <BaseDialog ref={ref} {...dialogProps} />;

    if (withAnimatePresence) {
      return <AnimatePresence>{props.isOpen && renderedDialog}</AnimatePresence>;
    }

    return props.isOpen ? renderedDialog : null;
  };

  const dialog = createDialog({ usePortal, useBackdrop, ...rest });

  return usePortal && portalElement ? ReactDOM.createPortal(dialog, portalElement) : dialog;
});

export const Dialog = styled(DialogComponent)<Props>`
  ${sizing}
`;
