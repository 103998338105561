import * as React from 'react';

import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { IconTransitionStates, IconTransitions } from './transitions';

type Props = {
  shouldAnimate: boolean;
};

export const HamburgerIcon: React.FC<Props> = ({ shouldAnimate }) => {
  return (
    <Container
      animate={shouldAnimate ? IconTransitionStates.BACK_BUTTON : IconTransitionStates.MENU}
      initial={false}
    >
      <Bar
        data-testid="icon-top-bar"
        variants={IconTransitions.topBar}
        transition={{ duration: 0.1 }}
      />
      <Bar
        data-testid="icon-middle-bar"
        variants={IconTransitions.middleBar}
        transition={{ duration: 0.1 }}
      />
      <Bar
        data-testid="icon-bottom-bar"
        variants={IconTransitions.bottomBar}
        transition={{ duration: 0.1 }}
      />
    </Container>
  );
};

const BarStyles = css`
  width: 18px;
  z-index: 3;
  height: 2px;
  border-radius: 100px;
  pointer-events: none;
  background: ${({ theme }) => theme._ui.colors.accent.text.default};
`;

const Container = styled(motion.div)`
  > :nth-child(2) {
    margin: ${({ theme }) => theme.space(0.5)}px 0;
  }
`;

const Bar = styled(motion.div)`
  ${BarStyles}
`;
