export type SwipeableCarouselAnimationType = {
  [key in SwipeableCarouselAnimations]: SwipeableCarouselAnimationValues;
};

export type SwipeableCarouselAnimationValues = {
  activeValue?: number;
  inactiveValue?: number;
};

export enum SwipeableCarouselAnimations {
  OPACITY = 'opacity',
  SCALE = 'scale'
}
