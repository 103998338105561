/*
 * Component that takes a media query string and returns
 * a render prop boolean for child components.
 * It renders nothing until the media query is checked.
 *
 * Example usage:
 *
 * <MatchMedia query={'only screen and (max-width: 200px)'}>
 *   {isSmallerThan200Px => (
 *     <Children />
 *   )}
 * </MatchMedia>
 */

import * as React from 'react';

type Props = {
  /**
   * Media query string
   */
  query: string;
  /**
   * Render prop function with access to result of media query test boolean
   */
  children?: (isMatch: boolean) => React.ReactNode;
};

export const MatchMedia: React.FC<Props> = ({ query, children }) => {
  const matchListRef = React.useRef<MediaQueryList | null>(null);
  const [isMatch, setIsMatch] = React.useState<boolean | undefined>(undefined);

  const handleMediaQueryListEvent = React.useCallback((e: MediaQueryListEvent) => {
    setIsMatch(e.matches);
  }, []);

  React.useEffect(() => {
    matchListRef.current = window.matchMedia(query);
    matchListRef.current?.addListener(handleMediaQueryListEvent);
    setIsMatch(matchListRef.current?.matches);

    return () => {
      if (matchListRef.current) {
        matchListRef.current.removeListener(handleMediaQueryListEvent);
      }
    };
  }, [query]);

  if (isMatch === undefined || children == null) {
    return null;
  }

  return <>{children(isMatch)}</>;
};
