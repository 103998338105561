import * as React from 'react';
import styled, { css } from 'styled-components';
import { mergeRefs } from '../../../utils';
import { SearchIcon, CircleCrossIcon } from '../../icons';
import { Input } from './Input';
import type { SearchInputProps, SearchInputState, SearchInputAction } from './types';

export const SEARCH_INPUT_TEST_ID = 'search-input';
export const SEARCH_INPUT_INPUT_TEST_ID = `${SEARCH_INPUT_TEST_ID}-input`;
export const SEARCH_INPUT_CLEAR_TEST_ID = `${SEARCH_INPUT_TEST_ID}-clear`;

const searchInputReducer = (
  state: SearchInputState,
  action: SearchInputAction
): SearchInputState => {
  switch (action.type) {
    case 'FOCUS':
      return {
        ...state,
        isFocused: true
      };
    case 'BLUR':
      return {
        ...state,
        isFocused: false
      };
    case 'SET_INPUT_VALUE':
      return {
        ...state,
        inputValue: action.payload
      };
    default:
      return state;
  }
};

type Props = SearchInputProps & {
  /**
   * It triggers onClick of Input when clicking to clear search
   * @default false
   */
  triggerClickOnClearSearch?: boolean;
};

export const SearchInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      testId = SEARCH_INPUT_TEST_ID,
      inputTestId = SEARCH_INPUT_INPUT_TEST_ID,
      clearTestId = SEARCH_INPUT_CLEAR_TEST_ID,
      className,
      onChange,
      onClear,
      disabled,
      isDisabled,
      isFullWidth,
      onFocus,
      onBlur,
      value = '',
      triggerClickOnClearSearch = false,
      ...rest
    },
    ref
  ) => {
    const [state, dispatch] = React.useReducer(searchInputReducer, {
      inputValue: value || '',
      isFocused: false
    });

    const realInputRef = React.useRef<HTMLInputElement | null>(null);

    React.useEffect(() => dispatch({ type: 'SET_INPUT_VALUE', payload: value || '' }), [value]);

    const internalDisabled = disabled || isDisabled;

    const handleWrapperClick = () => realInputRef.current?.focus();

    const handleFocus = (focusEvent: React.FocusEvent<HTMLInputElement>) => {
      dispatch({ type: 'FOCUS' });
      onFocus?.(focusEvent);
    };

    const handleBlur = (blurEvent: React.FocusEvent<HTMLInputElement>) => {
      dispatch({ type: 'BLUR' });
      onBlur?.(blurEvent);
    };

    const handleChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(changeEvent);

      if (!value) {
        dispatch({ type: 'SET_INPUT_VALUE', payload: changeEvent.currentTarget.value });
      }
    };

    const handleClearSearch = (e: React.MouseEvent) => {
      if (internalDisabled) {
        return;
      }

      realInputRef.current?.focus();
      onClear?.();

      if (triggerClickOnClearSearch) {
        rest.onClick?.(e);
      }

      if (!value) {
        dispatch({ type: 'SET_INPUT_VALUE', payload: '' });
      }
    };

    return (
      <InputWrapper
        as="div"
        className={className}
        isFocused={state.isFocused}
        isFullWidth={isFullWidth}
        disabled={internalDisabled}
        onClick={handleWrapperClick}
        testId={testId}
        hasMargin={false}
      >
        <SearchInputIcon color="text" />
        <RealInput
          ref={mergeRefs([ref, realInputRef])}
          value={value || state.inputValue}
          disabled={internalDisabled}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          testId={inputTestId}
          {...rest}
        />
        <ClearIcon
          color={internalDisabled ? 'textMuted' : 'text'}
          onClick={handleClearSearch}
          isFocused={state.isFocused}
          disabled={!!internalDisabled}
          testId={clearTestId}
          showIcon={state.inputValue.length > 0 && !disabled}
        />
      </InputWrapper>
    );
  }
);

type InputWrapperProps = Pick<SearchInputProps, 'isFullWidth'> & {
  isFocused: boolean;
};

const InputWrapper = styled(Input)<InputWrapperProps>(
  ({ theme, isFullWidth, isFocused, disabled }) => css`
    padding: 0 0 0 ${theme.space(2)}px;
    width: ${isFullWidth ? '100%' : 'auto'};
    cursor: ${disabled ? 'auto' : 'text'};
    box-sizing: border-box;
    background: ${theme.colors.white};

    ${disabled
      ? css`
          > :not(input) {
            opacity: 0.3;
          }
        `
      : isFocused
      ? css`
          border-color: ${theme.colors.blue};

          :hover {
            border-color: ${theme.colors.blue};
          }
        `
      : ''}
  `
);

const iconStyle = css`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
`;

const SearchInputIcon = styled(SearchIcon)`
  ${iconStyle}
`;

const RealInput = styled(Input)<SearchInputProps>`
  min-height: ${({ theme }) => theme.space(5) - 2}px;
  margin: 0;
  border: 0;
  border-radius: 0;
  min-width: 0;
  flex: 1 1 auto;
  outline: 0;
  background: ${({ theme }) => theme.colors.white};

  :hover,
  :active,
  :focus {
    border: none;
  }
  ::-ms-clear {
    display: none;
  }
`;

const ClearIcon = styled(CircleCrossIcon).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['isFocused', 'showIcon'].includes(prop as string) &&
    ((prop as string) === 'testId' || defaultValidatorFn(prop))
})<{
  isFocused: boolean;
  disabled?: boolean;
  showIcon: boolean;
}>`
  ${({ theme, isFocused, showIcon }) => css`
    ${iconStyle}
    visibility: ${showIcon ? 'visible' : 'hidden'};
    margin-right: ${theme.space()}px;
    color: ${isFocused ? theme.colors.text : theme.colors.textMuted};
    transition: color ${theme.transition.fast}, opacity ${theme.transition.fast};
    cursor: pointer;

    :enabled:hover {
      color: ${theme.colors.text};
    }
  `}
`;
