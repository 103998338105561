import * as React from 'react';

import { AnimatePresence } from 'framer-motion';
import { Route, Switch, useLocation } from 'react-router-dom';

import { routeViewTransitionDurationMs } from '@maggie/app/constants/transitions';
import { Platform } from '@maggie/cordova/platform';
import type { RouteName } from '@maggie/core/router/types';

import { RouteView } from '../layout/view/RouteView';
import { appRoutes } from './constants/app-routes';

export const AppRoutes: React.FC = React.memo(() => {
  const location = useLocation();
  /**
   * NOTE:
   * force ios to repaint when navigation complete.
   * this hack 🪄 is needed as the scroll bar sometimes appearing in the middle of the screen
   *
   * setTimeout is needed to make sure the force repaint happen after routeView transition
   * has finished, thus webkit re-calculates the scrollbar position
   *
   * for more information, see:
   * https://bugs.webkit.org/show_bug.cgi?id=218124
   * https://ed-app.atlassian.net/browse/ED-23893
   */
  const forceRepaint = React.useCallback(() => {
    if (Platform.get() === 'iOS') {
      document.body.style.setProperty('opacity', '0.99');
      setTimeout(() => {
        document.body.style.removeProperty('opacity');
      }, routeViewTransitionDurationMs);
    }
  }, []);

  return (
    <AnimatePresence initial={false} onExitComplete={forceRepaint}>
      <Switch location={location}>
        {Object.keys(appRoutes).map((routeName: RouteName) => (
          <Route key={appRoutes[routeName].path} path={appRoutes[routeName].path} exact={true}>
            <RouteView routeName={routeName} route={appRoutes[routeName]} />
          </Route>
        ))}
      </Switch>
    </AnimatePresence>
  );
});
