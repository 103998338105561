import * as React from 'react';

import type { RouteData, RouteName, RouteParams } from '@maggie/core/router/types';

import { RouteSplitViewLayout } from './route-split-view-layout/RouteSplitViewLayout';
import { RouteViewLayout } from './route-view-layout/RouteViewLayout';

type RouteLayoutProps = {
  route: RouteData;
  routeName: RouteName;
  routeParams: RouteParams<RouteName>;
};

export const ViewLayout = React.memo(({ routeName, route, routeParams }: RouteLayoutProps) => {
  /**
   * If view is splitViewLayout or locked, we want to hide the navigation bar
   *
   * This hides:
   *  * Navigation MFE (in frontend-reactor)
   *  * Top Navbar from Training MFE
   */
  React.useEffect(() => {
    const showScNav = !!route.splitViewLayout || !!route.locked;
    window.dispatchEvent(new CustomEvent('sc:nav-changed', { detail: !showScNav }));
  }, [route]);

  if (route.splitViewLayout) {
    return <RouteSplitViewLayout mainRoute={routeName} mainParams={routeParams} />;
  }

  return <RouteViewLayout routeName={routeName} routeParams={routeParams} route={route} />;
});
