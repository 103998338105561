import * as React from 'react';

import type { MotionProps } from 'framer-motion';
import styled, { css } from 'styled-components';

import { nonProxyMotion } from '@maggie/config/motion-wrapper';

type Props = { image: string } & MotionProps;

const FeatureImage: React.FC<Props> = ({ image, ...props }) => {
  return (
    <ImageContainer {...props}>
      <StyledFeatureImage src={image} />
    </ImageContainer>
  );
};

const StyledFeatureImage = styled(nonProxyMotion.img)`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    left: 50%;
    max-height: 100%;
    max-width: ${theme.breakpoints.xxlg}em;
    transform: translateX(-50%);
  `}
`;

const ImageContainer = styled(nonProxyMotion.div)`
  height: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
`;

export { FeatureImage };
