function logStorageException(e: unknown, type: 'local-storage' | 'indexedDB') {
  const isDOMException = e instanceof DOMException;
  if (isDOMException) {
    if (e.name === 'SecurityError') {
      console.error(`${type} Check: Access Denied to the storage`);
      return;
    } else if (e.name === 'QuotaExceededError') {
      console.error(`${type} Check: The storage has no more space`);
      return;
    }
  }
  console.error(`${type} Check:`, e);
}

export const StorageAvailabilityErrorCode = 'StorageAvailabilityError';
const storageTest = '__storage_test__';

function indexedDBAvailability() {
  return new Promise<void>((resolve, reject) => {
    const db = window.indexedDB.open(storageTest);
    db.onsuccess = () => {
      window.indexedDB.deleteDatabase(storageTest);
      resolve();
    };
    db.onerror = reject;
  });
}

function localStorageAvailability() {
  // recommended availability test from
  // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
  window.localStorage.setItem(storageTest, storageTest);
  window.localStorage.removeItem(storageTest);
}

export async function storageAvailability() {
  // check local storage
  try {
    localStorageAvailability();
  } catch (e) {
    logStorageException(e, 'local-storage');
    throw new Error(StorageAvailabilityErrorCode);
  }

  // check indexedDB
  try {
    // try to access indexedDB Databases
    await indexedDBAvailability();
  } catch (e) {
    logStorageException(e, 'indexedDB');
    throw new Error(StorageAvailabilityErrorCode);
  }
}
