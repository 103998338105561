import * as React from 'react';

import type { DefaultTheme } from 'styled-components';
import styled, { css } from 'styled-components';

import type { SizeVariant } from '../../types';
import { Skeleton } from '../skeleton/Skeleton';
import { generateColor, generateInitials } from './utils';

export type AvatarColor = 'primary' | 'normal' | string;
const getColorAttrs = (theme: DefaultTheme, color: AvatarColor) => {
  switch (color) {
    case 'normal':
      return css`
        background: ${theme.colors.grey};
        color: ${theme.colors.text};
      `;

    case 'primary':
      return css`
        background: ${theme.colors.navy};
        color: ${theme.colors.white};
      `;

    default:
      return css`
        background: ${color};
        color: ${theme.colors.white};
      `;
  }
};

const getSize = (size?: SizeVariant) => {
  switch (size) {
    case 'xs':
      return '24px';
    case 'md':
      return '40px';

    case 'lg':
      return '64px';

    case 'xlg':
      return '80px';

    case 'xxlg':
      return '128px';

    case 'sm':
    default:
      return '32px';
  }
};

const getSizeAttrs = (size?: SizeVariant) => {
  const sizeInPx = getSize(size);
  const dimensionsCss = css`
    height: ${sizeInPx};
    width: ${sizeInPx};
  `;

  switch (size) {
    case 'xs':
      return css`
        ${dimensionsCss};
        font-size: 10.5px;
      `;
    case 'md':
      return css`
        ${dimensionsCss};
        font-size: 17.5px;
      `;

    case 'lg':
      return css`
        ${dimensionsCss};
        font-size: 28px;
      `;

    case 'xlg':
      return css`
        ${dimensionsCss};
        font-size: 35px;
      `;

    case 'xxlg':
      return css`
        ${dimensionsCss};
        font-size: 56px;
      `;

    case 'sm':
    default:
      return dimensionsCss;
  }
};

type Props = {
  /**
   * Default: 'normal'
   *
   * @type {AvatarColor} -> primary, normal
   */
  color?: AvatarColor;
  /**
   * Default: 'mini'
   *
   * @type {SizeVariant}
   */
  size?: SizeVariant;
  isLoading?: boolean;
} & Partial<Pick<HTMLImageElement, 'alt' | 'src'>>;

const Component: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  size = 'sm',
  color,
  src,
  alt,
  isLoading,
  ...rest
}) => {
  const [imgSrc, setImgSrc] = React.useState(src);

  React.useEffect(() => {
    if (src === imgSrc) return;
    setImgSrc(src);
  }, [src]);

  if (isLoading) {
    const sizeInPx = getSize(size);
    return <Skeleton height={sizeInPx} width={sizeInPx} variant="circular" />;
  }

  const handleImageError = () => {
    setImgSrc('');
  };
  // TODO: https://safetyculture.atlassian.net/browse/TRAINING-554
  const initials = generateInitials((typeof children === 'string' ? children : alt) || '');
  const initialsColor = generateColor(initials);

  return (
    <Wrapper
      as={!!imgSrc ? 'img' : 'div'}
      alt={!!imgSrc ? initials : undefined}
      src={!!imgSrc ? imgSrc : undefined}
      size={size}
      color={color || initialsColor}
      isImage={!!imgSrc}
      onError={!!imgSrc ? handleImageError : undefined}
      {...rest}
    >
      {!imgSrc ? children || initials : null}
    </Wrapper>
  );
};

const Wrapper = styled.div<Props & { isImage?: boolean }>(
  ({ theme, color = 'normal', size, isImage = false }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    user-select: none;
    ${
      isImage &&
      css`
        align-self: center;
        object-fit: cover;
      `
    };
    border-radius: 50%;
    border: 2px solid ${theme.colors.white};
    box-sizing: border-box;

    ${theme.typography.subtitle2}
    font-family: ${theme.fontFamily};
    text-align: center;
    line-height: 1;

    ${!isImage && getColorAttrs(theme, color)}
    ${getSizeAttrs(size)}
  `
);

const Avatar = styled(Component)``;
export { Avatar };
