import * as React from 'react';
import type { ReactNode } from 'react';

import styled from 'styled-components';

import { Dialog, MatchMedia } from '@edapp/ed-components';
import { Screens } from '@maggie/theme/screens';

type Props = {
  children: ReactNode;
  isOpen: boolean;
  useBackdrop?: boolean;
  dialogWrapperStyle?: React.CSSProperties;
  usePortal?: boolean;
  handleClose?: () => void;
};

export const DynamicDialog: React.FC<Props> = ({
  children,
  isOpen,
  useBackdrop,
  dialogWrapperStyle,
  usePortal,
  handleClose
}: Props) => (
  <MatchMedia query={`only screen and (min-width: ${Screens.screenLgMin}px)`}>
    {(isDesktop: boolean) => {
      return (
        <StyledDialog
          isOpen={isOpen}
          animationType="fadeIn"
          usePortal={usePortal}
          useBackdrop={useBackdrop && isDesktop}
          onClose={handleClose}
          isFullScreen={!isDesktop}
          dialogWrapperStyle={dialogWrapperStyle}
        >
          {children}
        </StyledDialog>
      );
    }}
  </MatchMedia>
);

const StyledDialog = styled(Dialog)`
  ${({ theme }) => `border-radius: ${theme.sizes.borderRadius};`}
  @media (min-width: ${Screens.screenLgMin}px) {
    width: 40%;
    position: absolute;
    min-width: ${Screens.screenLgMin}px;
    height: 80%;
  }
`;
