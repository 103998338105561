import * as React from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import CourseRatingDialog from '@maggie/components/course/CourseRatingDialog';
import { RatingDialogActions } from '@maggie/store/rating-dialog/actions';
import type { LxStoreState } from '@maggie/store/types';

export const RatingDialogContainer: React.FC<{}> = () => {
  const { isOpen, backgroundImage, title, rating, review } = useSelector(
    (state: LxStoreState) => state.ratingDialog,
    shallowEqual
  );
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(RatingDialogActions.closeRatingDialog());
  };

  return (
    <CourseRatingDialog
      onClose={onClose}
      open={isOpen}
      backgroundImage={backgroundImage}
      title={title}
      userRating={rating}
      userReview={review}
    />
  );
};
