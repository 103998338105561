import * as React from 'react';

import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';

import { Box } from '@edapp/ed-components';
import type { ThemeType } from '@maggie/theme/theme';

import { TouchScroll } from '../TouchScroll';

type Props = {
  open: boolean;

  /**
   * @default cardBlue
   *
   * @type {keyof ThemeType['colors']}
   */
  backgroundColor?: keyof ThemeType['colors'];

  /**
   * @default white
   *
   * @type {keyof ThemeType['colors']}
   */
  textColor?: keyof ThemeType['colors'];

  className?: string;

  withoutBox?: boolean;

  noEntryAnimation?: boolean;

  noExitAnimation?: boolean;

  /**
   * The container it will attach to. It attaches using ReactDOM.createPortal.
   *
   * @default - `#container` or document.body
   */
  container?: HTMLElement;
};

class FullWindowModal extends React.Component<React.PropsWithChildren<Props>> {
  render() {
    const container = this.props.container || document.querySelector('#container') || document.body;

    return ReactDOM.createPortal(
      <AnimatePresence>
        {this.props.open && (
          <ModalWrapper
            initial={{ opacity: this.props.noEntryAnimation ? 1 : 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: this.props.noExitAnimation ? 1 : 0 }}
            backgroundColor={this.props.backgroundColor || 'cardBlue'}
            textColor={this.props.textColor || 'white'}
            className={classNames({
              'dialog-wrapper': true,
              [this.props.className || '']: !!this.props.className
            })}
          >
            {!this.props.withoutBox && (
              <TouchScroll>
                <StyledBox py="sm" textAlign="center">
                  {this.props.children}
                </StyledBox>
              </TouchScroll>
            )}

            {!!this.props.withoutBox && this.props.children}
          </ModalWrapper>
        )}
      </AnimatePresence>,
      container
    );
  }
}

const StyledBox = styled(Box)`
  color: ${({ theme }) => theme.colors.navy};
  box-sizing: border-box;
  height: 100%;
`;

type WrapperProps = {
  backgroundColor: keyof ThemeType['colors'];
  textColor: keyof ThemeType['colors'];
};
const ModalWrapper = styled(motion.div)<WrapperProps>`
  overflow: hidden;
  position: absolute;
  right: 0;
  bottom: 0;
  left: -1px;
  background-clip: padding-box;
  height: 100%;
  top: 0;
  z-index: 10;

  box-sizing: border-box;
  border-bottom-color: ${props => props.theme.colors[props.backgroundColor]};
  background: ${props => props.theme.colors[props.backgroundColor]};
  color: ${props => props.theme.colors[props.textColor]};
`;

export { FullWindowModal };
