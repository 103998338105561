import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import type { SagaMiddleware } from 'redux-saga';

import { AppLaunchDialog } from '@maggie/components/dialogs/app-launch-dialog/AppLaunchDialog';
import { FeatureFlagsProvider } from '@maggie/components/feature-flags/FeatureFlagsProvider';
import { ChinaPrivacyPolicy } from '@maggie/containers/china-privacy-policy/ChinaPrivacyPolicy';
import {
  launchAnalyticTools,
  launchBranchDeepLink,
  launchMonitoringTools
} from '@maggie/core/vendor-toolings';
import { BackboneActions } from '@maggie/store/backbone/actions';
import { FeatureFlagsActions } from '@maggie/store/feature-flags/actions';
import { FeatureFlagsSelectors } from '@maggie/store/feature-flags/selectors';
import { getLocationPath } from '@maggie/store/navigation/utils';
import { hasUserConsent } from '@maggie/utils/china-privacy-policy';

import { bootApp } from './app-bootstrap';
import { bootCordova, isCordovaBuild } from './cordova-bootstrap';

const triggerDefaultNavigation = () => {
  const url = getLocationPath();
  window.__router.trigger(url);
};

type Props = {
  runSaga: SagaMiddleware<unknown>['run'];
  isUxp?: boolean;
};

/**
 *
 * Because of App in China, we must delay the initialization of cordova.
 * That means cordova plugin usages will not be available until user agrees to T&C.
 *
 * Startup:
 *  1. create datastore
 *  2. render AppLaunch
 *      a. cordovaBootstrap - injects cordova.js in index.html (makes plugins available)
 *      b. run sagas - attach sagas to the datastore
 *      c. appBoostrap - initialize application plugins and global listeners
 *      d. fetch feature flags - in order to render the <App />
 *      e. when app is functional - resume the app and initialise deeplink
 *  3. app is running, cordova plugin can be called normally.
 */
export const AppLaunch: React.FC<React.PropsWithChildren<Props>> = ({ runSaga, isUxp, children }) => {
  const [consented, setIsConsent] = React.useReducer(() => true, false, hasUserConsent);
  const [appBooted, appIsBooted] = React.useReducer(() => true, false);
  const [appResumed, appIsResumed] = React.useReducer(() => true, false);

  const dispatch = useDispatch();
  const featureFlagsFetched = useSelector(FeatureFlagsSelectors.getIsFetched);
  const isAppFunctional = !isUxp ? appBooted && featureFlagsFetched : appBooted;

  // We fetch flags only when `runSaga` is finished (sagas are attached to store)
  // Otherwise, nothing guarantees saga will pick up that action and run
  const onBooted = React.useCallback(() => {
    if (!isUxp) {
      dispatch(FeatureFlagsActions.fetchFeatureFlags());
    }
    appIsBooted();
  }, [dispatch, isUxp]);

  // This hook doesn't need to wait on Feature Flags.
  // This will initialize cordova plugins and third party dependencies (analytics, datadog, etc)
  React.useEffect(() => {
    if (!consented || appBooted) {
      return;
    }

    launchAnalyticTools();
    launchMonitoringTools();
    if (isCordovaBuild) {
      // if consent use script attached document ready.
      bootCordova();
      document.addEventListener(
        'deviceready',
        () => {
          bootApp(runSaga).then(onBooted);
        },
        { once: true }
      );
    } else {
      bootApp(runSaga).then(onBooted);
    }
  }, [consented, appBooted, onBooted, runSaga]);

  React.useEffect(() => {
    // once app is functional, means the application can resume, and can initiate deeplink.
    if (!isAppFunctional) {
      return;
    }

    dispatch(BackboneActions.applicationResume());
    if (!isUxp) {
      launchBranchDeepLink({ fallback: triggerDefaultNavigation }).finally(appIsResumed);
    } else {
      triggerDefaultNavigation();
      appIsResumed();
    }
  }, [isAppFunctional, dispatch, isUxp]);

  if (!consented) {
    return <ChinaPrivacyPolicy open onConsent={setIsConsent} />;
  }

  return (
    <PersistGate persistor={window.__persistedStore}>
      {boostrapped =>
        appResumed && boostrapped ? (
          <FeatureFlagsProvider>{children}</FeatureFlagsProvider>
        ) : (
          <AppLaunchDialog show={!appResumed} />
        )
      }
    </PersistGate>
  );
};
