import * as React from 'react';
import SwipeableCarouselDot from './SwipeableCarouselDot';
import styled from 'styled-components';
import type { ColorsKeys } from '@edapp/themes';
import type { IconSize } from '../../icons/types';

type Props = {
  onClick: (slideNumber: number) => void;
  currentPosition: number;
  slidesCount: number;

  className?: string;
  outline?: boolean;
  color?: ColorsKeys;
  size?: IconSize;
};

const SwipeableCarouselDotGroup: React.FC<Props> = ({
  className,
  slidesCount,
  onClick,
  currentPosition,
  outline,
  color,
  size,
  ...rest
}) => {
  const showDots = slidesCount > 1;
  return (
    <DotGroupContainer className={className} {...rest}>
      {showDots &&
        [...new Array(slidesCount)].map((_, index) => (
          <SwipeableCarouselDot
            key={index}
            onClick={() => onClick(index)}
            active={index === currentPosition}
            outline={outline}
            color={color}
            size={size}
          />
        ))}
    </DotGroupContainer>
  );
};

export const DotGroupContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.space(2)}px;
`;

export default SwipeableCarouselDotGroup;
