import * as React from 'react';

import { motion } from 'framer-motion';
import * as ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Box, Lottie } from '@edapp/ed-components';
import LOADING from '@maggie/assets/lottie/loading.json';
import { SessionSelectors } from '@maggie/store/session/selectors';

export const SsoRedirectionDialog = () => {
  const ssoRedirectionInProgress = useSelector(SessionSelectors.getSSOAuthenticationProgress);

  if (!ssoRedirectionInProgress) {
    return null;
  }
  return ReactDOM.createPortal(
    <Container
      bgColor="white"
      flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      exit={{ opacity: 0, transition: { ease: 'easeOut', duration: 0.5, delay: 1 } }}
    >
      <Loading data={LOADING} autoplay={true} loop={true} />
    </Container>,
    document.body
  );
};

const Loading = styled(Lottie)`
  height: 35%;
`;

const Container = styled(motion(Box))`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.ABOVE_THE_SKY};
`;
