import * as React from 'react';

import { SplitViewContext } from './SplitViewContext';

export const useSplitViewContext = () => {
  const context = React.useContext(SplitViewContext);
  if (!context) {
    throw new Error('useSplitViewContext must be used within a SplitViewContextProvider');
  }
  return context;
};
