import * as React from 'react';

import { motion } from 'framer-motion';
import styled from 'styled-components';

import { NavLink } from '@maggie/components/link/Link';
import type { TopNavigationMenuItem } from '@maggie/layout/navigation/types';

import { appMenuTransitions } from './transitions';

type Props = {
  item: TopNavigationMenuItem;
  onClick: () => void;
};

const MenuItem: React.FC<Props> = ({ item, onClick }) => {
  return (
    <StyledMenuItem variants={appMenuTransitions.menuItem}>
      <MenuItemTypography activeClassName="active" to={item.path} onClick={onClick}>
        {item.title}
      </MenuItemTypography>
    </StyledMenuItem>
  );
};

const MenuItemTypography = styled(NavLink)`
  display: block;
  padding: ${({ theme }) => `${theme.space(2)}px`};
  color: ${({ theme }) => theme.colors.textMuted};
  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.text};
  }

  &.active {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const StyledMenuItem = styled(motion.li)`
  flex-shrink: 0;
  margin-left: ${({ theme }) => theme.space(2)}px;
  margin-right: ${({ theme }) => theme.space(2)}px;

  box-sizing: border-box;

  ${({ theme }) => theme.lineClamp(1)}    
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
  &:last-of-type {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }
`;

export { MenuItem };
