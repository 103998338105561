import * as React from 'react';

import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Box } from '@edapp/ed-components';
import { useAppearance } from '@edapp/themes';
import ED_LOGO_INVERT from '@maggie/assets/img/new-ed-logo-invert.png';
import ED_LOGO from '@maggie/assets/img/new-ed-logo.png';
import { NavLink } from '@maggie/components/link/Link';
import { NotificationsDropdown } from '@maggie/components/notifications/dropdown';
import { routePaths } from '@maggie/core/router/routes';
import { AppLayoutContext } from '@maggie/layout/AppLayoutContext';
import { MaxWidthLayoutStyles } from '@maggie/layout/styled';
import { UserSelectors } from '@maggie/store/user/selectors';

import { NavigationIcons } from '../navigation-icons/NavigationIcons';
import { TopBarContainer, TopBarContent } from '../styled';
import { MenuItem } from './MenuItem';

export const DesktopNavigation: React.FC = () => {
  const isNewNavbar = useSelector(UserSelectors.hasNewNavbarEnabled);
  const {
    isAppMenuOpen,
    menuItems,
    balance,
    isGameAvailable,
    shouldShowStarBalance,
    closeHomeSearch,
    viewHeadComponents
  } = React.useContext(AppLayoutContext);
  const { isDarkMode } = useAppearance();

  return (
    <TopBarContainer data-testid="app-nav">
      <StyledTopBarContent data-testid="desktop-nav-content">
        <Box testId="ed-logo" px={1.5}>
          <NavLink to={routePaths.home.create({})} onClick={closeHomeSearch}>
            <StyledLogo src={isDarkMode ? ED_LOGO_INVERT : ED_LOGO} width={28} />
          </NavLink>
        </Box>

        <MenuList>
          {menuItems.map(item => (
            <MenuItem key={item.path} item={item} onClick={closeHomeSearch} />
          ))}
        </MenuList>

        {!isNewNavbar && (
          <Box mr={1}>
            <NotificationsDropdown />
          </Box>
        )}

        <NavigationIcons
          shouldShiftIcons={isAppMenuOpen}
          shouldShowStarBalance={shouldShowStarBalance}
          isGameAvailable={isGameAvailable}
          balance={balance}
          viewHeadComponents={viewHeadComponents}
          useIconsFor="navigation"
        />
      </StyledTopBarContent>
    </TopBarContainer>
  );
};

const StyledLogo = styled.img`
  vertical-align: middle;
`;

const StyledTopBarContent = styled(TopBarContent)`
  justify-content: flex-start;
  ${MaxWidthLayoutStyles}
`;

const MenuList = styled(motion.ul)`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  height: 100%;

  /* reset styles */
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
`;
