export const HEIGHT_ANIMATION_DURATION = 0.2;

export enum AnimationVariants {
  OPEN = 'open',
  CLOSED = 'closed'
}

export const variants = {
  [AnimationVariants.OPEN]: {
    overflow: 'visible',
    height: 'auto',
    transition: {
      // overflow is not a value that can be interpolated
      // so it is instantly applied at the start of the animation
      // but we need it at the end to animate height correctly
      overflow: {
        delay: HEIGHT_ANIMATION_DURATION
      }
    }
  },
  [AnimationVariants.CLOSED]: {
    overflow: 'hidden',
    height: 0
  }
};
