/**
 *
 * <<< STANDALONE ENTRY FILE >>>
 *
 */
import * as React from 'react';

/**
 * Backbone is required to be available by the app.
 * Most places could be used from an import.
 * However, the communication in the thomas-bridge will run the
 * `Backbone.Events.trigger` in the window and require object to be available.
 */
import * as Backbone from 'backbone';
import { renderError } from 'error';
import { t } from 'i18next';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { ErrorBoundary, ErrorLogger } from '@edapp/monitoring';
import { ScPlatformContextProvider } from '@edapp/sc-web-ui';
import { AppearanceContextProvider } from '@edapp/themes';
import { createWebview } from '@edapp/uxp-webview';
import { App } from '@maggie/app/App';
import { AppLaunch } from '@maggie/app/AppLaunch';
import { ErrorBoundaryFallback } from '@maggie/app/ErrorBoundary/ErrorBoundaryFallback';
import '@maggie/assets/css/app.css';
import '@maggie/assets/css/fonts.css';
import { l10n } from '@maggie/core/l10n';
import { NetworkMonitor } from '@maggie/core/network-monitor';
import { NewAppRouter } from '@maggie/core/router/router';
import {
  StorageAvailabilityErrorCode,
  storageAvailability
} from '@maggie/core/storage-availability';
import {
  launchAnalyticTools,
  launchBrazeTools,
  launchMonitoringTools
} from '@maggie/core/vendor-toolings';
import { getLocationPath, strictQueryParams } from '@maggie/store/navigation/utils';
import { addLogoutListener } from '@maggie/store/request/logout-listener';
import { configureStore } from '@maggie/store/store';
import { ThemeProvider } from '@maggie/theme/ThemeProvider';
import { hasUserConsent } from '@maggie/utils/china-privacy-policy';

import { InteractionApi } from './core/tracker/interaction-api';
import './wdyr';

window.Backbone = Backbone;

require('raf').polyfill(global);
require('core-js/es/map');
require('core-js/es/set');

/**
 *
 *
 * WARNING:
 * anything that is called inside this file has to be platform-agnostic.
 * do not call any functions that requires cordova plugin, or platform specific function.
 *
 * cordova plugin should only be called after AppLaunch.tsx
 */
const initialize = async () => {
  try {
    await storageAvailability();

    /**
     * NOTE:
     * launchAnalyticTools will be triggered if:
     * - App is not for China
     * - or, App is for China and user has already agreed.
     *
     * see,
     * react-workspaces/apps/maggie/src/app/AppLaunch.tsx
     * for App for China initialisation.
     */

    if (hasUserConsent()) {
      launchMonitoringTools();
      launchAnalyticTools();
      launchBrazeTools();
    }

    const l10nValue = await l10n.initialize();
    window.__l10n = l10nValue;

    const { store, runSaga } = await configureStore();

    window.__router = new NewAppRouter(store);
    window.__networkMonitor = new NetworkMonitor(store);

    // Fallback to UXP webview
    const webview = createWebview();
    window.__scWebview = webview;

    addLogoutListener(store);

    InteractionApi.startupInteractionsApi();

    /**
     * WARNING: extra parameter for uxp and only uxp!.
     * this will skip 2 things:
     * 1. branch initialisation
     * 2. fetching initial feature flag.
     */
    const matched = window.__router.findMatch(getLocationPath());
    const { query } = strictQueryParams<any, { sc_user_id?: string }>(matched?.params ?? {});

    const element = document.querySelector(`#react-app`);
    if (!element) return;
    const root = createRoot(element);

    root.render(
      <Provider store={store}>
        <AppearanceContextProvider>
          <ThemeProvider i18n={l10nValue}>
            <ScPlatformContextProvider>
              <ErrorBoundary fallback={ErrorBoundaryFallback}>
                <AppLaunch runSaga={runSaga} isUxp={!!query.sc_user_id}>
                  <App />
                </AppLaunch>
              </ErrorBoundary>
            </ScPlatformContextProvider>
          </ThemeProvider>
        </AppearanceContextProvider>
      </Provider>
    );
  } catch (err) {
    await l10n.initialize();
    let errorMessage;
    if (err instanceof Error && err.message === StorageAvailabilityErrorCode) {
      errorMessage = t('storage-error.message', { ns: 'learners-experience' });
    }
    renderError(errorMessage);
    window.navigator?.splashscreen?.hide();
    ErrorLogger.captureEvent('failed to initialize app', 'fatal', { err });
  }
};

initialize();
