import type { Store } from 'redux';

import { LessonSelectors } from '@maggie/store/courseware/lessons/selectors';
import { LessonUtils } from '@maggie/store/courseware/lessons/utils';
import { getAlreadyEarnedStarsForSlide } from '@maggie/store/slide-progress/utils';
import { StarActions } from '@maggie/store/star/actions';
import type { LxStoreState } from '@maggie/store/types';
import { UserSelectors } from '@maggie/store/user/selectors';

import type { BackboneEventHandler } from '../backboneListener';

export const backboneStarEventHandler: BackboneEventHandler = (store: Store<LxStoreState>) => {
  return (interaction: any) => {
    const { earned, lessonId, slideId } = interaction.starsRewarded;
    if (!interaction.is_lesson) {
      // Completing the slide in other ways (e.g. brain boost)
      // is ineligable for earning a star.
      return;
    }

    // if stars are not enabled
    const lesson = LessonSelectors.getLesson(lessonId, store.getState());
    if (
      !UserSelectors.hasStarsEnabled(store.getState().user) ||
      !lesson ||
      !lesson.features.areStarsEnabled
    ) {
      return;
    }

    const slide = LessonUtils.getSlide(lessonId, slideId, store.getState());
    if (slide == null) {
      return;
    }

    const numStarsAlreadyEarned = getAlreadyEarnedStarsForSlide(
      store.getState().slideProgress,
      lessonId,
      slideId
    );
    const numStarsNewlyEarned = earned - numStarsAlreadyEarned;

    if (numStarsNewlyEarned <= 0) {
      return;
    }

    const { courseId } = lesson;
    const totalStars = LessonUtils.getTotalStarsForSlide(lesson, slideId);
    store.dispatch(
      StarActions.rewardStarsFromSlide(
        courseId,
        lessonId,
        slideId,
        numStarsNewlyEarned,
        earned,
        totalStars
      )
    );
  };
};
