import * as React from 'react';

import { useSelector } from 'react-redux';

import { NotificationsDropdown as BaseNotificationsDropdown } from '@edapp/notifications';
import { ConfigSelectors } from '@maggie/store/config/selectors';
import { UserSelectors } from '@maggie/store/user/selectors';

export const NotificationsDropdown: React.FC = () => {
  const isOnline = useSelector(ConfigSelectors.isOnline);
  const isEnabled = useSelector(UserSelectors.hasNotificationsEnabled);

  if (!isOnline || !isEnabled) {
    return null;
  }

  return <BaseNotificationsDropdown />;
};
