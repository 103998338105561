import * as React from 'react';
import type { Feed, FeedItem, FeedStoreState } from '@knocklabs/client';
import type { Props } from './types';
import { initialFeedStore, PAGE_SIZE } from './constants';
import Knock from '@knocklabs/client';
import { NotificationsContext } from './context';

export const NotificationsProvider: React.FC<Props> = React.memo(
  ({ feed, apiKey, userId, knockToken, children, pageSize = PAGE_SIZE, onNotificationClick }) => {
    const knockClient = React.useMemo(() => new Knock(apiKey), []);
    const [feedClient, setFeedClient] = React.useState<Feed | null>(null);
    const [state, setState] = React.useState<FeedStoreState>(initialFeedStore);

    // To help debugging
    (window as any).__knock = knockClient;
    (window as any).__feedClient = feedClient;

    // On mount and when knockToken or userId changes
    React.useEffect(() => {
      if (!!knockToken && !!userId) {
        knockClient.authenticate(userId, knockToken);
        const initializedFeedClient = knockClient.feeds.initialize(feed);
        setFeedClient(initializedFeedClient);
        initializedFeedClient.listenForUpdates();
        initializedFeedClient.fetch({ page_size: pageSize });

        initializedFeedClient.store.subscribe(s => {
          setState(s);
        });
      }

      return () => {
        feedClient?.teardown();
      };
    }, [knockToken, userId]);

    const markNotificationAsSeem = React.useCallback(
      async (notification: FeedItem) => {
        if (!notification.seen_at) {
          await feedClient?.markAsSeen(notification);
        }
      },
      [feedClient]
    );

    const markNotificationAsRead = React.useCallback(
      async (notification: FeedItem) => {
        if (!notification.read_at) {
          await feedClient?.markAsRead(notification);
        }
      },
      [feedClient]
    );

    const fetchNextPage = React.useCallback(
      async (after: string | undefined) => {
        const data = await feedClient?.fetch({ after, page_size: pageSize });
        if (data?.status !== 'ok') {
          console.error('Failed to fetch knock next page: ', { data });
        }
      },
      [feedClient]
    );

    const fetchPreviousPage = React.useCallback(
      async (before: string | undefined) => {
        const data = await feedClient?.fetch({ before, page_size: pageSize });
        if (data?.status !== 'ok') {
          console.error('Failed to fetch knock next page: ', { data });
        }
      },
      [feedClient]
    );

    return (
      <NotificationsContext.Provider
        value={{
          state,
          onNotificationClick,
          markNotificationAsRead,
          markNotificationAsSeem,
          fetchNextPage,
          fetchPreviousPage
        }}
      >
        {children}
      </NotificationsContext.Provider>
    );
  }
);
