import styled, { css } from 'styled-components';
import type { DefaultTheme, ThemeProps } from 'styled-components';

import type { InputProps } from './types';

export const INPUT_TEST_ID = 'input';

export const enabledInputStyle = ({ theme }: ThemeProps<DefaultTheme>) => css`
  cursor: text;
  &:hover {
    border-color: ${theme.colors.greyHover};
  }
  &:focus {
    border-color: ${theme.colors.blue};
  }
`;

export const disabledInputStyle = css`
  cursor: auto;
  opacity: 0.3;
`;

export const inputStyle = ({
  hasMargin = true,
  theme,
  isFullWidth,
  isTransparent,
  error,
  overflowEllipsis = true
}: InputProps & ThemeProps<DefaultTheme>) => css`
  :enabled {
    ${enabledInputStyle({ theme })}
  }
  :disabled {
    ${disabledInputStyle}
  }
  color: ${theme.colors.text};
  padding: ${theme.space()}px ${theme.space(2)}px ${theme.space()}px ${theme.space(2)}px;
  background: ${isTransparent ? 'transparent' : theme.colors.white};
  border-radius: ${theme.sizes.borderRadius * 2}px;
  border-color: ${!!error ? theme.colors.red : theme.colors.greyHover};
  /* IE fix for all inputs */
  min-height: ${theme.space(5)}px;

  ${hasMargin && `margin-bottom: ${theme.space(2)}px;`}

  transition: border-color ${theme.transition.slow};
  ${isFullWidth && 'width: 100%;'};
  ${theme.typography.normal}
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  outline: none;
  overflow: hidden;
  ${overflowEllipsis && 'text-overflow: ellipsis;'};
  white-space: nowrap;

  ::placeholder {
    color: ${theme.colors.textMuted};
  }

  ${
    error &&
    css`
      &:hover {
        border-color: ${theme.colors.red};
      }

      &:focus {
        border-color: ${theme.colors.red};
      }
    `
  }
`;

export const Input = styled.input.attrs<InputProps>(
  ({ type = 'text', testId = INPUT_TEST_ID, disabled, isDisabled }) => ({
    'data-testid': testId,
    disabled: disabled || isDisabled,
    type
  })
)<InputProps>`
  ${inputStyle}
`;
