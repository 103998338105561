import * as React from 'react';

import type { TimerProps, TimerStatus } from './types';

const INTERVAL = 1000;

export const useTimer = ({
  timerType = 'increment',
  initialTimerStatus = 'stop',
  startTime = 0,
  endTime = timerType === 'decrement' ? 0 : Infinity,
  onTimerUpdate,
  onTimerEnd
}: Partial<TimerProps>) => {
  const [elapse, setElapse] = React.useState(startTime);
  const [status, setStatus] = React.useState<TimerStatus>(initialTimerStatus);
  const intervalRef = React.useRef<NodeJS.Timeout | null>(null);

  const start = React.useCallback(() => {
    if (!intervalRef.current) {
      setStatus('running');

      intervalRef.current = setInterval(() => {
        setElapse(elapse => (timerType === 'decrement' ? elapse - INTERVAL : elapse + INTERVAL));
      }, INTERVAL);
    }
  }, []);

  const reset = React.useCallback(() => {
    setStatus('reset');
    setElapse(0);
  }, []);

  const stop = React.useCallback(() => {
    setStatus('stop');
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  React.useEffect(() => {
    onTimerUpdate?.(elapse);
    if (elapse === endTime) {
      stop();
      onTimerEnd?.(elapse);
    }
  }, [elapse]);

  React.useEffect(() => {
    if (initialTimerStatus === 'running') {
      start();
    }
    return () => {
      stop();
    };
  }, [initialTimerStatus]);

  return { start, reset, stop, status, time: elapse };
};
