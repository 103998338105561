export const enum IconTransitionStates {
  MENU = 'isMenu',
  BACK_BUTTON = 'isBackButton'
}

export const IconTransitions = {
  topBar: {
    [IconTransitionStates.MENU]: {
      transform: 'rotate(0deg) scale(1) translateX(0px)',
      height: '2px'
    },
    [IconTransitionStates.BACK_BUTTON]: {
      transform: 'rotate(-45deg) scale(0.6) translateX(-9px)',
      height: '4px'
    }
  },
  middleBar: {
    [IconTransitionStates.MENU]: {
      opacity: 1
    },
    [IconTransitionStates.BACK_BUTTON]: {
      opacity: 0
    }
  },
  bottomBar: {
    [IconTransitionStates.MENU]: {
      transform: 'rotate(0deg) scale(1) translateX(0px)',
      height: '2px'
    },
    [IconTransitionStates.BACK_BUTTON]: {
      transform: 'rotate(45deg) scale(0.6) translateX(-9px)',
      height: '4px'
    }
  }
};
