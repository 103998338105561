import * as React from 'react';

import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Box } from '@edapp/ed-components';
import { DownloadFooter } from '@maggie/containers/download-footer/DownloadFooter';
import { useMenuItems } from '@maggie/layout/hooks/useMenuItems';
import { Navigation } from '@maggie/layout/navigation/Navigation';
import { ConfigSelectors } from '@maggie/store/config/selectors';
import { NavigationActions } from '@maggie/store/navigation/actions';
import { SearchActions } from '@maggie/store/search/actions';
import { UserSelectors } from '@maggie/store/user/selectors';

import { AppLayoutContext } from './AppLayoutContext';
import { useIsMobile } from './app-mobile-context/useIsMobile';
import { useHideNavigation, useRoute, useSetStatusBarStyle, useStarBalance } from './hooks';
import { appMenuTransitions } from './navigation/app-navigation/transitions';
import { NavigationIconNames } from './navigation/types';
import { AppLayoutSelectors } from './selectors';

export const AppLayout: React.FC<React.PropsWithChildren<{}>> = React.memo(({ children }) => {
  const isNewNavbar = useSelector(UserSelectors.hasNewNavbarEnabled);

  const [isAppMenuOpen, setIsAppMenuOpen] = React.useState(false);
  const dispatch = useDispatch();

  const menuItems = useMenuItems();
  const hideNavigation = useHideNavigation();
  const { routeName, routeParams, routeData } = useRoute();
  const { balance, shouldShowStarBalance } = useStarBalance();
  const isGameAvailable = useSelector(UserSelectors.isGameAvailable);

  const isMobile = useIsMobile();
  const hasBackButton = useSelector(AppLayoutSelectors.getHasBackButton(routeName));
  const viewHeadComponents = routeData?.viewLayout?.viewHeadComponents || [
    NavigationIconNames.starBalance,
    NavigationIconNames.brainBoost
  ];
  const shouldRenderRoute = useSelector(AppLayoutSelectors.shouldRenderRoute(routeName));
  const locked = routeData?.locked || !shouldRenderRoute;
  const platformClassNames = useSelector(ConfigSelectors.getPlatformClassNames(locked));

  useSetStatusBarStyle({ routeName });

  const closeHomeSearch = React.useCallback(() => {
    dispatch(SearchActions.setIsOpen(false));
  }, []);

  const onGoBack = React.useCallback(() => {
    if (routeName) {
      dispatch(NavigationActions.goBack(routeName, routeParams || {}));
    }
  }, [routeName, routeParams]);

  // if viewport changes while menu was open
  React.useEffect(() => {
    if (!isMobile && isAppMenuOpen) {
      setIsAppMenuOpen(false);
    }
  }, [isMobile]);

  const menuAnimate = isAppMenuOpen && isMobile ? 'menuOpen' : 'menuClosed';

  return (
    <AppLayoutContext.Provider
      value={{
        hasBackButton,
        locked,
        hideNavigation,
        menuItems,
        isAppMenuOpen,
        setIsAppMenuOpen,
        shouldShowStarBalance,
        balance,
        isGameAvailable,
        onGoBack,
        closeHomeSearch,
        viewHeadComponents
      }}
    >
      <Layout
        testId="layout"
        className={platformClassNames}
        initial={menuAnimate}
        animate={menuAnimate}
        flex={true}
        flexDirection="column"
        variants={isMobile ? appMenuTransitions.layout : {}}
      >
        <Navigation>
          <RouteViewsContainer data-testid="views">{children}</RouteViewsContainer>
          {isNewNavbar && <DownloadFooter />}
        </Navigation>
      </Layout>
    </AppLayoutContext.Provider>
  );
});

const RouteViewsContainer = styled(motion.div)`
  z-index: 0;

  flex: 1;
  width: 100%;
  overflow: hidden;

  position: relative;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.background};
`;

const Layout = styled(motion(Box))`
  position: relative;
  height: 100%;
`;
