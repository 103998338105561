import * as React from 'react';
import type { Props } from './types';

export const NoSearchResultSvg: React.FC<Props> = ({ width = 180, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 181 178"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M101.149 121.396H90.7621H90.3219H79.9345L75.7678 138.549H90.3219H90.7621H105.287L101.149 121.396Z"
      fill="#EDF3FC"
    />
    <path
      opacity="0.65"
      d="M78.7314 126.331L101.824 124.304L101.149 121.396H79.9345L78.7314 126.331Z"
      fill="#DCE5F2"
    />
    <path
      d="M139.559 122.365H41.4952C36.0081 122.365 31.548 117.901 31.548 112.408V46.7037C31.548 41.2112 36.0081 36.7467 41.4952 36.7467H139.559C145.047 36.7467 149.507 41.2112 149.507 46.7037V112.408C149.507 117.901 145.076 122.365 139.559 122.365Z"
      fill="url(#paint0_linear_2386_14832)"
    />
    <path
      opacity="0.65"
      d="M90.5 114.76C92.345 114.76 93.8406 113.264 93.8406 111.419C93.8406 109.574 92.345 108.079 90.5 108.079C88.6551 108.079 87.1594 109.574 87.1594 111.419C87.1594 113.264 88.6551 114.76 90.5 114.76Z"
      fill="#4740C8"
    />
    <path
      d="M139.442 41.4463H41.7593C38.8543 41.4463 36.4775 43.8254 36.4775 46.7332V98.4862C36.4775 100.19 37.8567 101.6 39.5879 101.6H141.613C143.315 101.6 144.724 100.219 144.724 98.4862V46.7332C144.724 43.796 142.347 41.4463 139.442 41.4463Z"
      fill="white"
    />
    <path
      d="M108.925 136.464H70.9262C68.9016 136.464 67.229 137.903 66.8476 139.812C66.8182 140.253 67.141 140.635 67.6105 140.635H67.6398H112.241H112.27C112.711 140.635 113.063 140.253 113.033 139.812C112.623 137.903 110.95 136.464 108.925 136.464Z"
      fill="#E8EFF9"
    />
    <g clipPath="url(#clip0_2386_14832)">
      <path
        d="M68.0341 57.7312C59.042 71.2084 44.4818 98.4341 48.5698 103.658C51.9111 105.705 64.7147 106.733 64.7147 106.733C64.7147 106.733 75.9989 59.2998 77.4147 56.7788C77.833 56.0336 68.4926 57.045 68.0341 57.7312Z"
        fill="#E5AE8C"
      />
      <path
        d="M104.623 69.0441C106.073 68.6961 107.667 67.9533 108.805 67.2339C108.676 66.883 105.39 53.8402 104.34 51.2024C103.989 50.3193 94.9562 61.042 94.5526 61.3724C95.0614 64.0482 96.247 69.2002 97.0892 71.9608C99.2854 73.2066 102.181 69.6289 104.62 69.0441H104.623Z"
        fill="#F5CCB3"
      />
      <path
        d="M91.894 71.9044C91.894 71.9044 93.8571 65.434 104.113 61.1344C108.052 59.8571 109.222 62.2583 112.656 67.9458C116.315 74.0043 91.894 71.9044 91.894 71.9044Z"
        fill="#ADABFF"
      />
      <path
        d="M88.4722 42.924C87.706 44.9856 87.6358 46.7724 87.4136 48.2638C86.9925 51.1326 87.1592 52.5567 86.9983 55.1506C86.9954 55.1857 86.9895 55.2179 86.9895 55.2588C86.9164 56.3555 86.934 57.4492 87.1709 58.4142C87.5803 60.1279 88.6681 61.4555 91.1362 61.7216C95.0693 62.1515 98.9762 59.5167 101.743 56.8175C105.062 53.5686 106.971 49.8722 107.296 45.2371C107.351 44.4563 107.357 43.6697 107.255 42.9006C107.193 42.459 107.103 42.0233 106.962 41.5963C105.304 36.6016 99.023 35.5722 94.6044 36.9145C91.645 37.8152 89.5425 40.0493 88.4751 42.924H88.4722Z"
        fill="#F5CCB3"
      />
      <path
        d="M106.001 37.0649C103.781 34.9559 100.908 33.5517 96.3924 34.8326C92.8453 35.8415 86.7154 36.4844 85.5193 40.3357C85.1538 41.5113 84.9986 43.6365 87.8069 44.5341C90.6152 45.4317 93.3661 40.2489 94.8416 43.9767C93.8368 48.5413 94.7765 51.2855 96.2426 49.8946C96.2426 49.8946 98.9479 52.5067 99.3251 53.2027C100.7 55.7556 104.844 56.8923 107.848 52.9567C110.853 49.0211 109.117 40.0262 106.001 37.0649Z"
        fill="#1F2533"
      />
      <path
        d="M100.552 51.2148C100.395 52.585 99.1972 53.6481 97.8791 53.5876C96.5587 53.5271 95.6185 52.3663 95.7752 50.9938C95.9319 49.6237 97.1302 48.5606 98.4482 48.6211C99.7686 48.6816 100.709 49.8424 100.552 51.2148Z"
        fill="#F5CCB3"
      />
      <path
        d="M98.1209 58.754C96.5676 60.7064 94.5459 61.3824 94.5459 61.3824L94.9563 63.3667C96.12 63.1093 98.2685 60.5347 98.1209 58.7509V58.754Z"
        fill="#E5AE8C"
      />
      <path
        d="M129.865 101.261C129.435 99.5136 128.85 97.7985 128.311 96.0761C127.227 92.6086 126.036 89.167 124.621 85.8001C123.224 82.4769 121.979 79.6907 119.621 76.1704C117.212 72.5747 116.055 71.5284 112.447 67.7225C112.447 67.7225 106.68 60.2781 97.8403 66.5461C89.215 72.5999 89.8953 73.7374 85.0803 80.0481C84.1924 85.6751 87.1502 106.188 88.4472 112.738C88.7706 114.377 89.2191 116.02 90.0213 117.524C90.8513 119.08 92.0904 120.416 93.6717 121.332C95.2341 122.237 97.0135 122.735 98.8164 122.931C102.877 123.373 106.878 122.135 110.685 121.048C112.574 120.508 114.463 119.953 116.33 119.347C115.918 119.664 115.506 119.984 115.091 120.301C114.295 120.909 113.488 121.505 112.677 122.095C112.35 122.331 112.369 122.843 112.636 123.119C112.944 123.439 113.385 123.401 113.722 123.175C116.393 121.371 118.975 119.47 121.48 117.474C122.292 117.141 123.097 116.795 123.892 116.421C124.394 116.183 124.349 115.67 124.05 115.373C125.483 114.171 126.004 111.976 127.384 110.717C127.384 110.717 131.517 107.928 129.874 101.257L129.865 101.261Z"
        fill="#FFF06B"
      />
      <path d="M48.4569 42.0436L70.3539 42.6055V49.5828H48.4569V42.0436Z" fill="#85DAFF" />
      <path d="M48.4569 57.1244L70.3539 56.5602V49.5829H48.4569V57.1244Z" fill="#85DAFF" />
      <path
        d="M48.4565 57.122C51.0568 57.122 53.1646 53.7466 53.1646 49.5828C53.1646 45.419 51.0568 42.0436 48.4565 42.0436C45.8563 42.0436 43.7484 45.419 43.7484 49.5828C43.7484 53.7466 45.8563 57.122 48.4565 57.122Z"
        fill="#64CCF9"
      />
      <path
        d="M70.3535 56.56C72.7599 56.56 74.7107 53.4362 74.7107 49.5828C74.7107 45.7293 72.7599 42.6055 70.3535 42.6055C67.9471 42.6055 65.9963 45.7293 65.9963 49.5828C65.9963 53.4362 67.9471 56.56 70.3535 56.56Z"
        fill="#470F1C"
      />
      <path
        d="M48.4567 55.6211C50.2946 55.6211 51.7845 52.9176 51.7845 49.5827C51.7845 46.2478 50.2946 43.5443 48.4567 43.5443C46.6187 43.5443 45.1288 46.2478 45.1288 49.5827C45.1288 52.9176 46.6187 55.6211 48.4567 55.6211Z"
        fill="#1F2533"
      />
      <path d="M58.0365 41.2733L77.3631 41.8961V49.5828H58.0365V41.2733Z" fill="#85DAFF" />
      <path d="M58.0365 57.8924L77.3631 57.2719V49.5829H58.0365V57.8924Z" fill="#85DAFF" />
      <path
        d="M58.0368 57.8924C60.9019 57.8924 63.2245 54.1721 63.2245 49.5828C63.2245 44.9936 60.9019 41.2733 58.0368 41.2733C55.1717 41.2733 52.8491 44.9936 52.8491 49.5828C52.8491 54.1721 55.1717 57.8924 58.0368 57.8924Z"
        fill="#64CCF9"
      />
      <path d="M75.2378 43.9871L87.7684 44.3898V49.3746H75.2378V43.9871Z" fill="#64CCF9" />
      <path d="M75.2378 54.762L87.7684 54.3593V49.3745H75.2378V54.762Z" fill="#64CCF9" />
      <path
        d="M87.7688 54.3593C89.4872 54.3593 90.8802 52.1276 90.8802 49.3745C90.8802 46.6215 89.4872 44.3898 87.7688 44.3898C86.0504 44.3898 84.6573 46.6215 84.6573 49.3745C84.6573 52.1276 86.0504 54.3593 87.7688 54.3593Z"
        fill="#64CCF9"
      />
      <path
        d="M58.0372 56.2394C60.0624 56.2394 61.7043 53.2592 61.7043 49.5829C61.7043 45.9066 60.0624 42.9264 58.0372 42.9264C56.0119 42.9264 54.3701 45.9066 54.3701 49.5829C54.3701 53.2592 56.0119 56.2394 58.0372 56.2394Z"
        fill="#1F2533"
      />
      <path
        d="M81.4433 45.0615C81.2514 43.6404 79.7063 40.7399 79.3103 39.3744C79.1475 38.813 78.8209 37.8822 78.652 37.744C78.4832 37.6058 77.927 37.3398 77.4242 37.6673C76.4767 38.2784 77.7667 41.1785 77.8517 42.4094C77.8517 42.4094 76.7164 41.1522 76.3617 40.8188C76.0362 40.4095 74.6377 38.8276 73.5372 38.8685C72.1185 38.9211 73.1171 40.4153 73.258 41.0059C71.8757 39.7106 69.959 39.0013 69.1646 39.7498C68.3046 40.5597 71.9124 42.7877 71.9124 42.7877C71.9124 42.7877 69.327 40.6318 68.5251 42.1553C67.8055 44.2596 72.3024 43.6661 71.1249 50.889C69.4972 54.8714 71.1249 59.8759 71.1249 59.8759C64.1156 70.8937 57.3814 82.7935 54.5569 95.6579C52.1934 106.423 59.8645 109.78 66.0882 107.786C74.319 105.148 77.9099 103.646 81.3304 102.292C101.624 94.2671 76.9358 97.5457 73.4862 87.6082C75.1624 81.1901 76.3053 74.8509 78.4892 68.6609C80.061 64.2106 81.798 59.2778 81.849 54.386C81.8757 54.3042 81.8952 54.2135 81.9 54.1083C82.0045 51.0819 81.849 48.0498 81.4457 45.0615H81.4433Z"
        fill="#F5CCB3"
      />
      <path
        d="M84.0668 43.7944C83.2899 43.1822 82.2271 44.0032 81.6256 44.5454C80.9967 45.1134 80.4967 45.7754 80.1337 46.4894C79.9654 46.5775 79.9022 46.7493 79.9374 46.9118L79.9383 46.9144C79.9059 46.9912 79.8721 47.0685 79.8421 47.1473C79.7723 47.3332 79.9065 47.4745 80.0793 47.5238C80.2081 48.0623 80.3506 48.5974 80.4995 49.1315C80.6012 49.493 80.7084 49.8511 80.8175 50.21C80.918 50.5401 81.0005 50.8897 81.1324 51.2099C81.0713 51.4664 81.0225 51.7243 80.9946 51.9804C80.9758 52.1551 81.1717 52.2917 81.3474 52.2936C81.5562 52.2952 81.7057 52.1733 81.7753 51.9963C81.9618 51.5282 82.0823 51.0433 82.2348 50.5686C82.3883 50.0921 82.5549 49.6195 82.7303 49.1482C83.0737 48.2269 83.4555 47.315 83.8442 46.4036C84.184 45.6067 84.8946 44.4505 84.0624 43.796L84.0668 43.7944Z"
        fill="#F5CCB3"
      />
      <path
        d="M96.5898 68.321C96.5898 68.321 92.9571 70.1707 72.2419 87.2303L78.5963 110.382C78.5963 110.382 94.7179 101.234 102.488 95.0173C110.258 88.8031 110.682 72.2747 106.865 68.321C103.052 64.3644 96.5898 68.321 96.5898 68.321Z"
        fill="#ADABFF"
      />
      <path
        d="M95.3705 29.0868C94.1637 29.4802 93.9885 30.5678 92.6764 31.204C91.3441 31.85 89.9458 32.6225 88.4195 34.2744C86.9784 35.8341 85.8844 38.5301 85.5475 39.4206C85.4822 39.5933 85.3095 39.7002 85.1259 39.6813C84.3734 39.604 82.4677 39.4375 81.6662 39.639C81.5556 39.6668 81.4648 39.7615 81.4943 39.8718C82.0933 42.1112 104.912 47.4954 109.51 48.5589C109.833 48.6337 110.087 48.2963 109.938 47.9995L109.817 47.7571C109.774 47.673 109.764 47.577 109.786 47.4855C110.343 45.1679 110.526 42.0965 110.176 39.7851C110.172 39.7624 110.171 39.7407 110.171 39.7177C110.225 36.266 106.7 29.1558 95.4871 29.0695C95.4482 29.0692 95.4076 29.0747 95.3705 29.0868Z"
        fill="#FFF06B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.7414 39.7795C85.8135 39.6335 85.9903 39.5737 86.1362 39.6458C88.9557 41.0393 97.1254 44.3729 107.145 46.5029C107.305 46.5368 107.406 46.6933 107.372 46.8525C107.339 47.0117 107.182 47.1134 107.023 47.0795C96.9534 44.939 88.7373 41.5889 85.875 40.1743C85.7291 40.1022 85.6693 39.9254 85.7414 39.7795Z"
        fill="#F3E34C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.986 38.1003C108.854 38.1957 108.67 38.1663 108.574 38.0344C106.42 35.059 102.669 32.0666 95.8927 31.5493C95.7304 31.5369 95.6089 31.3953 95.6213 31.233C95.6336 31.0707 95.7753 30.9491 95.9376 30.9615C102.898 31.4929 106.802 34.5822 109.052 37.6886C109.147 37.8205 109.118 38.0048 108.986 38.1003Z"
        fill="#F3E34C"
      />
      <path
        d="M94.2529 37.1107L90.7993 41.8405L102.48 45.8267L102.403 40.1371C102.393 39.4279 101.959 38.7936 101.302 38.5274L96.3379 36.5165C95.5889 36.2131 94.7295 36.458 94.2529 37.1107Z"
        fill="#F3E34C"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2386_14832"
        x1="61.81"
        y1="30.3602"
        x2="149.452"
        y2="122.424"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFF5FE" />
        <stop offset="1" stopColor="#D6DEEB" />
      </linearGradient>
      <clipPath id="clip0_2386_14832">
        <rect
          width="98.4498"
          height="85.0873"
          fill="white"
          transform="translate(41.5698 16.5066)"
        />
      </clipPath>
    </defs>
  </svg>
);
