import * as React from 'react';

import * as ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import { BrainboostToast } from '@maggie/components/brainboost/brain-boost-toast/BrainboostToast';
import { BrainBoostActions } from '@maggie/store/brain-boost/actions';
import { BrainBoostSelectors } from '@maggie/store/brain-boost/selectors';

export const BrainboostToastContainer: React.FC = () => {
  const dispatch = useDispatch();

  const streak = useSelector(s => BrainBoostSelectors.getBrainboostToastState(s).streak);
  const show = useSelector(s => BrainBoostSelectors.getBrainboostToastState(s).show);

  const timer = React.useRef<NodeJS.Timeout>();

  React.useEffect(() => {
    if (!!show) {
      timer.current = setTimeout(() => {
        dispatch(BrainBoostActions.hideToast());
      }, 2000);
    }

    return () => {
      clearTimeout(timer.current);
    };
  }, [show]);

  return ReactDOM.createPortal(<BrainboostToast streak={streak} show={show} />, document.body);
};
