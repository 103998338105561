import * as React from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { appRoutes } from '@maggie/app/constants/app-routes';
import { LoadingBanner } from '@maggie/components/common/LoadingBanner';
import { useIsMobile } from '@maggie/layout/app-mobile-context/useIsMobile';

import { useTrackView } from '../hooks';
import { SplitViewContext } from '../split-view-context/SplitViewContext';
import { SplitViewLayoutUtils } from '../utils';
import { splitViewLayoutSizes } from './constants';
import { MainToolbar } from './main-toolbar/MainToolbar';

export const SplitViewMain: React.FC = React.memo(() => {
  const isMobile = useIsMobile();
  const { mainRoute, mainParams } = React.useContext(SplitViewContext);

  useTrackView(mainRoute, mainParams);

  const MainView = appRoutes[mainRoute].component;
  if (!MainView) {
    throw Error(`Route does not support splitViewLayout: ${mainRoute}`);
  }

  const hasChild = React.useMemo(() => SplitViewLayoutUtils.hasChild(mainRoute), [mainRoute]);

  return (
    <AnimatePresence exitBeforeEnter={true}>
      <Main
        data-testid={`main ${mainRoute}`}
        hasDetail={!isMobile && hasChild}
        size={appRoutes[mainRoute].splitViewLayout?.main?.size}
        key={mainRoute}
      >
        <MainToolbar />

        <React.Suspense fallback={<LoadingBanner />}>
          <MainView route={mainRoute} params={mainParams} />
        </React.Suspense>
      </Main>
    </AnimatePresence>
  );
});

type MainProps = {
  /**
   * If there is no detail view - the main will take all available width.
   * Otherwise, it will limit to the defined size
   */
  hasDetail: boolean;
  size?: keyof typeof splitViewLayoutSizes;
};

const Main = styled(motion.div)<MainProps>`
  flex-shrink: 0;
  position: relative;

  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  ${({ size, hasDetail }) =>
    !!hasDetail &&
    css`
      border-right: 1px solid ${({ theme }) => theme.colors.grey};
      max-width: ${splitViewLayoutSizes[size || 'md'].width}px;
    `}
`;
