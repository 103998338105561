import * as React from 'react';

import * as ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Toast } from '@maggie/components/common/toast/Toast';
import { ToastActions } from '@maggie/store/toast/actions';
import type { LxStoreState } from '@maggie/store/types';

export const ToastContainer: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const { show, isOn, text } = useSelector((state: LxStoreState) => state.toast);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (!!show) {
      timeout = setTimeout(() => {
        dispatch(ToastActions.hideToast());
      }, 3500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [show]);

  return ReactDOM.createPortal(<Toast isOn={isOn} text={text} show={show} />, document.body);
};
