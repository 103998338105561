import styled, { css } from 'styled-components';

const MainLogo = styled.img(
  ({
    theme: {
      space,
      screens: { screenLgMin, screenXlgMin }
    }
  }) =>
    css`
      position: absolute;
      top: ${space(7)}px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);

      height: 26px;
      width: auto;

      @media only screen and (max-height: ${screenLgMin - 1}px) {
        top: ${space(3)}px;
        height: 21px;
        width: auto;
      }

      /* @media only screen and (min-height: ${screenLgMin -
        1}px) and (max-height: ${screenXlgMin}px) {
        top: ${space(5)}px;
      } */
    `
);

export { MainLogo };
