import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { itly } from '@edapp/analytics-tracking';
import { Dropdown, SearchInput } from '@edapp/ed-components';
import { SearchContent } from '@maggie/components/search/SearchContent';
import { useRoute } from '@maggie/layout/hooks';
import { SearchActions } from '@maggie/store/search/actions';
import { SearchSelectors } from '@maggie/store/search/selectors';

export const SearchInputWithDropdown: React.FC = () => {
  const { t } = useTranslation('learners-experience');
  const searchTerm = useSelector(SearchSelectors.getSearchTerm);
  const dispatch = useDispatch();
  const { routeName } = useRoute();

  const handleFocusSearchTerm = React.useCallback(() => {
    dispatch(SearchActions.fetchSearchCatalog());
    dispatch(SearchActions.setIsOpen(true));
    itly.searchFunctionClicked();
  }, []);

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        window.__router.navigate('search', {});
        dispatch(SearchActions.addRecentSearchTerm(searchTerm));
        // only do this after user navigates away
        setTimeout(() => {
          dispatch(SearchActions.setIsPreviewMode(false));
        }, 300);
      }
    },
    [searchTerm]
  );

  const handleChangeSearchTerm = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    search(e.target.value);
  }, []);

  const handleClear = React.useCallback(() => {
    setTimeout(() => {
      search('');
    }, 300);
    window.__router.navigate('home', {}, true);
  }, []);

  const search = (searchTerm: string) => {
    dispatch(SearchActions.setSearchTerm(searchTerm));
    // reset preview state mode if user clears the value
    if (!searchTerm) {
      dispatch(SearchActions.setIsPreviewMode(true));
    }
  };

  React.useEffect(() => {
    // only do this after user navigates away
    setTimeout(() => {
      dispatch(SearchActions.setIsPreviewMode(routeName !== 'search'));
    }, 300);
  }, [routeName]);

  if (routeName === 'search') {
    return (
      <StyledSearchInput
        placeholder={t('common.search-courseware')}
        value={searchTerm}
        triggerClickOnClearSearch={true}
        onFocus={handleFocusSearchTerm}
        onChange={handleChangeSearchTerm}
        onKeyDown={handleKeyDown}
        onClear={handleClear}
      />
    );
  }

  return (
    <Dropdown
      placement="bottom-end"
      matchTriggerWidth={true}
      trigger={
        <StyledSearchInput
          placeholder={t('common.search-courseware')}
          value={searchTerm}
          triggerClickOnClearSearch={true}
          onFocus={handleFocusSearchTerm}
          onChange={handleChangeSearchTerm}
          onKeyDown={handleKeyDown}
          onClear={handleClear}
        />
      }
    >
      <StyledSearchContent />
    </Dropdown>
  );
};

const StyledSearchInput = styled(SearchInput)`
  width: 400px;
`;

const StyledSearchContent = styled(SearchContent)`
  /* Override */
  max-width: 480px;
  min-width: 480px; // size of search cards
  width: 100%;
  margin: unset;
  color: ${({ theme }) => theme.colors.text};

  /* Scroll */
  max-height: 50%;
  overflow-y: auto;

  /* Props */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;
