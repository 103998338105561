import styled, { css } from 'styled-components';

import { getSpaceValue } from '../../utils';
import type { Props } from './types';

export const DIVIDER_TEST_ID = 'divider';

export const Divider = styled.hr.attrs<Props>(({ testId = DIVIDER_TEST_ID }) => ({
  'data-testid': testId
}))<Props>(
  ({ theme, margin, marginTop, marginBottom, marginLeft, marginRight, color, isVertical }) => css`
    margin-top: ${getSpaceValue(margin) || getSpaceValue(marginTop)}px;
    margin-bottom: ${getSpaceValue(margin) || getSpaceValue(marginBottom)}px;
    background-color: ${color ? theme.colors[color] : theme.colors.greyHover};
    flex-shrink: 0;
    display: block;
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border: none;
    ${isVertical &&
    css`
      height: 100%;
      width: 1px;
      min-height: 32px;
      margin-top: ${marginTop ? getSpaceValue(marginTop) : 0}px;
      margin-bottom: ${marginBottom ? getSpaceValue(marginBottom) : 0}px;
      margin-left: ${getSpaceValue(margin) || getSpaceValue(marginLeft) || 0}px;
      margin-right: ${getSpaceValue(margin) || getSpaceValue(marginRight) || 0}px;
    `}
  `
);
