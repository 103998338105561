import * as React from 'react';

import styled from 'styled-components';

import { AchievementColorScheme, AchievementPreview } from '@edapp/achievements';
import {
  Box,
  CrossIcon,
  IconButton,
  SwipeableCarousel,
  SwipeableCarouselArrow
} from '@edapp/ed-components';
import { Platform } from '@maggie/cordova/platform';
import type { CustomAchievementsListItem } from '@maggie/store/custom-achievements/types';

type Props = {
  newAchievements: CustomAchievementsListItem[];
  handleClose: () => void;
};

export const AchievementsCarousel: React.FC<Props> = ({ newAchievements, handleClose }: Props) => {
  const isMobile = Platform.isMobile();
  return (
    <SwipeableCarousel
      showFullContent
      showDots
      disabled={newAchievements.length < 2}
      customPrevArrow={CustomCarouselArrowLeft}
      customNextArrow={CustomCarouselArrowRight}
      hideArrows={isMobile}
    >
      {newAchievements.map((achievement, index) => {
        return (
          <StyledAchievementWrapper flex flexDirection="row" key={achievement.id}>
            <StyledIconButton
              icon={CrossIcon}
              iconSize="md"
              iconColor={
                achievement.colorScheme === AchievementColorScheme.LIGHT
                  ? 'fixedBlack'
                  : 'fixedWhite'
              }
              variant="transparent"
              onClick={handleClose}
              testId="close-achievement-popup"
            />
            <AchievementPreview
              badgeBorderColor={achievement.badgeBorderColor}
              badgeMediaUrl={achievement.badgeMediaUrl}
              colorScheme={achievement.colorScheme}
              completion={achievement.completion}
              title={achievement.title}
              withBackgroundAnimation={index === 0}
            />
          </StyledAchievementWrapper>
        );
      })}
    </SwipeableCarousel>
  );
};

const CustomCarouselArrowLeft = styled(SwipeableCarouselArrow)`
  left: 60px;
`;

const CustomCarouselArrowRight = styled(SwipeableCarouselArrow)`
  right: 60px;
`;

const StyledAchievementWrapper = styled(Box)`
  width: 100%;
  height: 100%;
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 30px;
  right: 25px;
  z-index: 15;
  :hover:enabled {
    background-color: transparent;
  }
`;
