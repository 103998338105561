import * as React from 'react';

export type Context = {
  /**
   * Base URL to be prepend to every asset
   */
  baseUrl: string;

  /**
   * Possible domains of images that will be replaced by `baseUrl`
   */
  transformedHostnames: string[];
};

export const AssetContext = React.createContext<Context>({
  baseUrl: '',
  transformedHostnames: []
});
