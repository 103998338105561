import { datadogRum } from '@datadog/browser-rum';

import type { IAdapter, SeverityType, UserData } from '../types';
import { defaultConfig } from './constants';
import type { Config } from './types';

/**
 * Adapter for [Datadog RUM](https://docs.datadoghq.com/real_user_monitoring/) which requires [@datadog/browser-rum](https://www.npmjs.com/package/@datadog/browser-rum) peerDependency
 */
export class DatadogAdapter implements IAdapter {
  constructor({
    sampleRate = defaultConfig.sessionSampleRate,
    replaySampleRate = defaultConfig.sessionReplaySampleRate,
    ...config
  }: Config) {
    datadogRum.init({
      ...defaultConfig,
      ...config,
      trackResources: true,
      trackLongTasks: true,
      sessionSampleRate: sampleRate,
      sessionReplaySampleRate: replaySampleRate
    });

    if (replaySampleRate > 0) {
      datadogRum.startSessionReplayRecording();
    }
  }

  setUserContext<TUserData extends UserData>(user: TUserData): void {
    datadogRum.setUser(user);
  }

  clearUserContext(): void {
    datadogRum.clearUser();
  }

  captureException<TError extends Error>(
    error: TError,
    context?: Record<string, unknown> | undefined
  ): void {
    datadogRum.addError(error, context);
  }

  captureEvent<TData extends Record<string, unknown>>(
    message: string,
    level: SeverityType,
    extra: TData
  ): void {
    switch (level) {
      case 'info':
      case 'warning':
      case 'debug': {
        datadogRum.addAction(message, { level, ...extra });
        break;
      }

      default: {
        datadogRum.addError(new Error(message), { level, ...extra });
        break;
      }
    }
  }
}
