import * as React from 'react';
import type { Fallback } from '@edapp/monitoring';
import { ErrorLogger } from '@edapp/monitoring';
import { GenericErrorFallback } from './GenericErrorFallback';

const chunkFailedMessage = /Loading chunk [\d]+ failed/;
const chunkLoadErrorName = /ChunkLoadError/;

const isChunkError = (error: any): boolean => {
  if (!error) {
    return false;
  }

  if (!!error?.message && chunkFailedMessage.test(error.message)) {
    return true;
  }

  if (error?.name && chunkLoadErrorName.test(error.name)) {
    return true;
  }

  return false;
};

export const ErrorBoundaryFallback: Fallback = ({ error, reset }) => {
  const handleRetryClick = () => {
    const chunkError = isChunkError(error);

    ErrorLogger.captureEvent('Retry from ErrorBoundary', 'warning', {
      error,
      isChunkError: chunkError
    });

    if (chunkError) {
      window.location.reload();
      return;
    }

    reset();
  };

  return <GenericErrorFallback onRetry={handleRetryClick} />;
};
