import * as React from 'react';
import { createRoot } from 'react-dom/client';

import { GenericErrorFallback } from '@maggie/app/ErrorBoundary/GenericErrorFallback';
import { ThemeProvider } from '@maggie/theme/ThemeProvider';

export const renderError = (message?: string) => {
  const element = document.querySelector(`#react-app`);
  if (!element) return;
  const root = createRoot(element);

  root.render(
    <ThemeProvider>
      <GenericErrorFallback message={message} onRetry={() => window.location.reload()} />
    </ThemeProvider>,
  );
};
