import * as React from 'react';

import { useScPlatformContext } from '@edapp/sc-web-ui';

type WebviewUxpContextType = {
  /**
   * @returns `true` if it was handled - by default, returns `false`
   */
  openWebview: (path: string) => boolean;

  /**
   * @param {boolean} refresh refresh previous webview upon closing this webview. default `false`
   *
   * @returns `true` if it was handled - by default, returns `false`
   */
  closeWebview: (refresh?: boolean) => boolean;

  /**
   * Handles clicks of `<a />`, if it's handled by webview it prevents default navigation
   */
  handleOpenWebviewClick: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  /**
   * Tell webview that user wants to share a link.
   * So, native side can open the sharing bottom sheet.
   */
  shareLink: (link: string) => void;
  /**
   * Tell webview that user wants to share a qr code.
   * So, native side can generate the code from the link and open the sharing bottom sheet.
   */
  shareQRCode: (link: string) => void;
  /**
   * Tell webview that user wants to open the AI create FAB menu.
   *
   * @returns `true` if it was handled - by default, returns `false`
   */
  openAICreateMenu: () => boolean;
};

const WebviewUxpContext = React.createContext<WebviewUxpContextType>({
  openWebview: () => false,
  closeWebview: () => false,
  handleOpenWebviewClick: () => ({}),
  shareLink: () => ({}),
  shareQRCode: () => ({}),
  openAICreateMenu: () => false
});

type Props = {
  openWebview: WebviewUxpContextType['openWebview'];
  closeWebview: WebviewUxpContextType['closeWebview'];
  shareLink?: WebviewUxpContextType['shareLink'];
  shareQRCode?: WebviewUxpContextType['shareQRCode'];
  openAICreateMenu?: WebviewUxpContextType['openAICreateMenu'];
};

export const WebviewUxpContextProvider: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  openWebview,
  closeWebview,
  shareLink,
  shareQRCode,
  openAICreateMenu
}) => {
  const { platform } = useScPlatformContext();

  /**
   * This is a helper for anchor tags - that stops propagation of the click if the webview should own the navigation
   */
  const handleOpenWebviewClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (!platform) {
        return;
      }

      const path = e.currentTarget.href.replace(window.location.origin, '');
      const redirected = openWebview(path);
      if (redirected) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    [openWebview, platform]
  );

  /**
   * This directly calls to open page in the webview
   */
  const handleOpenWebview = React.useCallback(
    (path: string) => {
      if (!platform) {
        return false;
      }

      return openWebview(path);
    },
    [openWebview, platform]
  );

  const handleShareLink = React.useCallback(
    (link: string) => {
      if (!platform) {
        return;
      }
      shareLink?.(link);
    },
    [platform, shareLink]
  );

  /**
   * This directly calls to trigger the share function with the QR code
   */
  const handleShareQRCode = React.useCallback(
    (link: string) => {
      if (!platform) {
        return false;
      }
      shareQRCode?.(link);
    },
    [platform, shareQRCode]
  );

  /**
   * This directly calls to open page in the webview
   */
  const handleCloseWebview = React.useCallback(
    (refresh?: boolean) => {
      if (!platform) {
        return false;
      }

      return closeWebview(refresh);
    },
    [closeWebview, platform]
  );

  const handleOpenAICreateMenu = React.useCallback(() => {
    if (!platform) {
      return false;
    }
    return openAICreateMenu?.() ?? false;
  }, [platform, openAICreateMenu]);

  return (
    <WebviewUxpContext.Provider
      value={{
        openWebview: handleOpenWebview,
        closeWebview: handleCloseWebview,
        handleOpenWebviewClick,
        shareLink: handleShareLink,
        shareQRCode: handleShareQRCode,
        openAICreateMenu: handleOpenAICreateMenu
      }}
    >
      {children}
    </WebviewUxpContext.Provider>
  );
};

export const useWebviewUxpContext = (): WebviewUxpContextType => {
  const ctx = React.useContext(WebviewUxpContext);
  if (!ctx) {
    throw Error(`no uxp context provided`);
  }

  return ctx;
};
