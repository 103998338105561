import styled, { css } from 'styled-components';

export const TopBarContent = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    height: 100%;
    padding-right: ${theme.space(0.5)}px;
    padding-left: ${theme.space(0.5)}px;
    margin: auto;
    position: relative;

    @media screen and (min-width: ${({ theme }) => theme.screens.screenXlgMin}px) {
      padding-right: ${theme.space(3.5)}px;
      padding-left: ${theme.space(3.5)}px;
    }
  `
);

export const TopBarContainer = styled.nav(
  ({ theme }) => css`
    width: 100%;
    height: ${theme.topContainerHeight}px;
    z-index: 10;

    background-color: ${theme.colors.white};

    box-sizing: border-box;
    /* design has it as #E9EEF6, we don't have it? temporarily change this to grey to make it more apparent */
    border-bottom: 1px solid ${theme.colors.grey};

    .is-ios & {
      padding-top: constant(safe-area-inset-top);
      padding-top: env(safe-area-inset-top);
      height: calc(constant(safe-area-inset-top) + ${theme.topContainerHeight}px);
      height: calc(env(safe-area-inset-top) + ${theme.topContainerHeight}px);
    }
  `
);
