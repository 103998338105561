import * as React from 'react';

import * as ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';

import { SsoRedirectionDialog } from '@maggie/components/login/SsoRedirectionDialog';
import { AchievementsDialog } from '@maggie/containers/achievements-dialog/AchievementsDialog';
import { ActionDialogContainer } from '@maggie/containers/action-dialog/ActionDialog';
import { BrainboostToastContainer } from '@maggie/containers/brainboost/BrainboostToastContainer';
import { ConferenceReminderDialog } from '@maggie/containers/conference/ConferenceReminderDialog';
import { CustomSplashScreenContainer } from '@maggie/containers/custom-splash-screen/CustomSplashScreen';
import { DailyLoginRewardDialog } from '@maggie/containers/daily-login-reward-dialog/DailyLoginRewardDialog';
import { DownloadFooter } from '@maggie/containers/download-footer/DownloadFooter';
import { LearnerDemographic } from '@maggie/containers/learner-demographic/LearnerDemographic';
import { LockedDialogContainer } from '@maggie/containers/locked-dialog/LockedDialog';
import { LogoutDialog } from '@maggie/containers/logout-dialog/LogoutDialog';
import { RatingDialogContainer } from '@maggie/containers/rating-dialog/RatingDialog';
import { ToastContainer } from '@maggie/containers/toast/Toast';
import { UpdateAppDialogContainer } from '@maggie/containers/update-app-dialog/UpdateAppDialog';
import { UserSelectors } from '@maggie/store/user/selectors';

export const AppGlobalDialogs = () => {
  const isNewNavbar = useSelector(UserSelectors.hasNewNavbarEnabled);

  const dialogsDOMElement =
    document.querySelector('#dialogs') || document.querySelector('#training-root');
  if (!dialogsDOMElement) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <LearnerDemographic />

      <UpdateAppDialogContainer />

      <ToastContainer />

      <RatingDialogContainer />

      <LockedDialogContainer />

      <ActionDialogContainer />

      <AchievementsDialog />

      <DailyLoginRewardDialog />

      <ConferenceReminderDialog />

      <LogoutDialog />

      {/* NOTE: if we have new navbar, we render the download footer in AppLayout.tsx */}
      {!isNewNavbar && <DownloadFooter />}

      <CustomSplashScreenContainer />

      <BrainboostToastContainer />

      <SsoRedirectionDialog />
    </>,
    dialogsDOMElement
  );
};
