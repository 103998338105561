import type { Variants } from 'framer-motion';

export const NAV_MENU_WIDTH = 264;

export const appMenuTransitions: { [componentName: string]: Variants } = {
  menuItem: {
    menuOpen: {
      opacity: 1
    },
    menuClosed: {
      opacity: 0
    }
  },

  menuList: {
    menuOpen: {
      transition: {
        width: { stiffness: 1000, velocity: -100 },
        delayChildren: 0.2,
        staggerChildren: 0.07,
        staggerDirection: 1
      }
    },
    menuClosed: {
      transition: {
        when: 'afterChildren',
        width: { stiffness: 1000, velocity: -100 },
        staggerChildren: 0.05,
        staggerDirection: -1
      }
    }
  },

  menu: {
    menuOpen: {
      position: 'absolute',
      width: NAV_MENU_WIDTH,
      transition: {
        width: { stiffness: 1000, velocity: -100 }
      }
    },
    menuClosed: {
      position: 'absolute',
      width: 0,
      transition: {
        width: { stiffness: 1000, velocity: -100 }
      }
    }
  },

  backdrop: {
    menuOpen: {
      backgroundColor: 'rgba(0, 0, 0, 0.3)',
      transition: {
        width: { stiffness: 1000, velocity: -100 }
      }
    },
    menuClosed: {
      backgroundColor: 'transparent',
      transition: {
        width: { delay: 1, stiffness: 1000, velocity: -100 }
      }
    }
  }
};
