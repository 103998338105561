import { useSelector } from 'react-redux';

import { StarSelectors } from '@maggie/store/star/selectors';
import { UserSelectors } from '@maggie/store/user/selectors';

export const useStarBalance = () => {
  const shouldShowStarBalance = useSelector(UserSelectors.shouldShowStarBalance);
  const balance = useSelector(s => StarSelectors.getDisplayedStarBalance(s.star));
  return { shouldShowStarBalance, balance };
};
