import * as React from 'react';

import { AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import { Box } from '@edapp/ed-components';
import { nonProxyMotion } from '@maggie/config/motion-wrapper';

import type {
  RadioField,
  RadioTextAreaField,
  TextAreaField
} from '../demographic-question-form/types';
import { Radio } from '../radio/Radio';
import { TextArea } from '../text-area/TextArea';

type Props = {
  name: string;
  field: RadioTextAreaField;
  onChange: (name: string, value: RadioTextAreaField) => void;
};

const RadioTextArea: React.FC<Props> = props => {
  const onRadioChange = (name: string, field: RadioField) => {
    props.onChange(props.name, { ...props.field, checked: field.value });
  };

  const onTextAreaChange = (name: string, field: TextAreaField) => {
    props.onChange(props.name, { ...props.field, value: field.value });
  };

  return (
    <Box flex={true} flexDirection="column">
      <Radio
        name={props.name + '-radio'}
        field={{ type: 'radio', value: props.field.checked, label: props.field.label }}
        onChange={onRadioChange}
      />

      <AnimatePresence>
        {!!props.field.checked && (
          <OverflowHiddenBox
            initial={{ height: 0, overflow: 'hidden' }}
            animate={{ height: 'auto', overflow: 'initial' }}
            exit={{ height: 0, overflow: 'hidden' }}
          >
            <TextArea
              name={props.name}
              field={{
                type: 'text-area',
                placeholder: props.field.placeholder,
                value: props.field.value || '',
                max: props.field.max
              }}
              onChange={onTextAreaChange}
            />
          </OverflowHiddenBox>
        )}
      </AnimatePresence>
    </Box>
  );
};

const OverflowHiddenBox = styled(nonProxyMotion.div)`
  overflow: hidden;
`;

export { RadioTextArea };
