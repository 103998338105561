import type { SwipeableCarouselAnimationValues, SwipeableCarouselAnimationType } from './types';
import { SwipeableCarouselAnimations } from './types';

const OPACITY_DEFAULT_ANIMATION_VALUES: SwipeableCarouselAnimationValues = {
  activeValue: 1,
  inactiveValue: 1
};

const SCALE_DEFAULT_ANIMATION_VALUES: SwipeableCarouselAnimationValues = {
  activeValue: 1,
  inactiveValue: 1
};

const DEFAULT_ANIMATIONS: SwipeableCarouselAnimationValues[] = [];
// @ts-expect-error TODO: EDAPP-42821 check why a value is assigned to an array 
DEFAULT_ANIMATIONS[SwipeableCarouselAnimations.OPACITY] = OPACITY_DEFAULT_ANIMATION_VALUES;
// @ts-expect-error TODO: EDAPP-42821 check why a value is assigned to an array 
DEFAULT_ANIMATIONS[SwipeableCarouselAnimations.SCALE] = SCALE_DEFAULT_ANIMATION_VALUES;

export const getAnimation = (
  key: SwipeableCarouselAnimations,
  animations: string[] | SwipeableCarouselAnimationType[]
// @ts-expect-error TODO: EDAPP-42821 check why a value is assigned to an array 
) => animations[key] || DEFAULT_ANIMATIONS[key];
