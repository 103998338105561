import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Box, Button, Dialog } from '@edapp/ed-components';
import { usePrevious } from '@edapp/ed-components/hooks';
import { generateTitle } from '@edapp/translations/src/i18n';
import STAR_FACE_GIF from '@maggie/assets/video/star-face-anim.gif';
import { StarActions } from '@maggie/store/star/actions';
import { StarSelectors } from '@maggie/store/star/selectors';
import type { LxStoreState } from '@maggie/store/types';

export const DailyLoginRewardDialog: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('learners-experience');

  const generateRewardTitle = React.useCallback(() => generateTitle('app-open-reward.titles'), []);

  const [title, setTitle] = React.useState(generateRewardTitle());

  const isOnValidRoute = useSelector(
    (state: LxStoreState) => state.navigation.route !== 'register'
  );
  const isStarsEnabled = useSelector(StarSelectors.isStarsEnabled);
  const numberOfStars = useSelector(StarSelectors.getNumberOfDailyRewardStars);

  const isOpen = isOnValidRoute && isStarsEnabled && numberOfStars > 0;

  const wasOpen = usePrevious(isOpen);

  React.useEffect(() => {
    if (wasOpen === false && isOpen) {
      setTitle(generateRewardTitle());
    }
  }, [wasOpen, isOpen, generateRewardTitle]);

  const handleClose = () => dispatch(StarActions.closeDailyReward());

  return (
    <StyledDailyLoginRewardDialog
      isOpen={isOpen}
      animationType="fadeIn"
      usePortal={false}
      isFullScreen={true}
    >
      <Box
        id="app-open-reward"
        boxSizing="border-box"
        flex={true}
        flexDirection="column"
        flexGrow={1}
        alignItems="center"
        justifyContent="center"
      >
        <WhiteTextBox p="md" textAlign="center">
          <StyledStarImage src={STAR_FACE_GIF} />

          <Box py="sm">
            <StyledTitle>{title}</StyledTitle>

            <StyledText>{t('app-open-reward.main', { count: numberOfStars })}</StyledText>
          </Box>

          <Box p="md">
            <StyledButton variant="transparent" size="lg" onClick={handleClose}>
              {t('app-open-reward.exit')}
            </StyledButton>
          </Box>
        </WhiteTextBox>
      </Box>
    </StyledDailyLoginRewardDialog>
  );
};

const WhiteTextBox = styled(Box)`
  color: ${({ theme }) => theme.colors.white};
`;

const StyledDailyLoginRewardDialog = styled(Dialog)`
  background: ${({ theme }) => theme.colors.starsDialogBlue};
`;

const StyledStarImage = styled.img`
  width: 180px;
  height: 180px;
`;

const StyledTitle = styled.h1`
  font-size: 1.75em;
  font-weight: 300;
  letter-spacing: -0.12px;
  margin: 0 0 4px;
`;

const StyledText = styled.p`
  font-size: 1.25em;
  font-weight: 600;
  margin: 0;
`;

const StyledButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.colors.white};
  font-size: 1.25em;
  font-weight: 600;
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.transparent};
    color: ${({ theme }) => theme.colors.text};
  }
`;
