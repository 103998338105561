import styled from 'styled-components';
import { Dialog, Button } from '@edapp/ed-components';

const StyledDialog = styled(Dialog)`
  width: calc(100vw - ${({ theme }) => theme.space(5)}px);
  max-width: 500px;
  color: ${({ theme }) => theme.colors.text};

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${({ theme }) => theme.screens.screenMdMin}px) {
    width: 90%;
  }
`;

const StyledDialogActionButton = styled(Button)`
  border-top: 1px solid ${props => props.theme.colors.greyHover};
  color: ${props => props.theme.colors.text};
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
`;

export { StyledDialog, StyledDialogActionButton };
