import * as React from 'react';

import { compare } from 'compare-versions';

import { Platform } from '@maggie/cordova/platform';

export const useCheckUpdateApp = (appVersion: string, recommendedAppVersion: string) => {
  const [shouldPromptUpdate, setShouldPromptUpdate] = React.useState(false);

  const acknowledgeUpdate = () => {
    setLastAppVersionChecked(recommendedAppVersion);
    setShouldPromptUpdate(false);
  };

  React.useEffect(() => {
    if (!Platform.isCordova() || !recommendedAppVersion) {
      return; // no need to check anything
    }

    const lastVersionChecked = getLastAppVersionChecked();
    if (!!lastVersionChecked && compare(recommendedAppVersion, lastVersionChecked, '=')) {
      return; // already asked for update
    }

    const isNewer = compare(recommendedAppVersion, appVersion, '>');
    if (isNewer) {
      setShouldPromptUpdate(true);
    }
  }, [recommendedAppVersion, appVersion]);

  return { shouldPromptUpdate, acknowledgeUpdate };
};

const STORAGE_KEY = 'update-app-version';
const setLastAppVersionChecked = (value: string) => {
  window.localStorage.setItem(STORAGE_KEY, value);
};

export const getLastAppVersionChecked = () => {
  return window.localStorage.getItem(STORAGE_KEY);
};
