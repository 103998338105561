import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { Box } from '../box';

export const MotionCard = styled(motion(Box)).attrs({
  boxShadow: 'shallow',
  rounded: true
})(
  ({ theme }) => css`
    border-radius: ${theme.sizes.borderRadius * 2}px;
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.greyHover};
    z-index: 1060;

    * {
      box-sizing: border-box;
    }
  `
);

export const OptionsList = styled.ul<{ minWidth: number | null }>(
  ({ theme, minWidth }) => css`
    border-radius: ${theme.sizes.borderRadius * 2}px;
    ${minWidth
      ? css`
          min-width: ${minWidth}px;
        `
      : ''}
    margin: 0;
    padding: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    list-style: none;
    padding: 0;
    overflow-y: auto;
    max-height: 85vh;
  `
);
