import type { Variants } from 'framer-motion';

/**
 * It's ok to use `vw` and `vh` here because these views won't be used in SCT (MFE).
 *
 * These are for splashscreen and registration screen.
 */
export const splashScreenAnimations: { [key: string]: Variants } = {
  rotateScale: {
    initial: (horizontal: boolean) =>
      !horizontal ? { y: '-60vh', scale: 4, rotate: 0 } : { x: '-25vw', scale: 0, rotate: 0 },
    animate: (horizontal: boolean) =>
      !horizontal ? { y: 0, scale: 1, rotate: 360 } : { x: 0, scale: 1, rotate: 360 }
  },
  fadeUp: {
    initial: (horizontal: boolean) => (!horizontal ? { y: 80, opacity: 0 } : { x: 60, opacity: 0 }),
    animate: (horizontal: boolean) => (!horizontal ? { y: 0, opacity: 1 } : { x: 0, opacity: 1 })
  }
};
