import type { ContentBlock } from '@knocklabs/client';
import type { Actor } from './types';

export const getActorName = (actor?: Actor) => {
  if (!actor) {
    return '';
  }

  if (actor.first_name || actor.last_name) {
    return (actor.first_name || '') + ' ' + (actor.last_name || '');
  }

  if (actor.name) {
    return actor.name || '';
  }

  return '';
};

/**
 * Knock will return something like "\n    /#course/639805b1b6251534899ec8af    \n"
 * And we need to format that to look like a proper URL.
 */
export const createUriFromBlock = (block: ContentBlock | undefined) => {
  if (!block) {
    return '';
  }

  const blockValue = block.rendered;
  return blockValue.replace(/[\n\s]*/g, '');
};
