import * as React from 'react';

import styled, { css } from 'styled-components';

import { NavLink } from '@maggie/components/link/Link';

import type { TopNavigationMenuItem } from '../types';

type Props = {
  item: TopNavigationMenuItem;
  onClick: () => void;
};

export const MenuItem: React.FC<Props> = ({ item, onClick }) => {
  const handleClickMenuItem = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.currentTarget.blur();
    onClick();
  };

  return (
    <StyledMenuItem key={item.path}>
      <MenuItemTypography to={item.path} activeClassName="active" onClick={handleClickMenuItem}>
        {item.title}
      </MenuItemTypography>
    </StyledMenuItem>
  );
};

export const MenuItemTypography = styled(NavLink)`
  display: flex;
  align-items: center;
  transition: all 300ms;
  outline: none;
  height: 100%;
  white-space: nowrap;
  ${({ theme }) => css`
    padding: 0 ${theme.space(2)}px;
    color: ${theme.colors.textMuted};
    &:hover,
    &:active,
    &:focus {
      color: ${theme.colors.text};
      background-color: ${theme.colors.lightGrey};
    }
  `}

  &.${({ activeClassName }) => activeClassName} {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const StyledMenuItem = styled.li`
  height: 100%;
  ${({ theme }) => theme.lineClamp(1)}
`;
