import * as React from 'react';

import { useTranslation } from 'react-i18next';
import styled, { css, useTheme } from 'styled-components';

import { Typography } from '@edapp/sc-web-ui';
import { AppLayoutContext } from '@maggie/layout/AppLayoutContext';
import { useIsMobile } from '@maggie/layout/app-mobile-context/useIsMobile';

import { HamburgerIcon } from './HamburgerIcon';

export const MenuToggle: React.FC = React.memo(() => {
  const { t } = useTranslation('learners-experience');
  const theme = useTheme();
  const { hasBackButton, onGoBack, setIsAppMenuOpen, isAppMenuOpen, hideNavigation } =
    React.useContext(AppLayoutContext);

  const isMobile = useIsMobile();

  const handleToggleMenu = () => {
    if (hasBackButton) {
      return onGoBack();
    }
    setIsAppMenuOpen(!isAppMenuOpen);
  };

  if (hideNavigation && !hasBackButton) {
    // restricted
    return null;
  }

  return (
    <Menu
      data-testid="menu-toggle"
      id="menu-toggle"
      onClick={handleToggleMenu}
      // we force color style here because there is a re-painting issue for ios
      style={{ color: theme.colors.text }}
    >
      <HamburgerIcon shouldAnimate={hasBackButton} />

      {hasBackButton && !isMobile && (
        <Typography
          variant="captionMedium"
          color="accent.text.default"
          data-testid="menu-back-text"
        >
          {t('generic.back')}
        </Typography>
      )}
    </Menu>
  );
});

const Menu = styled.button(
  ({ theme }) => css`
    display: flex;
    border: none;
    background-color: transparent;
    padding-left: ${theme.space(2.5)}px;
    z-index: 10;
    cursor: pointer;
  `
);
