import type { Variants } from 'framer-motion';

export const routeViewTransitionDurationMs = 300; // in ms
export const routeViewTransitions: Variants = {
  initial: (diff: number) => {
    if (diff < 0) {
      return { x: 0 };
    }

    if (diff === 0) {
      return { opacity: 0 };
    }

    return { x: '100%' };
  },
  animate: (diff: number) => {
    if (diff === 0) {
      return { opacity: 1 };
    }

    return { x: 0 };
  },
  exit: (diff: number) => {
    if (diff < 0) {
      return { x: '100%' };
    }

    if (diff === 0) {
      return { opacity: 0 };
    }

    return { x: 0 };
  }
};
