import type { IAdapter, SeverityType } from '../types';

/* eslint-disable no-console */

export class ConsoleAdapter implements IAdapter {
  setUserContext(): void {
    return;
  }

  clearUserContext(): void {
    return;
  }

  captureException<TError extends Error>(
    error: TError,
    context?: Record<string, unknown> | undefined
  ): void {
    console.error(error, context);
  }

  captureEvent<TData extends Record<string, unknown>>(
    message: string,
    level: SeverityType,
    extra: TData
  ): void {
    switch (level) {
      case 'critical':
      case 'fatal':
      case 'error':
        console.error('capture >>> ', message, level, extra);
        break;

      case 'debug':
        console.debug('capture >>> ', message, level, extra);
        break;

      case 'warning':
        console.warn('capture >>> ', message, level, extra);
        break;

      case 'info':
        console.info('capture >>> ', message, level, extra);
        break;

      default:
        console.log('capture >>> ', message, level, extra);
        break;
    }
  }
}
