import * as React from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { useWebviewUxpContext } from '@edapp/uxp-webview';
import { LockedDialog } from '@maggie/components/dialogs/locked-dialog/LockedDialog';
import { routePaths } from '@maggie/core/router/routes';
import { LockedDialogActions } from '@maggie/store/locked-dialog/actions';
import type { LxStoreState } from '@maggie/store/types';

export const LockedDialogContainer: React.FC = () => {
  const { openWebview } = useWebviewUxpContext();
  const { isOpen, contentText, title, redirectParams, acceptText } = useSelector(
    (state: LxStoreState) => state.lockedDialog,
    shallowEqual
  );
  const dispatch = useDispatch();

  const onClose = () => {
    if (redirectParams.shouldRedirect) {
      const path = routePaths[redirectParams.routeName].create(redirectParams.params as any);
      const redirected = openWebview(path);
      if (!redirected) {
        window.__router.navigate(redirectParams.routeName, redirectParams.params);
      }
    }

    dispatch(LockedDialogActions.closeLockedDialog());
  };

  // Redirect to home instead of sequential playlist, when user os accessing
  // a locked course from either home screen or deeplinks
  const redirectHomeFromBackdrop = redirectParams.shouldRedirect
    ? !!redirectParams?.redirectHomeFromBackdrop
    : false;

  const onClickBackdrop = () => {
    window.__router.navigate('home', {});
    dispatch(LockedDialogActions.closeLockedDialog());
  };

  return (
    <LockedDialog
      onClose={redirectHomeFromBackdrop ? onClickBackdrop : onClose}
      onButtonClick={onClose}
      isOpen={isOpen}
      contentText={contentText}
      title={title}
      acceptText={acceptText}
    />
  );
};
