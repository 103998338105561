import * as React from 'react';
import type { TopNavigationMenuItem } from './navigation/types';
import type { NavigationIconNames } from './navigation/types';

export type AppLayoutContextType = {
  /** Config */
  hideNavigation: boolean;
  locked: boolean;

  /** Nav Items */
  menuItems: TopNavigationMenuItem[];

  /** Nav back */
  hasBackButton: boolean;
  onGoBack: () => void;
  /** App Nav (mobile only) */
  isAppMenuOpen: boolean;
  setIsAppMenuOpen: (b: boolean) => void;

  /** View Head (nav-bar) */
  viewHeadComponents: NavigationIconNames[];

  /** Star Bar */
  shouldShowStarBalance: boolean;
  balance: number;
  isGameAvailable: boolean;

  /** Home */
  closeHomeSearch: () => void;
};

export const initialAppLayoutContext: AppLayoutContextType = {
  locked: false,
  hasBackButton: false,
  hideNavigation: false,
  menuItems: [],
  isAppMenuOpen: false,
  setIsAppMenuOpen: () => ({}),
  onGoBack: () => ({}),
  shouldShowStarBalance: false,
  balance: 0,
  isGameAvailable: false,
  closeHomeSearch: () => ({}),
  viewHeadComponents: []
};

export const AppLayoutContext = React.createContext<AppLayoutContextType>(initialAppLayoutContext);
