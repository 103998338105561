import { createSelector } from 'reselect';

import type { LxStoreState } from '@maggie/store/types';

const findNextQuestionAvailable = createSelector(
  (state: LxStoreState) => state.learnerDemographic.questions,
  questions => {
    if (questions.length === 0) {
      return undefined;
    }

    return questions[0];
  }
);

const LearnerDemographicSelectors = {
  findNextQuestionAvailable
};

export { LearnerDemographicSelectors };
