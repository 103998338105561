import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useNotifications } from '../../providers/notifications';
import { DiscussionSvg } from '@edapp/ed-components';
import { NotificationCard, NotificationCardSkeleton } from '../card/NotificationCard';
import type { Props } from './types';
import { StyledEmptyStateCard } from './styled';

export const NotificationsFeed = React.memo<Props>(({ className, getScrollParent }) => {
  const { state, fetchPreviousPage, fetchNextPage } = useNotifications();
  const notifications = state.items;
  const pageInfoAfter = state.pageInfo.after;
  const pageInfoBefore = state.pageInfo.before;
  const unseenCount = state.metadata.unseen_count;
  const isLoading = state.loading;

  React.useEffect(() => {
    if (pageInfoBefore && unseenCount > 0) {
      fetchPreviousPage(pageInfoBefore);
    }
  }, []);

  const onLoadMore = async () => {
    if (!!pageInfoAfter && !isLoading) {
      await fetchNextPage(pageInfoAfter);
    }
  };

  return (
    <InfiniteScroll
      className={className}
      loadMore={onLoadMore}
      hasMore={!!pageInfoAfter}
      loader={<NotificationCardSkeleton key={0} />} // need key because the loader is rendered inside the list with other items
      useWindow={false}
      initialLoad={false}
      pageStart={1}
      getScrollParent={getScrollParent}
    >
      {notifications?.length ? (
        notifications.map(notification => (
          <NotificationCard key={notification.id} notification={notification} />
        ))
      ) : (
        <StyledEmptyStateCard
          showBackground={false}
          image={<DiscussionSvg width={160} />}
          title="Nothing to see here"
          description="You’ll be able to view your notifications here."
          maxTextWidth={256}
          gap={0}
        />
      )}
    </InfiniteScroll>
  );
});
